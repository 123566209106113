<template>
  <div class="features">
    <div class="actions-block">
      <div class="top-block">
        <div class="top-block-btn-wrapper">
          <div class="item">
            <div class="mess-wrapper">
              <p class="question">Ready for instant chat?</p>
              <span class="price">1 credit / 1 minute</span>
            </div>
            <button
              class="btn btn-outline-red btn btn-outline-red btn-action"
              :class="{ disabled: disabled.chat }"
              @click="previewStartChat(preview(false), user.user_id)"
            >
              <span class="btn-description">Chat now</span>
              <span class="rbi rbi-comment-text"></span>
            </button>
          </div>
          <div class="item">
            <div class="mess-wrapper">
              <p class="question">Want to send a letter?</p>
              <span class="price">10 credits / 1 message</span>
            </div>
            <button
              class="btn btn-outline-red btn-action"
              :class="{ disabled: disabled.mail }"
              @click="previewSendMail(preview(false), user)"
            >
            <span class="btn-description">SEND MESSAGE</span>
              <span class="rbi rbi-message"></span>
            </button>
          </div>
        </div>
        <p class="info-block" @click="$emit('openPriceDescription')">
          <span class="rbi rbi-info"></span>
          Explain all the differences between Messages and Chat
        </p>
      </div>
      <div class="bottom-block">
        <div class="item">
          <button
            class="btn btn-outline-red btn-action"
            :class="{ disabled: disabled.gift }"
            @click="
              sex.isMan
                ? previewSendGift(preview(false), user.user_id)
                : previewSendVirtualGift(preview(false), user.user_id)
            "
          >
            <span class="btn-description">Send a gift</span>
            <span class="rbi rbi-gift"></span>
          </button>
        </div>
        <div class="item">
          <button
            class="btn btn-outline-red btn-action"
            :class="{ disabled: disabled.wink }"
            @click="previewSendWink(preview(false), user.user_id)"
          >
          <span class="btn-description">Give a wink</span>
            <span class="checked-wrap"><i class="rbi-check"></i></span>
            <span class="rbi rbi-emoji"></span>
          </button>
        </div>
        <div class="item">
          <button
            class="btn btn-outline-red btn-action"
            :class="{ disabled: disabled.bookmarks }"
            @click="previewAddBookmark(preview(false), user.user_id)"
          >
            <span class="btn-description">To favorites</span>
            <span class="checked-wrap"><i class="rbi-check"></i></span>

            <span class="rbi rbi-star"></span>
          </button>
        </div>
        <div class="item">
          <button
            class="btn btn-outline-red btn-action"
            :class="{
              disabled: disabled.favs.status || disabled.favs.isFav || disabled.favs.ignored,
            }"
            @click="previewAddToFavs(preview(false), user.user_id)"
          >
          <span class="btn-description">Like</span>
            <span v-if="!disabled.favs.ignored || disabled.favs.tmp" class="checked-wrap">
              <i class="rbi-check"></i>
            </span>
            <span class="rbi rbi-heart"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  USER_ACTIONS,
  ACTIONS_DATA,
  CHECK_PRIVILEGES,
  COMPARE_DATA,
} from '../../../mixins/userActions';
import { MAIL_MODAL, PREVIEW_MODAL, REGISTRATION_MODAL } from '../../../mixins/formsActions';

export default {
  mixins: [
    USER_ACTIONS,
    ACTIONS_DATA,
    CHECK_PRIVILEGES,
    MAIL_MODAL,
    PREVIEW_MODAL,
    REGISTRATION_MODAL,
    COMPARE_DATA,
  ],
  props: {
    user: Object,
    disabledProps: Object,
  },
  computed: {
    actionsUserId() {
      return this.user.user_id;
    },
    token() {
      return this.$store.getters.user.accessToken;
    },
    width() {
      return this.$store.getters.deviceWidth;
    },
    sex() {
      return this.$store.getters.sex;
    },
  },
  watch: {
    /**
     * при смене пользователя обнуляем состояние отключенной привилегии с подминиванием
     *
     */
    user() {
      //				this.askPrivileges( val.user_id, val.online )
      this.disabled.wink = false;
    },
    /**
     * при обновлении состояния disabled выясняем, можно ли пригласить пользователя в чат
     */
    disabled() {
      this.canChat(this.user.online);
    },
  },
  mounted() {
    //			this.askPrivileges( this.user.user_id, this.user.online )
  },
  methods: {
    /**
     * Обработка действия. Например, подпигивания
     *
     * @param bool {boolean} условие
     *
     * @return {boolean}
     */
    preview(bool) {
      if (this.deviceWidth <= 768 && !this.token) {
        this.openRegistrationModal();
        return;
      }
      if (bool) {
        // show usual preview
        this.openPreviewModal(this.user.user_id, bool);
      } else if (this.token) {
        // if authorized and bool == false
        return true;
      } else {
        // if non authorized and bool == false
        this.openPreviewModal(this.user.user_id, bool);
      }
      return false;
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
@import '../../../assets/scss/variables';
@import '../../../assets/scss/vars';

.actions-block {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  box-shadow: none;
  border-color: $grey;
  color: $grey;

  &:after {
    width: 10px;
    height: 10px;
    border-bottom-color: $grey;
    border-right-color: $grey;
  }
}

.actions-block-left,
.actions-block-right {
  display: flex;
}

.flex-top .features .item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.flex-top {
  .transparent.disabled {
    position: relative;
    border-color: #bdbdbd !important;
    background: none !important;
    cursor: pointer;

    svg {
      fill: #bdbdbd !important;
    }
  }

  .btn-orange {
    &:hover {
      color: #ffffff;
    }
  }

  .checked-wrap {
    display: none !important;
  }

  .features {
    .btn {
      position: relative;
      justify-content: space-evenly;
    }

    .transparent {
      padding: 0;
      border-radius: 50%;
      width: 36px;
      height: 36px;

      .svg {
        fill: currentColor;
      }
    }
  }
}

.disabled {
  .checked-wrap {
    display: flex !important;
    right: -6px;
    top: -6px;
  }
}
.top-block {
  padding: 2px;
  background-color: #f5f9fc;
  width: 100%;
  box-sizing: border-box;
}
.top-block-btn-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  .btn {
    width: 250px;
  }
}
.info-block {
  color: #00ae99;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  font-size: 13px;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  span {
    color: inherit;
  }
}
.bottom-block {
  display: flex;
  align-items: center;
  margin-top: 40px;
  width: 100%;

  .btn {
    width: 108px;
    font-size: 20px;
  }
  .btn-description {
    font-size: 12px;
  }
}
.mess-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
}
.price {
  color: #97a7bf;
}
.btn-description {
  font-size: 16px;
}
.flex-top .features .item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  &:last-child {
    margin-right: 0  !important;
  }
}
.features .top-block .item {
  justify-content: space-between;
  margin-right: 0;
}
@media only screen and (max-width: 768px) {
  .actions-block-left,
  .actions-block-right {
    display: none;
  }
  .actions-block {
    display: block;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .flex-top {
    .transparent.disabled {
      background: #fff !important;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1259px) {
  .bottom-block .btn {
    font-size: 24px;
  }

  .bottom-block .btn-description {
    font-size: 14px;
  }
  .flex-top .features .bottom-block .btn-action {
    width: 192px;
    justify-content: space-evenly;
  }
  .bottom-block {
    margin-top: 17px;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .flex-top .features .top-block .btn-action {
    width: 160px;
    justify-content: space-evenly;
    .btn-description {
      font-size: 14px;
    }
  }
  .flex-top .features .item:nth-child(2) {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .flex-top .features {
    .top-block {
      .item {
        margin: 0;
      }
      .btn-action {
        width: 160px;
        justify-content: space-evenly;
      }
    }
  }
  .top-block {
    .btn-description {
      font-size: 14px;
    }
  }
  .top-block-btn-wrapper {
    gap: 19px;
  }
  .bottom-block {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 25px;
    .btn {
      font-size: 24px;
    }
    .btn-description {
      font-size: 14px;
    }
  }

  .flex-top .features .bottom-block .btn-action {
    width: 145px;
    justify-content: space-evenly;
  }
  .flex-top .features .item {
    margin-right: 16px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .flex-top .features {
    padding: 48px 0 0;
    .top-block {
      .item {
        margin: 0;
      }
      .item {
        flex-direction: column;
        align-items: baseline;
        row-gap: 10px;
        margin-right: 0 !important;
      }
      .btn-action {
        width: 100%;
        justify-content: space-evenly;
      }
      .btn-description {
        font-size: 14px;
      }
    }
  }
  .bottom-block {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 20px;
    .btn {
      font-size: 24px;
    }
    .btn-description {
      font-size: 12px;
    }
  }
  .flex-top .features .bottom-block .btn-action {
    width: 103px;
    height: 56px;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .flex-top .features .item {
    margin-right: 16px !important;
  }
  .mess-wrapper {
    margin: 0;
  }
}
@media screen and (min-width: 480px) and (max-width: 575px) {
  .flex-top .features {
    padding: 15px 0 0;
    .top-block {
      .item {
        margin: 0;
      }
      .item {
        flex-direction: column;
        align-items: baseline;
        row-gap: 10px;
        margin-right: 0 !important;
      }
      .btn-action {
        width: 100%;
        justify-content: space-evenly;
      }
      .btn-description {
        font-size: 14px;
      }
    }
  }
  .bottom-block {
    position: absolute;
    bottom: 0;
    left: 20px;
    margin: 0;
    width: calc(100vw - 20px);
    padding: 0;
    .btn {
      font-size: 24px;
    }
    .btn-description {
      font-size: 12px;
    }
  }
  .flex-top .features .bottom-block .btn-action {
    width: 18.3vw;
    height: 56px;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .flex-top .features .item {
    margin-right: 16px !important;
  }
  .flex-top .features .btn-action .rbi {
    font-size: 20px;
  }
}

@media screen and (max-width: 479px) {
  .flex-top .features {
    position: absolute;
    top: 274px;
    left: 20px;
    width: calc(100vw - 70px);
    padding: 0;
    .actions-block {
      justify-content: unset;
      row-gap: 20px;
    }
    .top-block {
      .item {
        margin: 0;
      }
      .item {
        flex-direction: column;
        align-items: baseline;
        row-gap: 10px;
      }
      .btn-action {
        width: 100%;
      }
      .btn-description {
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }
  .bottom-block {
    position: static;
    margin: 0;
    padding: 0;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 14px;
    .btn {
      font-size: 24px;
    }
    .btn-description {
      font-size: 12px;
    }
  }
  .flex-top .features .bottom-block .btn-action {
    width: 38vw;
    height: 56px;
    flex-direction: column;
  }
  .flex-top .features .item {
    margin-right: 0;
  }
  .flex-top .features .btn-action .rbi {
    font-size: 20px;
  }
}
@media screen and (max-width: 360px) {
  .flex-top .features {
    width: calc(100vw - 70px);
  }
  .flex-top .features .bottom-block .btn-action {
    width: 35vw;
  }
}
@media screen and (max-width: 320px) {
  .flex-top .features {
    width: 250px;
  }
}
</style>
