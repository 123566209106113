<template>
  <div>
    {{ toast.notificationId }}
    <component
      :is="allowedToasts()"
      :name="toast.params.senderName"
      @click.native="routerPush"
    />
  </div>
</template>

<script>
import guest from './guest.vue';
import favorites from './favorites.vue';
import inbox from './inbox.vue';
import wink from './wink.vue';

export default {
  components: {
    guest,
    favorites,
    inbox,
    wink,
  },
  props: {
    toast: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    settings() {
      return this.$store.getters.settings;
    },
    lifeTime() {
      return this.$store.getters.config.lifeTimes.notification;
    },
  },
  mounted() {
    setTimeout(() => {
      this.removeToast();
    }, this.lifeTime);
  },
  methods: {
    /**
			 * Перейти на профиль пользователя, пославшего уведомление
			 */
    routerPush() {
      this.$router.push({
        name: 'profile',
        params: { id: +this.toast.params.senderId || 0 },
      });
      this.removeToast();
    },

    /**
			 * Удалить уведомление
			 */
    removeToast() {
      this.$store.commit('removeToast', this.toast.notificationId);
    },

    /**
			 * Показывать только разрешаемые уведомления
			 * @return {string}
			 */
    allowedToasts() {
      //				if ( this.settings.notification !== 'off' ) {
      if (this.settings.notification_winks === 'on' && this.toast.type === 'wink') {
        return 'wink';
      }

      if (this.settings.notification_inbox === 'on' && this.toast.type === 'inbox') {
        return 'inbox';
      }

      if (this.settings.notification_guest === 'on' && this.toast.type === 'guest') {
        return 'guest';
      }

      if (this.settings.notification_favorite === 'on' && this.toast.type === 'favorites') {
        return 'favorites';
      }

      return '';

      //				}
    },
  },
};
</script>

<style lang="scss">

</style>
