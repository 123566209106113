<template>
  <div class="rb-page">
    <app-alerts />
    <div class="container profile-container main-container">
      <div class="row no-gutters">
        <sidebar-profile v-if="isAuthorized" :show="show" @closeSidebar="closeSidebar" />
        <guest-banner-sidebar v-if="!isAuthorized" />

        <div class="main-block">
          <template v-if="!deleted">
            <div class="white-box">
              <div v-if="!loaded" class="loader--wrap">
                <preloader />
              </div>
              <div v-show="loaded" class="header-box">
                <div
                  class="flex-top"
                  :class="{
                    'self-view': selfUserLoaded
                      ? (sex.isWoman === viewedUser.isWoman || sex.isMan === viewedUser.isMan) &&
                        selfUserLoaded
                      : false,
                  }"
                >
                  <div class="avatar-box">
                    <div :class="{ 'is-new': user.new && !user.is_iam }">
                      <div
                        v-background="{
                          img: user.avatar.img_large,
                          imgClass: 'avatar-main',
                        }"
                        class="ava-inner"
                        :class="{ 'is-online': user.online }"
                      >
                        <img
                          class="avatar-main"
                          :src="user.avatar.img_large"
                          :alt="user.name + $t('shared.alts.ava')"
                        />
                        <ul
                          v-if="sex.isMan && viewedUser.isMan"
                          class="avatar-box--actions list-unstyled visible-sm visible-md visible-lg"
                        >
                          <li>
                            <a href="#" @click.prevent="openUploader">
                              <span class="icon">
                                <span class="rbi-upload" />
                              </span>
                              <span>{{ $t('profile.uploadAva') }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="main-info info-block">
                    <main-info
                      :viewed-user="viewedUser"
                      :sex="sex"
                      :user="user"
                      @scroll="toggleInfo(true)"
                    />
                    <!-- <features-action
                       v-if="(sex.isMan !== viewedUser.isMan && selfUserLoaded) || !isAuthorized"
                      :disabled-props="user.disabled"
                      :user="miniProfile"
                    /> -->
                    <NewFeaturesAction
                      v-if="(sex.isMan !== viewedUser.isMan && selfUserLoaded) || !isAuthorized"
                      :disabled-props="user.disabled"
                      :user="miniProfile"
                      @openPriceDescription="priceDescriptionModal = true"
                    />
                    <features-self
                      v-if="
                        selfUserLoaded
                          ? (sex.isWoman === viewedUser.isWoman ||
                              sex.isMan === viewedUser.isMan) &&
                            selfUserLoaded
                          : false
                      "
                    />
                  </div>
                </div>
                <PriceDescriptionModal
                    v-if="priceDescriptionModal"
                    :user="user"
                    @closePriceDescriptionModal="priceDescriptionModal = false"
                />
              </div>
              <div v-show="loaded" class="center-part">
                <div class="flex-order w-s">
                  <photo-box
                    v-if="(viewedUser.isWoman && sex.isMan) || !isAuthorized"
                    :id="user.user_id"
                    class="viewblock-photo"
                    :photos="user.photos"
                    :name="user.name"
                    :limit="6"
                  />
                  <contact-box-large
                    v-if="((sex.isWoman || sex.isMan) && viewedUser.isWoman) || !isAuthorized"
                    :user="miniProfile"
                    :loaded="loaded"
                    :is-authorized="isAuthorized"
                    :viewed-user="viewedUser"
                  />
                  <div class="center-inner-box">
                    <div class="outer-info-box">
                      <photo-box
                        v-if="(sex.isMan === viewedUser.isMan && isAuthorized) || sex.isWoman"
                        :id="user.user_id"
                        :photos="user.photos"
                        :name="user.name"
                        :limit="5"
                      />
                      <responsive-info-man :user="user" :sex="sex" :viewed-user="viewedUser" />
                      <div ref="info" />
                      <big-info
                        :class="{ open: toggleResponsiveInfo }"
                        :viewed-user="viewedUser"
                        :sex="sex"
                        :profile="user.profile_additional_info"
                      />
                    </div>
                    <div
                      id="information-anchor"
                      class="chat-available hide-responsive"
                      :class="{ open: toggleResponsiveInfo }"
                    >
                      <appearance
                        :user="user"
                        class="full-appearance toggling-appearance"
                        :profile="user.profile_additional_info"
                      />
                    </div>
                  </div>
                  <!--<div class="detailed-info d-lg-none"-->
                  <!--:class="{open: toggleResponsiveInfo}">-->
                  <!--<button @click="toggleInfo(false)" type="button">Detailed info<span-->
                  <!--class="caret"></span>-->
                  <!--</button>-->
                  <!--</div>-->
                  <new-users-slider />
                  <visited-profiles />
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div v-show="loaded" class="white-box profile-deleted">
              <div class="profile-deleted__icon">
                <span class="rbi rbi-attention" />
              </div>
              <p class="profile-deleted__header">
                {{ $t('profile.delHeader') }}
              </p>
              <p class="profile-deleted__header--sub">
                {{ $t('profile.delHeaderSub') }}
              </p>
              <span>
                <a class="profile-deleted__link" @click.prevent="$router.go(-1)">
                  <span class="rbi rbi-reply" />
                  <span>{{ $t('profile.back') }}</span>
                </a>
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <upload-photos-modal v-if="!questionnaireModal.open" @updatePage="updatePageInfo" />
    <toast />
    <thanks-for-purchase-modal />
    <verification-modal />
    <questionnaire-modal/>
    <chat-invites />
    <preview-modal />
    <gift-modal />
    <virtual-gift-modal />
    <write-mail-modal v-if="sex.isMan || sex.isWoman" />
    <register-modal />
    <low-credits />
    <vue-gal />
  </div>
</template>

<script>
import  VueGal from '../Authorized/VueGal.vue';
import Alerts from '../Authorized/Alerts.vue';
import LowCredits from './modals/LowCredits.vue';
import FeaturesSelf from './Profile/FeaturesSelf.vue';
import ResponsiveInfoMan from './Profile/Man/ResponsiveInfoMan.vue';
import SidebarProfile from './Profile/Sidebar.vue';
import GuestBannerSidebar from '../Guest/GuestBannerSidebar.vue';
// import FeaturesAction from './Profile/FeaturesAction.vue';
import NewFeaturesAction from './Profile/NewFeaturesAction.vue';
import Appearance from './Profile/Appearance.vue';
import ContactBoxLarge from './Profile/ContactBoxLarge.vue';
import MainInfo from './Profile/MainInfo.vue';
import BigInfo from './Profile/BigInfo.vue';
import PhotoBox from './Profile/PhotoBox.vue';
import NewUsersSlider from './parts/NewUsersSlider.vue';
import VisitedProfiles from './parts/VisitedProfiles.vue';
import PreviewModal from './modals/PreviewModal.vue';
import WriteMailModal from './modals/WriteMailModal.vue';
import RegisterModal from './modals/RegisterModal.vue';
import GiftModal from './modals/GiftModal.vue';
import UploadPhotosModal from './modals/UploadPhotosModal.vue';
import { PHOTO_SRC, MAIN_RESIZE_DETECTOR, GALLERY_INDEX } from '../../mixins/utils';
import { PREPARE_DATA, LOGOUT } from '../../mixins/userActions';
import Toast from '../Authorized/Toast.vue';
import VirtualGiftModal from './modals/VirtualGiftModal';
import Preloader from './parts/Preloader';
import ThanksForPurchaseModal from './modals/PurchaseProcessingModal';
import QuestionnaireModal from "./modals/QuestionnaireModal";
import VerificationModal from '../Shared/modals/VerificationModal';
import ChatInvites from '@/components/Authorized/ChatInvites';
import PriceDescriptionModal from './PriceDescriptionModal';

export default {
  components: {
    ChatInvites,
    VerificationModal,
    QuestionnaireModal,
    ThanksForPurchaseModal,
    VirtualGiftModal,
    FeaturesSelf,
    ResponsiveInfoMan,
    GuestBannerSidebar,
    // FeaturesAction,
    NewFeaturesAction,
    ContactBoxLarge,
    MainInfo,
    BigInfo,
    PhotoBox,
    Appearance,
    SidebarProfile,
    NewUsersSlider,
    VisitedProfiles,
    PreviewModal,
    GiftModal,
    WriteMailModal,
    RegisterModal,
    Toast,
    appAlerts: Alerts,
    LowCredits,
    VueGal,
    UploadPhotosModal,
    Preloader,
    PriceDescriptionModal,
  },
  mixins: [PHOTO_SRC, MAIN_RESIZE_DETECTOR, PREPARE_DATA, GALLERY_INDEX, LOGOUT],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.token) {
        window.localStorage.setItem('access-token', vm.token);
        /* if (!vm.$socket) {
          /!* Vue.use(VueSocketIO, vm.config.chatSocketURL, store)*!/
          Vue.use(
            new VueSocketIO({
              debug: false,
              connection: vm.config.chatSocketURL,
              vuex: {
                store,
                actionPrefix: 'socket_',
                mutationPrefix: 'socket_',
              },
            })
          )
        } */
      }
      vm.$data.loaded = false;
      if (window.localStorage['access-token']) {
        vm.$http
          .post('v1/profile/profile', {
            access_token: window.localStorage['access-token'],
            target_user_id: to.params.id || '',
          })
          .then(
            (response) => {
              response.json().then(
                (response) => {

                  if (!response.status) {
                    if (response.result) {
                      if (vm.checkProfileExist(response.result.account_status)) {
                        vm.user = response.result;
                        vm.loaded = true;
                        vm.$store.dispatch('getUserData', {});
                        vm.writeReferalRecord();
                      } else {
                        vm.$router.push({ name: '404' });
                        window.localStorage.removeItem('ref');
                      }
                    } else {
                      vm.$router.push({ name: '404' });
                      window.localStorage.removeItem('ref');
                    }
                  } else {
                    document.title = response.result.name;
                    vm.$store.dispatch('getUserData', {
                      targetId: to.params.id,
                    });
                    vm.user = response.result;
                    vm.user.photos.forEach((i) => {
                      i.disableActions = false;
                      if (!vm.user.is_iam) i.album_type = false;
                    });
                    vm.loaded = true;
                    vm.regChat();
                  }
                },
                () => {
                  next(false); // if cannot parse json go back
                }
              );
            },
            () => {
              vm.logout();
            }
          );
      } else {
        vm.$http
          .post('v1/user-guest/profile-woman', {
            id: to.params.id,
          })
          .then(
            (response) => {
              if (!response.body.status || response.body.code === 10005) {
                vm.$router.push({ name: '404' });
                window.localStorage.removeItem('ref');
              } else {
                vm.$data.user = response.body.result;
                vm.$data.loaded = true;
                vm.writeReferalRecord();
              }
            },
            () => {
              vm.logout();
            }
          );
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('updateGallery', {
      open: false,
      index: 0,
      hideWatermark: false,
      photos: [],
    });
    next();
  },
  props: {
    token: String,
    referral: String,
  },
  data() {
    return {
      show: false,
      loaded: false,
      toggleResponsiveInfo: false,
      deleted: false,
      user: {
        avatar: {},
        profile_additional_info: {},
        photos: [],
      },
      priceDescriptionModal: false,
    };
  },
  computed: {
    questionnaireModal() {
      return this.$store.getters.questionnaireModal;
    },
    verificationModal() {
      return this.$store.getters.verificationModal;
    },
    isMe() {
      return this.selfUserLoaded
        ? (this.sex.isWoman === this.viewedUser.isWoman ||
            this.sex.isMan === this.viewedUser.isMan) &&
            this.selfUserLoaded
        : false;
    },
    config() {
      return this.$store.getters.config;
    },
    updatedGallery() {
      return this.$store.getters.updatedGallery;
    },
    gallery() {
      return this.$store.getters.gallery;
    },
    showLink() {
      return this.sex.isMan && this.viewedUser.isMan
        ? true
        : !!(false || (this.sex.isWoman && this.viewedUser.isWoman));
    },
    sex() {
      return this.$store.getters.sex;
    },
    chat() {
      return this.$store.getters.user.chat;
    },
    selfUserLoaded() {
      return !!this.selfUser.user_id;
    },
    selfUser() {
      return this.$store.getters.user;
    },
    /**
     * получить минифицированную информацию о профиле
     *
     * @return {object|undefined}
     */
    miniProfile() {
      if (this.user.user_id !== undefined) {
        return {
          avatar: this.user.avatar,
          name: this.user.name,
          user_id: this.user.user_id,
          online: this.user.online,
          email: this.user.email,
          phone: this.user.phone_number,
        };
      }
      return {};
    },
    isAuthorized() {
      return !!this.$store.getters.user.user_id || !!window.localStorage['access-token'];
    },
    /**
     * получить пол просматриваемого пользователя
     *
     * @return {{isMan: boolean, isWoman: boolean}}
     */
    viewedUser() {
      const man = this.user.user_type === 'man';
      const woman = !!(!this.user.user_type || this.user.user_type === 'woman');
      return {
        isMan: man,
        isWoman: woman,
      };
    },
    agreementModal() {
      return this.$store.getters.wmnAgreementModal;
    },
  },
  watch: {
    chat(newVal) {
      if (newVal === true) {
        setTimeout(() => {
          this.regChatOnDisconnect();
        }, 2500);
      }
    },
    /**
     * При смене роута очищаем компонент и грузим данные для нового пользователя
     */
    $route() {
      this.deleted = false;
      this.clearUpdate();
      this.loaded = false;
      this.user = {
        profile_additional_info: {},
        photos: [],
        avatar: {},
      };
      if (window.localStorage['access-token']) {
        this.askRegProfile();
      } else {
        this.$http
          .post('v1/user-guest/profile-woman', {
            id: this.$route.params.id,
          })
          .then((response) => {
            if (!response.body.status || response.body.code === 10005) {
              this.$router.push({ name: '404' });
            } else {
              this.user = response.body.result;
              this.loaded = true;
            }
          });
      }
    },
    /**
     * При обновлении пользователя запускаем проверку привилегий взаимодействия с пользователем
     *
     * @param v {object}
     */
    miniProfile(v) {
      if (
        this.user.user_id &&
        !this.usersUpdated &&
        this.$store.getters.user.user_id !== this.user.user_id
      ) {
        const self = this;
        this.singleArrayCheckPrivileges(v, 'user_id').then((response) => {
          self.user = { ...self.user, disabled: response.disabled };
        });
      }
    },
    /**
     * При обновлении состоянии галереи обновляем галерею, спрашивая профиль
     */
    updatedGallery() {
      this.askRegProfile(true);
    },
  },
  methods: {
    /**
     * Записать в localstorage инфо о реферальной ссылке
     */
    isEmptyObj(obj) {
      for (const key in obj) {
        return false;
      }
      return true;
    },
    writeReferalRecord() {
      const write = () => {
        if (this.$route.params.id) {
          window.localStorage.setItem(
            'ref',
            JSON.stringify({ id: this.$route.params.id, ...this.$route.query })
          );
        } else if (!this.isEmptyObj(this.$route.query)) {
          window.localStorage.setItem('ref', this.$route.query);
        } else {
          window.localStorage.removeItem('ref');
        }
      };

      if (!this.isEmptyObj(this.$route.query)) {
        if (
          window.localStorage.ref &&
          JSON.parse(window.localStorage.ref).ref !== this.$route.query
        ) {
          write();
        }
        if (!window.localStorage.ref) {
          write();
        }
      } else {
        window.localStorage.removeItem('ref');
      }
    },
    /**
     * Загрузить профиль с сервера
     *
     * @param updateGallery {boolean} - нужно ли обновлять галерею
     */
    askRegProfile(updateGallery) {
      const userID = this.user.user_id;
      this.$http
        .post('v1/profile/profile', {
          access_token: window.localStorage['access-token'],
          target_user_id: this.$route.params.id,
        })
        .then((response) => {
          response.json().then(
            (response) => {
              if (!response.status) {
                if (response.result) {
                  if (this.checkProfileExist(response.result.account_status)) {
                    this.user = response.result;
                    this.loaded = true;
                    this.$store.dispatch('getUserData', {});
                  } else {
                    this.$router.push({ name: '404' }); // if unable to view profile of same male as user
                  }
                } else {
                  this.$router.push({ name: '404' }); // if unable to view profile of same male as user
                }
              } else {
                document.title = response.result.name;
                this.user = response.result;
                this.user.photos.forEach((i) => {
                  i.disableActions = false;
                  if (!this.user.is_iam) i.album_type = false;
                }, this);
                if (updateGallery && this.gallery.open && userID === this.user.user_id) {
                  this.takePhotos(true, this.user.photos, this.gallery.index);
                }
                this.$store.dispatch('getUserData', {
                  targetId: this.$route.params.id,
                });
                this.regChat();
                this.loaded = true;
              }
            },
            () => {
              this.$router.go(-1); // if cannot parse json go back
            },
            () => {
              this.logout();
            }
          );
        });
    },
    /**
     * Проверка, неактивный ли данный профиль
     * @param v {string}
     * @return {boolean}
     */
    checkProfileExist(v) {
      return (this.deleted = v === 'deactivated');
    },
    /**
     * открыть модалку с загрузкой нового аватар
     */
    openUploader() {
      this.$store.commit('updateUploadPhotosModal', {
        open: true,
      });
    },
    /**
     * обновить компонент страницы при обновлении аватара
     */
    updatePageInfo() {
      const requestedID = +this.$route.params.id || this.selfUser.user_id;
      this.$http
        .post('v1/profile/profile', {
          access_token: window.localStorage['access-token'],
          target_user_id: requestedID,
        })
        .then(
          (response) => {
            if (
              response.body.result &&
              requestedID === this.user.user_id &&
              requestedID === this.selfUser.user_id
            ) {
              this.user = {
                profile_additional_info: {},
                photos: [],
                avatar: {},
              };
              this.user = response.body.result;
            }
          },
          () => {
            this.logout();
          }
        );
    },
    /**
     * переключить отображение сайдбара
     */
    toogleSidebar() {
      this.show = !this.show;
    },
    /**
     * переключить отображение сайдбара
     */
    closeSidebar() {
      this.show = false;
    },
    /**
     * переключить отображение подробной информации в респонзив версии
     *
     * @param open {boolean} новое состояние показа доп. инфы
     */
    toggleInfo(open) {
      this.toggleResponsiveInfo = !this.toggleResponsiveInfo || open;
      this.$scrollTo(this.$refs.info, 1000, {
        offset: -70,
      });
    },
    /**
     * регистрация пользователя в чате
     */
    regChat() {
      /* if (!this.chat) {
        this.$http
          .post('v1/chat/auth-new', {
            access_token: window.localStorage['access-token'],
            type: 'site',
          })
          .then((response) => {
            if (response.body.status) {
              this.$socket.emit(
                'registration',
                {
                  user_id: response.body.user_id,
                  front_v: this.$store.getters.frontendVersion,
                  type: 'site',
                },
                (data) => {
                  window.localStorage.setItem('chat-token', data.chatToken)
                  this.$store.commit('setRegisteredOnChatStatus', {
                    status: true,
                  })
                }
              )
            }
          })
      } */
    },
    regChatOnDisconnect() {
      /* this.$http
        .post('v1/chat/auth-new', {
          access_token: window.localStorage['access-token'],
          type: 'site',
        })
        .then((response) => {
          if (response.body.status) {
            this.$socket.emit(
              'registration',
              {
                user_id: response.body.user_id,
                front_v: this.$store.getters.frontendVersion,
                type: 'site',
              },
              (data) => {
                window.localStorage.setItem('chat-token', data.chatToken)
                this.$store.commit('setRegisteredOnChatStatus', {
                  status: true,
                })
              }
            )
          }
        }) */
    },
    /* regChat() {
      /!*if (window.localStorage['access-token'] && !this.chat) {
        this.$http
          .post('v1/chat/auth-new', {
            access_token: window.localStorage['access-token'],
            type: 'site',
          })
          .then((response) => {
            if (response.body.status) {
              this.$socket.emit(
                'registration',
                {
                  user_id: response.body.user_id,
                  front_v: this.$store.getters.frontendVersion,
                  type: 'site',
                },
                (data) => {
                  window.localStorage.setItem('chat-token', data.chatToken)
                }
              )
            }
          })
      }*!/
    }, */
  },
};
</script>

<style lang="scss" scoped="scoped">
@import '../../assets/scss/vars';
@import '../../assets/scss/_mixins/font-size-fluid';

.profile-container {
  .loader--wrap {
    width: 100%;
    min-height: 50vh;
  }
}

.profile-deleted {
  padding: 25px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  &__icon {
    @include fluid-type(300px, 1700px, 30px, 35px);
    color: $red;
  }
  &__header {
    @include fluid-type(300px, 1700px, 18px, 26px);
    margin-top: 0;
    &--sub {
      @include fluid-type(300px, 1700px, 12px, 16px);
    }
  }
  &__link {
    display: inline-flex;
    align-items: center;
    @include fluid-type(300px, 1700px, 14px, 16px);
  }
}
/*	@media only screen and (max-width: 800px) {
            .toggling-info {
                display: none;
            }
            .toggling-appearance {
                display: none;
            }
        }*/

.avatar-box {
  > div {
    height: 100%;
  }
}

.main-block {
  max-width: 82.432%;
  flex: 0 0 82.432%;
}

.loader--wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.index-navbar {
  position: static;
  padding: 20px 0 25px;
  z-index: 10;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 225px;
    background: transparent url(/static/images/profile_top_bg.jpg) 0 0 no-repeat;
    background-size: cover;
  }
}

@media only screen and (max-width: 1200px) {
  .index-navbar {
    z-index: 100;

    &:before {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
}
@media screen and (min-width: 480px) and (max-width: 575px) {
  .flex-top {
    padding: 20px 20px 95px;
  }
  .avatar-box {
    flex: 0 0 40vw;
  }

  .avatar-box .ava-inner {
    width: 40vw;
    height: 361px;
  }
}
@media screen and (max-width: 479px) {
  .flex-top {
    padding: 20px 20px 460px;
  }
  .avatar-box {
    flex: 0 0 42.5vw;
  }

  .avatar-box .ava-inner {
    width: 42.5vw;
    height: 240px;
  }
}
</style>
