<template>
  <div>
    <div class="header-info-component">
      <div class="first-line">
        <div class="item">
          <h1 class="title-profile">
            {{ user.name }},
            <template v-if="user.birthday !== null">
              {{
                getUserAge(user.birthday)
              }}
            </template>
          </h1>
          <h3 class="id">
            ID: {{ user.user_id }}
          </h3>
        </div>
        <div class="item item-status">
          <p
            v-if="user.online"
            class="status visible-sm visible-md visible-lg"
          >
            {{ $t('profile.online') }}
          </p>
          <div
            v-if="sex.isMan && viewedUser.isMan"
            class="dropdown d-md-none"
          >
            <div
              id="actions-dropdown"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              <span class="rbi rbi-more" />
            </div>
            <ul
              class="dropdown-menus dropdown-menu"
              aria-labelledby="actions-dropdown"
            >
              <li>
                <a
                  href="#"
                  @click.prevent="openUploader"
                >{{
                  $t('profile.uploadAva')
                }}</a>
              </li>
            </ul>
          </div>
          <span
            :class="userWebcam(user.camera)"
            class="hidden-sm hidden-xs"
          />
          <div
            class="hidden-lg hidden-md hidden-sm profile-scroll"
            @click.prevent="$emit('scroll')"
          >
            <span class="rbi rbi-info" />
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="status-profile">
          <p
            v-if="!editingStatus && displayedStatus"
            class="custom-status"
            @click="startEditing"
          >
            {{ displayedStatus }}
          </p>
          <form
            v-if="editingStatus"
            @submit.prevent="changeStatus"
          >
            <input
              ref="status"
              v-model="user.status"
              class="custom-status"
              type="text"
              autofocus
              @blur="changeStatus"
            >
          </form>
          <div
            v-show="errorStatus"
            class="error"
          >
            <i class="fa fa-warning" />
            <span>{{ $t('profile.errors.failStatus') }}</span>
          </div>
        </div>
      </div>
      <div class="info-box normal header-info">
        <div class="item">
          <div class="caption">
            {{ $t('profile.country') }}:
            <span>{{ noData(user.address_country) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t('profile.city') }}:
            <span>{{ noData(user.address_city) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t('profile.religion') }}:
            <span>{{ noData(user.profile_additional_info.religion) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption zodiac-container">
            {{ $t('profile.dateBirth') }}: <span>{{ date }}</span>
            <zodiac-tpl
              v-if="user.birthday !== null"
              :timestamp="user.birthday"
            />
          </div>
        </div>
        <div class="item">
          <div class="caption capitalize">
            {{ $t('profile.lvlEng') }}:
            <span>{{ noData(user.profile_additional_info.lang_english) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption capitalize">
            {{ $t('profile.marital') }}:
            <span>{{
              noData(user.profile_additional_info.family_status)
            }}</span>
          </div>
        </div>
        <div
          class="contain-edit empty"
          :class="{ 'flex-row': isIE }"
        >
          <router-link
            v-if="sex.isMan && viewedUser.isMan"
            tag="button"
            :to="{ name: 'edit' }"
            type="button"
            class="contain-edit__btn"
          >
            <span>
              <span class="rbi rbi-edit" />
              {{ $t('profile.edit') }}
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import zodiacTpl from '../parts/zodiac/_template.vue';
import { AGE, WEBCAM, NO_DATA } from '../../../mixins/utils';

export default {
  components: {
    zodiacTpl,
  },
  mixins: [AGE, WEBCAM, NO_DATA],
  props: {
    user: Object,
    viewedUser: Object,
    sex: Object,
  },
  data() {
    return {
      editingStatus: false,
      errorStatus: false,
    };
  },
  computed: {
    isIE() {
      return (
        navigator.userAgent.indexOf('MSIE') !== -1
        || navigator.appVersion.indexOf('Trident/') > -1
      );
    },
    /**
     * Возвращает статус пользователя
     *
     * @return {*}
     */
    displayedStatus() {
      if (this.viewedUser.isMan && this.sex.isMan) {
        return this.user.status
          ? this.user.status
          : this.$t('profile.emptyStatus');
      }
      //				if ( this.viewedUser.isWoman || this.viewedUser. ) {
      return this.user.status;
      //				}
    },
    /**
     * Возвращает строчное представление даты
     *
     * @return {string}
     */
    date() {
      if (!this.user.birthday) {
        return this.$t('profile.noFill');
      }
      const userAge = this.getUserAge(this.user.birthday);
      return `${moment
        .utc(this.user.birthday * 1000)
        .format('LL')} (${userAge})`;
    },
  },
  watch: {
    /**
     * Валидация отображаемого статуса пользователя
     */
    displayedStatus() {
      if (this.user.status != null && !this.viewedUser.isWoman) {
        if (this.user.status.length > 160) {
          this.errorStatus = true;
        } else if (this.user.status.length < 160) {
          this.errorStatus = false;
        }
      }
    },
  },
  methods: {
    /**
     * открыть модалку с обновлением фотографии
     */
    openUploader() {
      this.$store.commit('updateUploadPhotosModal', {
        open: true,
      });
    },
    /**
     * смена статуса пользователя
     */
    changeStatus() {
      this.editingStatus = false;
      if (!this.errorStatus) {
        this.$http.post('v1/profile/edit-status', {
          access_token: window.localStorage['access-token'],
          status: this.user.status ? this.user.status.trim() : this.user.status,
        });
      }
    },
    /**
     * начало редактирования статуса и валидация привилегий на это
     */
    startEditing() {
      if (this.viewedUser.isMan && this.sex.isMan) {
        this.editingStatus = true;
        const self = this;
        setTimeout(() => {
          self.$refs.status.focus();
        }, 50);
      }
    },
  },
};
</script>

<style></style>
