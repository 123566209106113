<template>
  <div class="responsive-box-info info-block info-box normal d-md-none">
    <div class="contain-edit empty">
      <router-link
        v-if="sex.isMan && viewedUser.isMan"
        tag="button"
        :to="{ name: 'edit' }"
        type="button"
        class="contain-edit__btn"
      >
        <span>
          <span class="rbi rbi-edit" />
          {{ $t('profile.edit') }}
        </span>
      </router-link>
    </div>
    <div class="item">
      <div class="caption">
        {{ $t('profile.country') }}:
        <span>{{ noData(user.address_country) }}</span>
      </div>
    </div>
    <div class="item">
      <div class="caption">
        {{ $t('profile.city') }}: <span>{{ noData(user.address_city) }}</span>
      </div>
    </div>
    <div class="item">
      <div class="caption">
        {{ $t('profile.religion') }}:
        <span>{{ noData(user.profile_additional_info.religion) }}</span>
      </div>
    </div>
    <div class="item">
      <div class="caption">
        {{
          viewedUser.isWoman
            ? $t('profile.dateBirthW')
            : $t('profile.dateBirthM')
        }}:
        <span
          class="capitalize zodiac-container"
        >{{ date }}
          <zodiac-tpl
            v-if="user.birthday !== null"
            :timestamp="user.birthday"
          /></span>
      </div>
    </div>
    <div class="item">
      <div class="caption">
        {{ $t('profile.religion') }}:
        <span>{{ noData(user.profile_additional_info.religion) }}</span>
      </div>
    </div>
    <div class="item">
      <div class="caption">
        {{ $t('profile.lvlEng') }}:
        <span class="capitalize">{{
          noData(user.profile_additional_info.lang_english)
        }}</span>
      </div>
    </div>
    <div class="item">
      <div class="caption">
        {{ $t('profile.marital') }}:
        <span class="capitalize">{{
          noData(user.profile_additional_info.family_status)
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import zodiacTpl from '../../parts/zodiac/_template.vue';
import { COMPUTED_PROFILE } from '../../../../mixins/types/profile';
import { AGE, NO_DATA } from '../../../../mixins/utils';

export default {
  components: {
    zodiacTpl,
  },
  mixins: [AGE, COMPUTED_PROFILE, NO_DATA],
  props: {
    user: Object,
    sex: Object,
    viewedUser: Object,
  },
  data() {
    return {};
  },
  computed: {
    /**
     * получение строчного представления даты из таймстемпа
     *
     * @return {string}
     */
    date() {
      if (this.user.birthday !== null) {
        return ` ${moment
          .utc(this.user.birthday * 1000)
          .format('ll')} (${this.getUserAge(this.user.birthday)} ${this.$t(
          'profile.years',
        )})`;
      }
      return this.$t('profile.noFill');
    },
  },
  methods: {},
};
</script>

<style></style>
