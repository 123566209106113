<template>
  <div class=" hide-responsive">
    <div class="info-block">
      <div class="text-overflow contain-edit">
        <h2 class="title-profile edit-caption">
          {{ $t( 'profile.aboutMe' ) }}
        </h2>
        <!--Template for editting Button-->
        <router-link
          v-if="sex.isMan && viewedUser.isMan"
          tag="button"
          :to="{name: 'edit-about'}"
          type="button"
          class="contain-edit__btn"
        >
          <span>
            <span class="rbi rbi-edit" />
            {{ $t('profile.edit') }}
          </span>
        </router-link>
      </div>
      <div class="info-box redefine column-orientation">
        <div class="item">
          <div class="caption">
            {{ $t( 'profile.educations' ) }}: <span>{{ noData( profile.education ) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t( 'profile.occupation' ) }}: <span>{{ noData( profile.profession ) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t( 'profile.alcohol' ) }}: <span>{{ noData( profile.drink ) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t( 'profile.smoke' ) }}: <span>{{ noData( profile.smoke ) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t( 'profile.iLive' ) }}: <span>{{ noData( profile.accommodation ) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t( 'profile.children' ) }}: <span>{{ noData( profile.children ) }}</span>
          </div>
        </div>
        <div
          v-if="viewedUser.isWoman"
          class="item"
        >
          <div class="caption">
            {{ $t( 'profile.flowers' ) }}: <span>{{ noData( flowers ) }}</span>
          </div>
        </div>
        <div
          v-if="viewedUser.isWoman"
          class="item"
        >
          <div class="caption">
            {{ $t( 'profile.holidays' ) }}: <span>{{ noData( holidays ) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t( 'profile.pet' ) }}: <span>{{ noData( pets ) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t( 'profile.langs' ) }}: <span>{{ noData( langs ) }}</span>
          </div>
        </div>
        <div
          class="item"
          :class="{full: viewedUser.isWoman}"
        >
          <div class="caption">
            {{ $t( 'profile.goals' ) }}: <span>{{ noData( goals ) }}</span>
          </div>
        </div>
        <div
          v-if="viewedUser.isMan"
          class="item"
        >
          <div class="caption">
            {{ $t( 'profile.holidays' ) }}: <span>{{ noData( holidays ) }}</span>
          </div>
        </div>
        <div class="item full">
          <div class="caption">
            {{ $t( 'profile.hobbies' ) }}: <span>{{ noData( profile.interests ) }}</span>
          </div>
        </div>
        <div class="item full">
          <div class="caption">
            {{ $t( 'profile.aboutMe' ) }}: <span>{{ noData( profile.about_me ) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NO_DATA } from '../../../mixins/utils';

export default {
  mixins: [NO_DATA],
  props: {
    profile: {
      type: Object,
      required: true,
    },
    viewedUser: Object,
    sex: Object,
  },
  computed: {
    /**
       * получение строки вариантов домашних питомцев из массива
       *
       * @return {string}
       */
    pets() {
      const tmp = [
        {
          k: 'Dog',
          v: this.profile.pet_dog,
        },
        {
          k: 'Cat',
          v: this.profile.pet_cat,
        },
        {
          k: 'Hamster',
          v: this.profile.pet_hamster,
        },
        {
          k: 'Aquarium fish',
          v: this.profile.pet_aquarium_fish,
        },
        {
          k: 'Parrot',
          v: this.profile.pet_parrot,
        },
        {
          k: 'Turtle',
          v: this.profile.pet_turtle,
        },
        {
          k: 'Snake',
          v: this.profile.pet_snake,
        },
        {
          k: 'Iguana',
          v: this.profile.pet_iguana,
        },
        {
          k: 'Chinchilla',
          v: this.profile.pet_chinchilla,
        },
        {
          k: 'Rabbit',
          v: this.profile.pet_rabbit,
        },
      ];
      return tmp.filter((item) => item.v).map((item) => item.k).join(', ');
    },
    /**
       * получение строки вариантов праздников из массива
       *
       * @return {string}
       */
    holidays() {
      const tmp = [
        {
          k: 'Birthday',
          v: this.profile.celebration_birthday,
        },
        {
          k: 'Christmas day',
          v: this.profile.celebration_christmas_day,
        },
        {
          k: 'New Year\'s day',
          v: this.profile.celebration_new_years_day,
        },
        {
          k: 'Easter',
          v: this.profile.celebration_easter,
        },
        {
          k: 'Valentine\'s day',
          v: this.profile.celebration_valentines_day,
        },
        {
          k: 'Halloween',
          v: this.profile.celebration_halloween,
        },
        {
          k: 'Woman\'s day',
          v: this.profile.celebration_womens_day,
        },
      ];
      return tmp.filter((item) => item.v).map((item) => item.k).join(', ');
    },
    /**
       * получение строки вариантов любимых цветов из массива
       *
       * @return {string}
       */
    flowers() {
      const tmp = [
        {
          k: 'Red roses',
          v: this.profile.flowers_red_roses,
        },
        {
          k: 'White roses',
          v: this.profile.flowers_white_roses,
        },
        {
          k: 'Pink roses',
          v: this.profile.flowers_pink_roses,
        },
        {
          k: 'Gerberas',
          v: this.profile.flowers_gerbera,
        },
        {
          k: 'Chrysanthemums',
          v: this.profile.flowers_chrysanthemum,
        },
      ];

      return tmp.filter((item) => item.v).map((item) => item.k).join(', ');
    },
    /**
       * получение строки вариантов языков из массива
       *
       * @return {string}
       */
    langs() {
      const tmp = [
        {
          k: 'Spanish',
          v: this.profile.lang_spanish,
        },
        {
          k: 'French',
          v: this.profile.lang_french,
        },
        {
          k: 'German',
          v: this.profile.lang_german,
        },
        {
          k: 'Portuguese',
          v: this.profile.lang_portuguese,
        },
      ];

      return tmp.filter((item) => (item.v === 'no' || !item.v ? false : item)).map((item) => `${item.k} (${item.v})`).join(', ');
    },
    /**
       * получение строки вариантов целей в из массива
       *
       * @return {string}
       */
    goals() {
      const tmp = [
        {
          k: 'Friendship',
          v: this.profile.target_friendship,
        },
        {
          k: 'Communication',
          v: this.profile.target_communication,
        },
        {
          k: 'Journey',
          v: this.profile.target_journey,
        },
        {
          k: 'Flirtation',
          v: this.profile.target_flirtation,
        },
        {
          k: 'Meeting',
          v: this.profile.target_meeting,
        },
        {
          k: 'Family',
          v: this.profile.target_family,
        },
        {
          k: 'Children',
          v: this.profile.target_children,
        },
      ];

      return tmp.filter((item) => item.v).map((item) => item.k).join(', ');
    },
  },
  methods: {},
};
</script>

<style>
</style>
