<template>
  <div class="pay-systems-wrapper">
    <h4 class="modal-title">
      {{ $t('paySystemModal.heading') }}
    </h4>
    <div v-if="!loading" class="pay_systems">
      <div v-if="filteredPaymentsStatus.billline_gift_online.status" class="pay_system">
        <h6 class="subheader">Bill Line UA Hryvnas</h6>
        <input
          id="billline"
          v-model="paySystemId"
          type="radio"
          name="radio-group"
          :value="filteredPaymentsStatus.billline_gift_online.id"
          @change="paySystemClickHandler($event.target.value)"
        />
        <label for="billline">
          <span class="pay_system--block">
            <img src="../../../assets/images/mastecard-visa@compress-h37.png" alt />
          </span>
        </label>
      </div>
      <div v-if="filteredPaymentsStatus.stripe_gift_online.status" class="pay_system">
        <h6 class="subheader">Stripe</h6>
        <input
          id="stripe"
          v-model="paySystemId"
          type="radio"
          name="radio-group"
          :value="filteredPaymentsStatus.stripe_gift_online.id"
          @change="paySystemClickHandler($event.target.value)"
        />
        <label for="stripe">
          <span class="pay_system--block">
            <img src="../../../assets/images/mastecard-visa@compress-h37.png" alt />
          </span>
        </label>
      </div>
      <div v-if="filteredPaymentsStatus.billline_usd_gift_online.status" class="pay_system">
        <h6 class="subheader">Bill Line US Dollars</h6>
        <input
          id="billline_usd"
          v-model="paySystemId"
          type="radio"
          name="radio-group"
          :value="filteredPaymentsStatus.billline_usd_gift_online.id"
          @change="paySystemClickHandler($event.target.value)"
        />
        <label for="billline_usd">
          <span class="pay_system--block">
            <img src="../../../assets/images/mastecard-visa@compress-h37.png" alt />
          </span>
        </label>
      </div>
      <div v-if="filteredPaymentsStatus.multicard_gift_online.status" class="pay_system">
        <h6 class="subheader">MultiCards</h6>
        <input
          id="multicard"
          v-model="paySystemId"
          type="radio"
          name="radio-group"
          :value="filteredPaymentsStatus.multicard_gift_online.id"
          @change="paySystemClickHandler($event.target.value)"
        />
        <label for="multicard">
          <span class="pay_system--block">
            <img src="../../../assets/images/mastecard-maestro@compress-h371.png" alt />
          </span>
        </label>
      </div>
      <div v-if="filteredPaymentsStatus.epayments_gift_online.status" class="pay_system">
        <h6 class="subheader">Epayments</h6>
        <input
          id="epayments"
          v-model="paySystemId"
          type="radio"
          name="radio-group"
          :value="filteredPaymentsStatus.epayments_gift_online.id"
          @change="paySystemClickHandler($event.target.value)"
        />
        <label for="epayments">
          <span class="pay_system--block">
            <img src="../../../assets/images/mastecard-maestro@compress-h37.png" alt />
          </span>
        </label>
      </div>
      <div v-if="paymentsStatus.paymentstrust_gift_online.status" class="pay_system">
        <h6 class="subheader">Payments Trust</h6>
        <input
          id="paymentstrust"
          v-model="paySystemId"
          type="radio"
          name="radio-group"
          :value="paymentsStatus.paymentstrust_gift_online.id"
          @change="paySystemClickHandler($event.target.value)"
        />
        <label for="paymentstrust">
          <span class="pay_system--block">
            <img src="../../../assets/images/mastecard-maestro@compress-h37.png" alt />
          </span>
        </label>
      </div>
      <div v-if="filteredPaymentsStatus.ccbill_gift_online.status" class="pay_system">
        <h6 class="subheader">CCBill</h6>
        <input
          id="ccbill"
          v-model="paySystemId"
          type="radio"
          name="radio-group"
          :value="filteredPaymentsStatus.ccbill_gift_online.id"
          @change="paySystemClickHandler($event.target.value)"
        />
        <label for="ccbill">
          <span class="pay_system--block">
            <img src="../../../assets/images/mastecard-maestro@compress-h37.png" alt />
          </span>
        </label>
      </div>
      <div v-if="filteredPaymentsStatus.segpay_gift_online.status" class="pay_system">
        <h6 class="subheader">Segpay</h6>
        <input
          id="segpay"
          v-model="paySystemId"
          type="radio"
          name="radio-group"
          :value="filteredPaymentsStatus.segpay_gift_online.id"
          @change="paySystemClickHandler($event.target.value)"
        />
        <label for="segpay">
          <span class="pay_system--block">
            <img src="../../../assets/images/mastecard-maestro@compress-h37.png" alt />
          </span>
        </label>
      </div>
      <div v-show="!anyPaySystemOnline">No pay systems online.</div>
    </div>
    <div v-else class="loader-wrap">
      <preloader />
    </div>
  </div>
</template>

<script>
import Preloader from '../parts/Preloader';

export default {
  name: 'GiftPaySystemSelect',
  components: { Preloader },
  props: {
    value: { type: Number, default: 0 },
    paymentsStatus: Object,
    loading: Boolean,
    paySystemClickHandler: Function,
  },
  data: () => ({
    paySystemId: 0,
  }),
  computed: {
    anyPaySystemOnline() {
      return (
        this.paymentsStatus.ccbill_gift_online.status ||
        this.paymentsStatus.paymentstrust_gift_online.status ||
        this.paymentsStatus.epayments_gift_online.status ||
        this.paymentsStatus.multicard_gift_online.status ||
        this.paymentsStatus.segpay_gift_online.status ||
        this.paymentsStatus.billline_gift_online ||
        this.paymentsStatus.billline_usd_gift_online||
        this.paymentsStatus.stripe_gift_online
      );
    },

    userPaymentsGroupItems() {
      return this.$store.getters.userPaymentsGroup;
    },

    filteredPaymentsStatus() {
      if (this.userPaymentsGroupItems.length > 0) {
        const filteredPaymentsStatus = Object.values(this.paymentsStatus);
        console.log(filteredPaymentsStatus,'filteredPaymentsStatus');

        filteredPaymentsStatus.forEach((paySystem) => {
          const foundActiveUserPaySystem = this.userPaymentsGroupItems.find(
            (userPaySystem) => userPaySystem === paySystem.userGroupsId
          );

          if (foundActiveUserPaySystem && paySystem.status === 1) {
            paySystem.status = 1;
          } else {
            paySystem.status = 0;
          }
        });

        return {
          ccbill_gift_online: {
            id: 1,
            status: filteredPaymentsStatus[0].status,
            userGroupsId: 4,
          },
          epayments_gift_online: {
            id: 2,
            status: filteredPaymentsStatus[1].status,
            userGroupsId: 3,
          },
          multicard_gift_online: {
            id: 3,
            status: filteredPaymentsStatus[2].status,
            userGroupsId: 1,
          },
          paymentstrust_gift_online: {
            id: 4,
            status: filteredPaymentsStatus[3].status,
            userGroupsId: 5,
          },
          segpay_gift_online: {
            id: 5,
            status: filteredPaymentsStatus[4].status,
            userGroupsId: 2,
          },
          billline_gift_online: {
            id: 6,
            status: filteredPaymentsStatus[5].status,
            userGroupsId: 6,
          },
          billline_usd_gift_online: {
            id: 7,
            status: filteredPaymentsStatus[6].status,
            userGroupsId: 6,
          },
          stripe_gift_online: {
            id: 8,
            status: filteredPaymentsStatus[7].status,
            userGroupsId: 6,
          },
        };
      }
      return {
        ccbill_gift_online: {
          id: 1,
          status: this.paymentsStatus.ccbill_gift_online.status,
          userGroupsId: 4,
        },
        epayments_gift_online: {
          id: 2,
          status: this.paymentsStatus.epayments_gift_online.status,
          userGroupsId: 3,
        },
        multicard_gift_online: {
          id: 3,
          status: this.paymentsStatus.multicard_gift_online.status,
          userGroupsId: 1,
        },
        paymentstrust_gift_online: {
          id: 4,
          status: this.paymentsStatus.paymentstrust_gift_online.status,
          userGroupsId: 5,
        },
        segpay_gift_online: {
          id: 5,
          status: this.paymentsStatus.segpay_gift_online.status,
          userGroupsId: 2,
        },
        billline_gift_online: {
          id: 6,
          status: this.paymentsStatus.billline_gift_online.status,
          userGroupsId: 6,
        },
        billline_usd_gift_online: {
          id: 7,
          status: this.paymentsStatus.billline_usd_gift_online.status,
          userGroupsId: 6,
        },
        stripe_gift_online: {
          id: 8,
          status: this.paymentsStatus.stripe_gift_online.status,
          userGroupsId: 6,
        },
      };
    },
  },
};
</script>

<style scoped>
.modal-title {
  padding-bottom: 7px;
}
.subheader {
  font-size: 0.9rem;
  padding-bottom: 3px;
}
.pay_system--block img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.pay-systems-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 50px;
}
.pay_systems {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 20px;
}
.pay_system {
  width: 50%;
  cursor: pointer;
}
@media screen and (max-width: 425px) {
  .pay_systems {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 15px;
  }
  .pay_system {
    width: 100%;
    cursor: pointer;
  }
  .pay_system--block img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.loader-wrap {
  display: flex;
  justify-content: center;
}
</style>
