<template>
  <component :is="sign" />
</template>

<script>
import moment from 'moment';
import aqu from './aqu.vue';
import ari from './ari.vue';
import can from './can.vue';
import cap from './cap.vue';
import gem from './gem.vue';
import leo from './leo.vue';
import lib from './lib.vue';
import pis from './pis.vue';
import sag from './sag.vue';
import sco from './sco.vue';
import tau from './tau.vue';
import vir from './vir.vue';

export default {
  components: {
    aqu,
    ari,
    can,
    cap,
    gem,
    leo,
    lib,
    pis,
    sag,
    sco,
    tau,
    vir,
  },
  props: {
    timestamp: Number,
  },
  data() {
    return {};
  },
  computed: {
    /**
     * возвращает значение зодиака, основываясь на дате
     *
     * @return {string} - название динамического компонента
     */
    sign() {
      if (!this.timestamp) return null;

      let ts = this.timestamp * 1000,
        month = moment(ts).get('month') + 1,
        day = moment(ts).get('date');

      if ((month === 1 && day <= 20) || (month === 12 && day >= 22)) {
        return 'cap';
      }
      if ((month === 1 && day >= 21) || (month === 2 && day <= 18)) {
        return 'aqu';
      }
      if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) {
        return 'pis';
      }
      if ((month === 3 && day >= 21) || (month === 4 && day <= 20)) {
        return 'ari';
      }
      if ((month === 4 && day >= 21) || (month === 5 && day <= 20)) {
        return 'tau';
      }
      if ((month === 5 && day >= 21) || (month === 6 && day <= 20)) {
        return 'gem';
      }
      if ((month === 6 && day >= 22) || (month === 7 && day <= 22)) {
        return 'can';
      }
      if ((month === 7 && day >= 23) || (month === 8 && day <= 23)) {
        return 'leo';
      }
      if ((month === 8 && day >= 24) || (month === 9 && day <= 23)) {
        return 'vir';
      } else if ((month === 9 && day >= 24) || (month === 10 && day <= 23)) {
        return 'lib';
      } else if ((month === 10 && day >= 24) || (month === 11 && day <= 22)) {
        return 'sco';
      } else if ((month === 11 && day >= 23) || (month === 12 && day <= 21)) {
        return 'sag';
      } else {
        return '';
      }
    },
  },
  methods: {},
};
</script>

<style></style>
