<template>
  <transition v-if="modal.open" name="modal">
    <div class="vue-modal modal-mask mgifts" @click.self="closeModal">
      <div class="vue-modal--wrapper" @click.self="closeModal">
        <div class="vue-modal--container mgifts--container" :class="{ wide: preview }">
          <div class="modal-header">
            <h4 v-if="preview" class="modal-title">Gifts</h4>
            <button
              v-else-if="!chosenData.retryGiftId"
              class="btn go-back"
              type="button"
              @mouseup="goBack"
            >
              <span class="rbi rbi-left-arrow" />
              <span>{{ $t('mail.utils.back') }}</span>
            </button>
            <div />
            <div class="modal-control">
              <button
                v-if="preview"
                class="btn-filter d-flex d-lg-none"
                @mouseup="showOptions = !showOptions"
              >
                <span class="rbi rbi-sort" />
              </button>
              <button type="button" class="close" @click="closeModal(false, true)">
                <span class="rbi rbi-close" />
              </button>
            </div>
          </div>
          <div v-show="preview" class="modal-body">
            <div v-show="showOptions" class="mgifts--main__controls responsive-control d-lg-none">
              <form class="set-flex" @submit.prevent="">
                <div class="form-group">
                  <multiselect
                    v-model="category"
                    :allow-empty="false"
                    :options="sortCategories"
                    :searchable="false"
                    track-by="k"
                    label="v"
                    :show-labels="false"
                  />
                </div>
                <div class="form-group">
                  <multiselect
                    v-model="sortValue"
                    :allow-empty="false"
                    :options="sortTypes"
                    :searchable="false"
                    track-by="name"
                    label="text"
                    :show-labels="false"
                  />
                </div>
                <div class="search-wrap d-none form-group">
                  <input
                    v-model="searchValue"
                    type="search"
                    :placeholder="$t('giftModal.giftName')"
                    class="form-control"
                  />
                  <div>
                    <button type="button" class="search_btn">
                      <span class="rbi rbi-search" />
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div class="mgifts--main">
              <div class="mgifts--main__head d-flex justify-content-center">
                <!--<ul class="nav nav-tabs" role="tablist">
                  <li
                    role="presentation"
                    :class="{ active: data.rec.length }"
                    @mouseup="toggleTab(true)"
                    v-if="data.rec.length"
                  >
                    <a
                      href="#mgift-rec"
                      aria-controls="mgift-rec"
                      role="tab"
                      data-toggle="tab"
                    >
                      <span>{{ $t('giftModal.recommended') }}</span>
                      <span class="text-muted" v-show="data.rec.length"
                        >({{ data.rec.length }})</span
                      >
                    </a>
                  </li>
                  <li role="presentation" :class="{ active: !data.rec.length }">
                    <a
                      href="#mgift-all"
                      aria-controls="mgift-all"
                      @mouseup="toggleTab(false)"
                      role="tab"
                      data-toggle="tab"
                    >
                      <span>{{ $t('giftModal.all') }}</span>
                      <span class="text-muted" v-show="data.all.length"
                        >({{ data.all.length }})</span
                      >
                    </a>
                  </li>
                </ul>-->
                <div class="mgifts--main__controls d-none d-lg-block">
                  <form class="set-flex" @submit.prevent="">
                    <div class="form-group">
                      <multiselect
                        v-model="category"
                        :allow-empty="false"
                        :options="sortCategories"
                        :searchable="false"
                        track-by="k"
                        label="v"
                        :show-labels="false"
                      />
                    </div>
                    <div class="form-group">
                      <multiselect
                        v-model="sortValue"
                        :allow-empty="false"
                        :options="sortTypes"
                        :searchable="false"
                        track-by="name"
                        label="text"
                        :show-labels="false"
                      />
                    </div>
                    <div class="search-wrap hidden-xs form-group">
                      <input
                        v-model="searchValue"
                        type="search"
                        :placeholder="$t('giftModal.giftName')"
                        class="form-control"
                      />
                      <div>
                        <button type="button" class="search_btn">
                          <span class="rbi rbi-search" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="mgifts--main__body">
                <div class="tab-content">
                  <div
                    v-if="data.rec.length"
                    id="mgift-rec"
                    role="tabpanel"
                    class="tab-pane"
                    :class="{ active: data.rec.length }"
                  >
                    <simplebar class="simplebar">
                      <div ref="content" class="gift-container">
                        <div class="row">
                          <gift
                            v-for="item in displayedData"
                            :key="item.id"
                            :click-credits-handler="selectGiftCredits"
                            :click-euro-handler="selectGiftEuro"
                            class="col-lg-4 col-md-6 col-sm-12 col-12 mgifts--item"
                            :gift="item"
                            :chosen-gift-id="chosenData ? chosenData.id : null"
                            :loading="loading"
                          />
                        </div>
                        <div v-show="showLoadBtn" class="button-wrap">
                          <load-more :counter="defaultLimit" @mouseup.native="loadMore" />
                        </div>
                      </div>
                    </simplebar>
                  </div>
                  <!--<div
                    role="tabpanel"
                    class="tab-pane"
                    :class="{ active: !data.rec.length }"
                    id="mgift-all"
                  >
                    <simplebar class="simplebar">
                      <div ref="content2" class="gift-container">
                        <div class="row">
                          <gift
                            v-for="item in displayedData"
                            :click-credits-handler="selectGiftCredits"
                            :click-euro-handler="selectGiftEuro"
                            :key="item.id"
                            class="col-lg-4 col-md-6 col-sm-12 col-12 mgifts&#45;&#45;item"
                            :gift="item"
                            :loading="loading"
                          ></gift>
                        </div>
                        <div class="button-wrap" v-show="showLoadBtn">
                          <load-more
                            @mouseup.native="loadMore"
                            :counter="defaultLimit"
                          ></load-more>
                        </div>
                      </div>
                    </simplebar>
                  </div>-->
                  <div v-show="!isSearching && !displayedGifts.length" class="alert alert-info">
                    {{ $t('actionPages.errors.noData') }}
                  </div>
                  <div v-show="isSearching && !search.result.length" class="alert alert-warning">
                    {{ $t('actionPages.errors.noMatch') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="!preview && chosenData.id" class="mgifts--single">
            <div class="modal-body">
              <div class="mgifts--single__calc">
                <gift
                  :gift="chosenData"
                  :extended="true"
                  :click-euro-handler="selectGiftEuro"
                  :click-credits-handler="selectGiftCredits"
                />
                <div class="calculate item d-flex align-items-center">
                  <div class="calculate__price">
                    <div v-if="payMethod === 1">
                      <span class="rbi rbi-money" />
                      <span>{{ chosenData.sumPrice }}</span>
                    </div>
                    <div v-else>
                      <span class="rbi">€</span>
                      <span>{{
                        chosenData.sumPriceEur ? chosenData.sumPriceEur.toFixed(2) : ''
                      }}</span>
                    </div>
                  </div>
                  <div class="calculate__multiply">
                    <span class="rbi rbi-close" />
                  </div>
                  <div class="calculate__calc" :class="{ error: incorrectAmount }">
                    <button class="calc-btn flip" @mouseup.prevent="changeSum(-1)">
                      <span class="rbi rbi-minus" />
                    </button>
                    <span>{{ chosenData.amount }}</span>
                    <button class="calc-btn plus" @mouseup.prevent="changeSum(1)">
                      <span class="rbi rbi-add" />
                    </button>
                  </div>
                  <div class="calculate__equal">
                    <span class="rbi rbi-equal" />
                  </div>
                  <div class="calculate__result">
                    <div v-if="payMethod === 1">
                      <span class="rbi rbi-money" />
                      <span>{{ sum }}</span>
                    </div>
                    <div v-else>
                      <span class="rbi">€</span>
                      <span>{{ payMethod === 2 ? sumEur.toFixed(2) : '' }}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <gift-pay-system-select
                      v-show="chosenData.sumPriceEur !== 0 && payMethod === 2"
                      :payments-status="paymentsStatus"
                      :pay-system-click-handler="selectPaySystem"
                      :loading="loading"
                    />
                  </div>
                </div>
              </div>
              <form
                class="mgifts--single__form"
                @submit.prevent="payMethod === 1 ? sendGiftCredits() : sendGiftEur()"
              >
                <div class="form-group set-flex set-flex-y-center">
                  <p>{{ $t('giftModal.to') }}:</p>
                  <div class="usr-selection">
                    <div class="small-img-wrap">
                      <img
                        :src="setPhotoSource(user.avatar.img_small)"
                        :alt="user.name + $t('shared.alts.ava')"
                      />
                    </div>
                    <span>{{ user.name }}</span>
                    <span style="margin-left: 7px; color: #e50000"
                      >( Your message will be written in a postcard and delivered with the gift
                      )</span
                    >
                  </div>
                </div>
                <div class="form-group" :class="{ 'has-error': err.first('giftComment') }">
                  <textarea
                    v-model="comment"
                    class="form-control"
                    name="giftComment"
                    :placeholder="$t('mail.placeholders.message')"
                  />
                  <div v-show="err.first('giftComment')" class="error">
                    <i class="fa fa-warning" />
                    <span>{{ err.first('giftComment') }}</span>
                  </div>
                </div>
                <div style="display: flex; justify-content: space-between">
                  <div class="clearfix">
                    <rb-button class="btn-black" type="submit">
                      {{ $t('giftModal.send') }}
                    </rb-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.css';

import { Validator } from 'vee-validate';
import { LOW_CREDITS_MODAL } from '../../../mixins/formsActions';
import { PHOTO_SRC } from '../../../mixins/utils';
import Gift from '../../Authorized/parts/GiftModalItem.vue';
import RbButton from '../../Button';
import LoadMore from '../../Authorized/parts/LoadMore.vue';
import GiftPaySystemSelect from './GiftPaySystemSelect';

export default {
  scrollRec: null,
  scrollAll: null,
  validator: null,
  components: {
    GiftPaySystemSelect,
    gift: Gift,
    LoadMore,
    simplebar,
    RbButton,
  },
  mixins: [PHOTO_SRC, LOW_CREDITS_MODAL],
  data() {
    return {
      showOptions: false,
      sortValue: { name: 'name/0', ask: 0, text: this.$t('mail.sort.default') },
      sortTypes: [
        { name: 'name/0', ask: 0, text: this.$t('mail.sort.default') },
        { name: 'name/true', ask: true, text: this.$t('mail.sort.nameAZ') },
        { name: 'name/false', ask: false, text: this.$t('mail.sort.nameZA') },
        {
          name: 'sumPrice/true',
          ask: true,
          text: this.$t('mail.sort.priceASC'),
        },
        {
          name: 'sumPrice/false',
          ask: false,
          text: this.$t('mail.sort.priceDESC'),
        },
      ],
      sortCategories: [
        {
          k: '',
          v: this.$t('giftModal.categories'),
        },
        {
          k: 'flowers',
          v: this.$t('giftModal.flowers'),
        },
        {
          k: 'jewelry',
          v: this.$t('giftModal.jewelry'),
        },
        {
          k: 'perfume',
          v: this.$t('giftModal.perfume'),
        },
        {
          k: 'electronic_devices',
          v: this.$t('giftModal.elDevices'),
        },
        {
          k: 'toys',
          v: this.$t('giftModal.toys'),
        },
        {
          k: 'food_and_beverage',
          v: this.$t('giftModal.food'),
        },
        {
          k: 'season_tickets_and_certificates',
          v: this.$t('giftModal.tickets'),
        },
        {
          k: 'balloons',
          v: this.$t('giftModal.baloons'),
        },
        {
          k: 'clothing',
          v: this.$t('giftModal.clothing'),
        },
      ],
      render: false,
      searchValue: '',
      activeTab: false, // true - recommend, false - all
      isSearching: false,
      heightTab: null,
      search: {
        result: [],
      },
      err: [],
      preview: true,
      payMethod: 1, // 1 - credits, 2 - euro
      incorrectAmount: false,
      chosenData: {
        amount: 1,
      },
      categories: [],
      category: { k: '', v: this.$t('giftModal.categories') },
      comment: '',
      limit: 9,
      defaultLimit: 9,
      loading: false,
      paymentsStatus: {
        ccbill_gift_online: { id: 1, status: 0, userGroupsId: 4 },
        epayments_gift_online: { id: 2, status: 0, userGroupsId: 3 },
        multicard_gift_online: { id: 3, status: 0, userGroupsId: 1 },
        paymentstrust_gift_online: { id: 4, status: 0, userGroupsId: 5 },
        segpay_gift_online: { id: 5, status: 0, userGroupsId: 2 },
        billline_gift_online: {
          id: 6,
          status: 0,
          userGroupsId: 6,
        },
        billline_usd_gift_online: {
          id: 7,
          status: 0,
          userGroupsId: 7,
        },
        stripe_gift_online: {
          id: 8,
          status: 0,
          userGroupsId: 7,
        },
      },
      actionPayment: 0,
    };
  },
  computed: {
    recomendedTitle() {
      return ` <span>${this.$t('giftModal.recommended')}</span>
                        <span class="text-muted">(${this.data.rec.length})</span>`;
    },

    /**
     * показыть ли кнопку "загрузить еще"
     *
     * @return {boolean}
     */
    showLoadBtn() {
      return this.isSearching
        ? this.displayedData.length < this.search.result.length
        : this.displayedData.length < this.displayedGifts.length;
    },
    /**
     * возвращает отображаемые данные: результаты поиска или список подарков
     *
     * @return {array}
     */
    displayedData() {
      if (this.sortValue === 'name/0') {
        return this.isSearching
          ? this.search.result
              .slice(0, this.limit)
              .sort((a, b) => (a.number_in_queue > b.number_in_queue ? -1 : 1))
          : this.displayedGifts.slice(0, this.limit);
      }
      return this.isSearching
        ? this.search.result.slice(0, this.limit)
        : this.displayedGifts.slice(0, this.limit);
    },
    /**
     * возвращает доступные для отображения подарки
     *
     * @return {Array}
     */
    displayedGifts() {
      if (this.activeTab) {
        const recDataFiltered = this.data.rec.filter((gift) => {
          let existsInAgenciesList = false;
          let existsInGirlsList = false;
          let lockDisplay = true;

          if (gift.agencies_to_gift.length > 0) {
            gift.agencies_to_gift.map((agency) => {
              if (agency.id.toString() === this.user.agency.agency_id) {
                existsInAgenciesList = true;
              }
            });
          }

          if (gift.girls_to_gift.length > 0) {
            gift.girls_to_gift.map((girl) => {
              if (girl.id === this.user.user_id.toString()) {
                existsInGirlsList = true;
              }
            });
          }

          if (gift.girls_to_gift.length === 0 && gift.agencies_to_gift.length === 0) {
            lockDisplay = false;
          }

          if (!lockDisplay || existsInAgenciesList || existsInGirlsList) {
            return gift;
          }
        });

        if (this.sortValue.name !== 'name/0') {
          return recDataFiltered.filter(
            (i) => (this.category.k === '' ? true : i.category === this.category.k),
            this
          );
        }
        return recDataFiltered
          .filter((i) => (this.category.k === '' ? true : i.category === this.category.k), this)
          .sort((a, b) => (a.number_in_queue > b.number_in_queue ? -1 : 1));
      }
      const allDataFiltered = this.data.all.filter((gift) => {
        let existsInAgenciesList = false;
        let existsInGirlsList = false;
        let lockDisplay = true;

        if (gift.agencies_to_gift.length > 0) {
          gift.agencies_to_gift.map((agency) => {
            if (agency.id.toString() === this.user.agency.agency_id) {
              existsInAgenciesList = true;
            }
          });
        }

        if (gift.girls_to_gift.length > 0) {
          gift.girls_to_gift.map((girl) => {
            if (girl.id === this.user.user_id.toString()) {
              existsInGirlsList = true;
            }
          });
        }

        if (gift.girls_to_gift.length === 0 && gift.agencies_to_gift.length === 0) {
          lockDisplay = false;
        }

        if (!lockDisplay || existsInAgenciesList || existsInGirlsList) {
          return gift;
        }
      });

      if (this.sortValue.name !== 'name/0') {
        return allDataFiltered.filter(
          (i) => (this.category.k === '' ? true : i.category === this.category.k),
          this
        );
      }
      return allDataFiltered
        .filter((i) => (this.category.k === '' ? true : i.category === this.category.k), this)
        .sort((a, b) => (a.number_in_queue > b.number_in_queue ? -1 : 1));
    },
    modal() {
      return this.$store.getters.giftModal;
    },
    /**
     * получить список подарков для всех вкладок
     * @return {{rec: (Array), all: (Array)}}
     */
    data() {
      // console.log(this.modal.rec)
      let retryGiftItem = null;
      if (this.modal.retryGift) {
        retryGiftItem = this.modal.all.find((gift) => gift.id === this.modal.retryGift.gift_id);

        if (retryGiftItem) {
          retryGiftItem.retryGiftId = this.modal.retryGift ? this.modal.retryGift.id : null;
          this.selectGiftEuro(retryGiftItem, this.modal.retryGift.amount);
          /* this.chosenData = retryGiftItem[0] */
          /* this.payMethod = 2 */
        }
      }
      return {
        rec: this.modal.rec,
        all: this.modal.all,
        retry: retryGiftItem || null,
      };
    },

    /**
     * возвращает текущего пользователя, которому будет отправляться подарок
     *
     * @return {state.giftModal.currentUser|{avatar}|*|currentUser} {object}
     */
    user() {
      return this.modal.currentUser;
    },
    open() {
      return this.modal.open;
    },
    /**
     * возвращает стоимость выбранных для покупки подарков в кредитах
     *
     * @return {number}
     */
    sum() {
      return this.chosenData.sumPrice * this.chosenData.amount;
    },
    sumEur() {
      return this.chosenData.sumPriceEur * this.chosenData.amount;
    },
  },
  watch: {
    /**
     * Запуск инициализации скроллбаров при открытии модалки
     *
     * @param v
     */
    open(v) {
      if (v) {
        if (this.modal.rec.length) {
          this.toggleTab(true);
          setTimeout(() => {
            const el = this.$refs.content;
            if (el) this.heightTab = `${el.clientHeight}px`;
          }, 300);
        } else {
          this.toggleTab(false);
          setTimeout(() => {
            const el = this.$refs.content2;
            if (el) this.heightTab = `${el.clientHeight}px`;
          }, 300);
        }
      }
      /*        if (v) {
                  setTimeout(() => {
                    let el = this.$refs.content2
                    this.heightTab = el.clientHeight + 'px'
                  }, 300)
                } */
    },
    /**
     * Очистка состояния компонента при переключении между табами
     *
     * @param v
     */
    activeTab() {
      this.clearParams();
      this.searchValue = '';
      this.sortValue = {
        name: 'name/0',
        ask: 0,
        text: this.$t('mail.sort.default'),
      };
    },
    /**
     * Запуск поиска по названию
     *
     * @param v {string} - название подарка
     */
    searchValue(v) {
      if (v.length) {
        this.searching(v);
        this.isSearching = true;
      } else {
        this.isSearching = false;
        this.clearParams();
      }
    },
    /**
     * Очистка параметров при смене режима просмотра модалки
     *
     * @param v {boolean}
     */
    preview(v) {
      this.clearParams();
      if (v) {
        this.chosenData = {
          amount: 1,
        };
      }
      this.incorrectAmount = false;
      this.category = { k: '', v: this.$t('giftModal.categories') };
      this.searchValue = '';
      this.sortValue = {
        name: 'name/0',
        ask: 0,
        text: this.$t('mail.sort.default'),
      };
    },
    /**
     * Запуск сортировки результатов
     *
     * @param val {string}
     */
    sortValue(val) {
      this.sorting(val.name);
    },
    /**
     * Валидация комментария к подарку
     *
     * @param v {string}
     */
    comment(v) {
      this.validator.validate('giftComment', v).catch(() => {});
    },
  },
  mounted() {
    this.render = true;
  },
  created() {
    /**
     * инициализация плагина с валидацией
     *
     * @type {Validator}
     */
    this.validator = new Validator();
    this.validator.attach('giftComment', 'max:255', {
      prettyName: this.$t('mail.placeholders.message'),
    });
    this.$set(this, 'err', this.validator.errorBag);
  },
  methods: {
    tabChanged(selectedTab) {
      if (selectedTab.tab.id === 'my') {
        this.toggleTab(true);
      } else {
        this.toggleTab(false);
      }
    },

    /**
     * Закрыть модалку с очисткой состояния
     */
    closeModal() {
      this.preview = true;
      this.comment = '';
      this.category = { k: '', v: this.$t('giftModal.categories') };
      this.searchValue = '';
      this.sortValue = {
        name: 'name/0',
        ask: 0,
        text: this.$t('mail.sort.default'),
      };
      this.chosenData = {
        amount: 1,
      };

      this.activeTab = false;

      this.actionPayment = 0;
      this.payMethod = 1;
      this.incorrectAmount = false;
      this.$store.commit('updateGiftModal', {
        open: false,
        user_id: 0,
        all: [],
        rec: [],
      });
      this.clearParams();
    },
    /**
     * Сбросить параметр котображения кол-ва результатов на вкладке
     */
    clearParams() {
      this.limit = this.defaultLimit;
    },
    /**
     * сортировка данных по типу и порядку
     *
     * @param sort {array}
     */
    sorting(sort) {
      const tmp = sort.split('/');

      const info = {
        name: tmp[0] || 'price',
        ask: tmp[1] === 'true',
      };
      const sortFunc = function (a, b) {
        if (info.ask) {
          return a[info.name] > b[info.name] ? 1 : -1;
        }
        return a[info.name] > b[info.name] ? -1 : 1;
      };
      if (this.isSearching) {
        this.search.result.sort(sortFunc);
      } else if (this.activeTab) {
        // 	  for(let i = 0; i < this.data.rec.length; i++){
        //   this.data.rec[i].name = this.data.rec[i].name.trim()
        // }
        this.data.rec.sort(sortFunc);
      } else {
        // for(let i = 0; i < this.data.all.length; i++){
        //   this.data.all[i].name = this.data.all[i].name.trim()
        // }
        this.data.all.sort(sortFunc);
      }
    },
    /**
     * переключить таб
     *
     * @param v {boolean} true - recommend, false - all
     */
    toggleTab() {
      this.activeTab = false;
    },
    /**
     * поиск по имени подарка
     *
     * @param val {string}
     */
    searching(val) {
      val = val.toLowerCase();
      this.search.result = this.displayedGifts.filter((item) =>
        item.name.toLowerCase().includes(val.toLowerCase())
      );
    },
    /**
     * выбрать подарок для отправки
     *
     * @param item {object}
     */
    selectGiftCredits(item) {
      this.chosenData = { ...item, amount: 1 };
      this.payMethod = 1;
      this.preview = false;
    },

    selectPaySystem(paySystemId) {
      this.actionPayment = parseInt(paySystemId);
    },

    selectGiftEuro(item, amount) {
      this.chosenData = { ...item, amount: amount || 1 };
      if (this.payMethod !== 2) {
        this.payMethod = 2;
        this.loading = true;
        this.$http
          .post('v1/gift/send-money', {
            access_token: window.localStorage['access-token'],
            target: this.user.user_id,
            gift: this.chosenData.id,
            count: this.chosenData.amount,
          })
          .then((response) => {
            if (response.body.status) {
              const { payments } = response.body.result;
              console.log(response.body);
              console.log(payments)
              this.paymentsStatus.ccbill_gift_online.status =
                payments.ccbill_gift_online !== 0 ? payments.ccbill_gift_online : 0;
              this.paymentsStatus.multicard_gift_online.status =
                payments.multicard_gift_online !== 0 ? payments.multicard_gift_online : 0;
              this.paymentsStatus.segpay_gift_online.status =
                payments.segpay_gift_online !== 0 ? payments.segpay_gift_online : 0;
              this.paymentsStatus.epayments_gift_online.status =
                payments.epayments_gift_online !== 0 ? payments.epayments_gift_online : 0;
              this.paymentsStatus.paymentstrust_gift_online.status =
                payments.paymentstrust_gift_online !== 0 ? payments.paymentstrust_gift_online : 0;
              this.paymentsStatus.billline_gift_online.status =
                payments.billline_gift_online !== 0 ? payments.billline_gift_online : 0;
              this.paymentsStatus.billline_usd_gift_online.status =
                payments.billline_usd_gift_online !== 0 ? payments.billline_usd_gift_online : 0;
              this.paymentsStatus.stripe_gift_online.status =
                payments.stripe_gift_online !== 0 ? payments.stripe_gift_online : 0;

              this.preview = false;
              this.loading = false;
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
                info: 'Gift sending process failed to start.',
              });
            }
          })
          .catch((err) => {
            console.log('Error', err);
          });
      }
    },
    /**
     * Обработчик изменения суммы
     *
     * @param num {number}:  1 || -1
     */
    changeSum(num) {
      if (this.chosenData.amount === 1 && num < 0) {
        this.incorrectAmount = true;
      } else {
        this.incorrectAmount = false;
        this.chosenData.amount += num;
      }
    },
    /**
     * вернуться в режим пре-просмотра
     */
    goBack() {
      this.payMethod = 1;
      this.preview = true;
    },

    /**
     * провалидировать комментарий и отправить подарок
     */
    sendGiftCredits() {
      this.validator
        .validateAll({
          giftComment: this.comment,
        })
        .then((success) => {
          if (success && !this.error) {
            this.sending();
          }
        })
        .catch(() => {});
    },
    sendGiftEur() {
      this.validator
        .validateAll({
          giftComment: this.comment,
        })
        .then((success) => {
          if (success && !this.error) {
            if (this.actionPayment !== 0) this.sendingEur();
            else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: 'To send a gift, select a payment system.',
                info: 'no pay system.',
              });
            }
          }
        })
        .catch(() => {});
    },
    /**
     * фукция отправки подарка (оплата в кредистах)
     */
    sending() {
      if (!this.loading) {
        this.loading = true;
        this.$http
          .post('v1/gift/send', {
            access_token: window.localStorage['access-token'],
            target: this.user.user_id,
            comment: this.comment,
            gift: this.chosenData.id,
            count: this.chosenData.amount,
          })
          .then(
            (r) => {
              if (r.body.status) {
                this.$store.commit('addAlert', {
                  type: 'success',
                  text: this.$t('alerts.giftSentSuccess'),
                });
                this.loading = false;
                this.closeModal();
              } else {
                const { code } = r.body;
                if (code === 10161 || code === 10025 || !r.body.status || !r.body.status) {
                  this.loading = false;
                  this.openCreditsModal();
                } else {
                  this.loading = false;
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: this.$t('alerts.someErr'),
                    info: 'gift sending failed',
                  });
                }
              }
            },
            () => {
              this.loading = false;
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
                info: 'gift sending failed',
              });
            }
          );
      }
    },
    /**
     * фукция отправки подарка (оплата в евро)
     */
    sendingEur() {
      if (!this.loading) {
        this.loading = true;
        this.$http
          .post('v1/gift/send-money', {
            access_token: window.localStorage['access-token'],
            target: this.user.user_id,
            payment: this.actionPayment,
            comment: this.comment,
            gift: this.chosenData.id,
            count: this.chosenData.amount,
          })
          .then(
            (r) => {
              if (r.body.status) {
                this.$store.commit('addAlert', {
                  type: 'success',
                  text: 'Gift pay process started on new tab.' /* this.$t('alerts.giftSentSuccess') */,
                });
                this.loading = false; // new tab (ios don't work) // same tab
                /* window.open(r.body.result, '_blank') */ /* window.location.assign(r.body.result) */
                window.location = r.body.result;
                this.closeModal();
              } else {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.someErr'),
                  info: 'Gift sending failed',
                });
                this.loading = false;
                this.payMethod = 2;
                /* let code = r.body.code
                if (
                  code === 10161 ||
                  code === 10025 ||
                  !r.body.status ||
                  !r.body.status
                ) {
                  this.openCreditsModal()
                } else {
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: this.$t('alerts.someErr'),
                    info: 'gift sending failed',
                  })
                } */
              }
            },
            () => {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
                info: 'gift sending failed',
              });
              this.loading = false;
              this.payMethod = 2;
            }
          );
      }
    },
    /**
     * показ бОльшего кол-ва данных на табе
     */
    loadMore() {
      this.limit += this.defaultLimit;
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/vars';

.gift-container {
  padding: 0 15px;
}
.nav-tabs {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  background: #f6f6f6;
  height: 50px;

  li {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #e21919;
      bottom: 0;
      left: 0;
      transform: scale(0);
      transform-origin: 50% 50%;
      transition: 0.3s;
    }

    &.active {
      &:after {
        transform: scale(1);
      }

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -12px;
        border: 6px solid transparent;
        border-top: 6px solid #e21919;
      }
    }

    a {
      height: 50px;
      padding: 10px 30px;
      color: #000000;
      font-size: 12px;
      font-weight: 300;
      line-height: 50px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      text-decoration: none;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      justify-content: center;

      .text-muted {
        margin-left: 0.3em;
      }
    }
  }
}

.button-wrap {
  padding: 20px 0;
}

.modal {
  &-header {
    padding: 20px 30px 20px;

    .go-back {
      padding: 10px 15px;
      font-size: 12px;

      .rbi {
        margin-right: 0.3em;
      }
    }
  }

  &-body {
    padding: 0 15px 20px 30px;
  }

  &-control {
    display: flex;

    .btn-filter {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1em;
    }
  }
}

.mgifts {
  .simplebar {
    max-height: 540px;

    &-wrapper {
      padding-right: 15px;
    }

    &-track {
      &.vertical {
        width: 3px;
        background: #e9e9e9;
      }

      .simplebar-scrollbar {
        width: 3px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background: $red;
        right: 0;
      }
    }
  }

  &--container {
    width: 850px;
    max-width: 100%;
    min-width: 290px;
    padding: 0;

    &.wide {
      width: 1180px;
    }
  }

  &--main {
    &__head {
      padding-right: 15px;
    }

    &__body {
      margin-top: 20px;
    }

    &__controls {
      .form-group {
        width: 164px;
        margin-left: 15px;

        &.search-wrap {
          .form-control {
            padding-right: 40px;
          }
        }

        .form-control {
          line-height: inherit;
          width: 100%;
        }

        .search_btn {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 20px;
          border: none;
          background: none;
        }
      }
    }
  }

  &--single {
    .modal-body {
      padding: 0 30px 20px;
    }

    &__form {
      .form-group {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 15px;
        }
      }

      .usr-selection {
        display: flex;
        align-items: center;

        .small-img-wrap {
          padding: 3px;
          position: relative;
          margin-right: 10px;

          &:before {
            position: absolute;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            border: 1px solid transparent;
            border-right-color: $black;
          }

          img {
            width: 45px;
            height: 45px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
          }
        }
      }

      textarea.form-control {
        width: 100%;
        height: 200px;
      }
    }
  }
}

/*  .modal-title-control {
      display: flex;
      align-items: center;

      .btn-filter {
        background: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .modal-header {
      margin-bottom: 25px;
    }

    .mgifts {
      .mgifts--container {
        max-width: 100%;
        width: 700px;

        &.wide {
          width: 1030px;
        }

        .simplebar-track.horizontal {
          display: none;
        }

        .row {
          padding-bottom: 5px;
        }
      }

      .modal-body {
        padding: 0;
      }

      .modal-header {
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modal-title {
          font-size: 18px;
        }

        .item {
          flex: 0 0 50%;
          display: flex;

          &:last-child {
            justify-content: flex-end;
          }
        }
      }

      &--main {
        .mgifts--item {
          margin-top: 15px;
          cursor: pointer;

          &:hover {
            .mgifts--item__img {
              img {
                filter: brightness(1.1);
              }
            }
          }

        }

        &__controls {
          svg {
            fill: #bdbdbd;
            height: 16px;
            width: 16px;
          }

          form {
            > div + div {
              margin-left: 10px;
            }
          }
        }

        &__head {
          justify-content: space-between;
        }

        !*li {*!
          !*span {*!
            !*font-weight: normal !important;*!
          !*}*!

          !*a {*!
            !*font-size: 13px;*!
            !*padding: 10px 15px;*!
            !*border: 1px solid transparent;*!
          !*}*!

          !*&.active {*!
            !*a {*!
              !*border: 1px solid #e2dfdf;*!
              !*border-bottom: none;*!
            !*}*!
          !*}*!
        !*}*!

        .search-wrap {
          position: relative;

          > div {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
          }

          .btn {
            background: none;
            padding-top: 8px;

            &:active {
              -webkit-box-shadow: none;
              -moz-box-shadow: none;
              box-shadow: none;
            }
          }

        }

        &__body {
          padding: 0 15px;

          .tab-content {
            padding-bottom: 15px;
          }
        }

        .simplebar {
          max-height: 500px;
        }

      }

      &--single {
        .btn-nav {
          background: none;
          border: 1px solid #dcdcdc;
          color: #bdbdbd;

          svg {
            height: 16px;
            width: 16px;
            fill: currentColor;

            + span {
              margin-left: 5px;
            }
          }
        }

        .modal-body {
          padding: 15px;
        }

        &__calc {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #d3d3d3;

          svg {
            fill: currentColor;
            height: 20px;
            width: 20px;
          }

          .item {
            display: flex;
            align-items: center;
            flex: 1 1;
            margin-left: 1%;
            justify-content: space-between;
          }

          .calc {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 105px;
            width: 36px;
            border: 1px solid #d3d3d3;
            padding: 1px;
            font-size: 18px;
            border-radius: 50px;

            &.error {
              border-color: #d9534f;
            }

            &-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 0;
              border-radius: 50%;
              height: 32px;
              width: 32px;
              background-color: #d3d3d3;
              color: #fff;
              cursor: pointer;
              user-select: none;

              &:active {
                background-color: darken(#d3d3d3, 10%);
              }

              svg {
                height: 10px;
                width: 10px;
              }

              &.flip {
                svg {
                  transform: scaleY(-1);
                }
              }
            }

            &__result {
              display: flex;
              align-items: center;
              font-size: 18px;

              img {
                height: 36px;
                width: 36px;
              }

              span + span {
                margin-left: 10px;
              }
            }
          }
        }

        &__form {
          margin-top: 30px;

          p {
            color: #bdbdbd;
          }

          textarea {
            resize: none;
            height: 160px;
          }

          .btn-primary {
            padding: 6px 40px;
            font-size: 12px;
            text-transform: uppercase;
          }

          .usr-selection {
            display: flex;
            align-items: center;
            color: #3e3f42;
            margin-left: 10px;
            background-color: #ededed;
            border-radius: 50px;
            padding-right: 20px;

            img {
              height: 36px;
              width: 36px;
              border-radius: 50%;
            }

            div + span {
              margin-left: 10px;
            }
          }
        }

        .load-more {
          margin-top: 15px;
          flex: 0 0 40px;
        }

      }

      .alert {
        margin-top: 15px;
      }
    }

    @media only screen and (max-width: 992px) {
      .mgifts {
        &--container {
          &.wide {
            width: 100% !important;
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .vue-modal {
        padding: 10px;
      }
      .mgifts {
        &--item {
          &__img {
            justify-content: center;
            flex: 0 0 40%;

          }

          &__info {
            flex: 0 0 50%;

            .tip {
              margin: 10px 0;
              line-height: 1.2 !important;
            }
          }

          &:after {
            border: 1px solid grey;
          }
        }

        &--single {
          &__calc {
            flex-direction: column;

            .item {
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: space-around;
            }

            .calc {
              flex-direction: row-reverse;
              width: 100%;
              height: auto;

              .calc-btn {
                transform: rotate(90deg);
              }

              span {
                margin: 0 10px;
              }
            }
          }
        }

        .modal-header {
          .filter {
            border: none;
            background: transparent;
            line-height: 1;

            svg {
              margin-right: 15px;
              width: 16px;
              height: 16px;
              fill: #c6c6c6;
            }
          }
        }

        &--main {
          &__body {
            .simplebar {
              overflow-x: hidden;
            }
          }

          &__head {
            flex-wrap: wrap;

            .nav-tabs {
              padding: 0;
              display: flex;
            }
          }

          &__controls {
            padding: 15px;
          }
        }
      }
    }*/

.tab-content > .tab-pane.active {
  display: block;
}

.tab-content > .tab-pane {
  display: none;
}

.calculate {
  margin: 20px 0;

  &__calc {
    border: 1px solid $grey-two;
    display: flex;
    align-items: center;
    margin: 0 15px;

    button {
      background: $light-grey;
      border: none;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;

      &:first-child {
        border-right: 1px solid $grey-two;
      }

      &:last-child {
        border-left: 1px solid $grey-two;
      }
    }

    > span {
      min-width: 30px;
      height: 30px;
      display: inline-block;
      text-align: center;
      line-height: 30px;
      font-size: 18px;
      padding: 0 10px;
    }
  }

  &__price,
  &__result {
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-right: 15px;

    .rbi {
      font-size: 25px;
      margin-right: 0.3em;
    }
  }

  &__equal {
    margin-right: 15px;
  }

  &__result {
    margin-right: 0;
  }
}

.responsive-control {
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .mgifts {
    .simplebar {
      max-height: 400px;
    }
  }
  .nav-tabs {
    width: 100%;

    li {
      width: 100%;

      &:first-child {
        margin-left: 0;
      }
    }
  }
  .responsive-control {
    padding-right: 15px;

    .form-group {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .mgifts {
    .simplebar {
      max-height: 350px;
    }
  }
  .nav-tabs {
    width: 100%;

    li {
      width: 100%;
      a {
        padding: 10px 10px;
        width: 100%;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
  .mgifts--item__img img {
    width: 75px !important;
    height: 75px !important;
  }
  .mgifts--item__info__price {
    font-size: 18px !important;
    .rbi {
      font-size: 22px !important;
    }
  }
}

@media screen and (max-width: 400px) {
  .mgifts--item.extended .info {
    flex-direction: column;
    > .tip {
      margin-top: 20px;
    }
  }

  .calculate__price {
    display: none;
  }

  .mgifts--single__form .form-group {
    margin-bottom: 15px;
  }
  .mgifts--single__form textarea.form-control {
    height: 150px;
  }
}
</style>
