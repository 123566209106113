<template>
  <div class="toast--box">
    <t-template
      v-for="toast in toasts"
      :key="toast.notificationId"
      class="toast set-flex"
      :toast="toast"
    />
  </div>
</template>

<script>

import Template from './toasts/_template.vue';

export default {
  components: {
    tTemplate: Template,
  },
  data() {
    return {};
  },
  computed: {
    toasts() {
      return this.$store.getters.toasts;
    },
  },
  methods: {},
};
</script>

<style lang="scss">

	@import "../../assets/scss/variables";
	@import "../../assets/scss/vars";

	.toast {

		&--box {
			position: fixed;
			top: 0;
			right: 0;
			z-index: 7000;
			font-size: 15px;
		}

		&--base {
			align-items: center;
			color: #ffffff;
			background-color: $dark;
			padding: 10px 15px;
			width: auto;
			cursor: pointer;
			line-height: 1;
      display: flex;
      margin: 5px;
      justify-content: space-between;

			.rbi {
				margin-right: 10px;
        font-size: 20px;
			}

			.user-name {
				border-bottom: 1px dashed #fff;
				font-weight: bold;
			}
		}

		&--link {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 35px;
			width: 35px;
			min-width: 35px;
      margin-left: 25px;
      border: 1px solid #ffffff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      .rbi{
        margin: 0;
        font-size: 16px;
      }
		}
	}

</style>
