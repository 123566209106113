<template>
  <div class="toast--favorite toast--base set-flex">
    <audio
      v-if="settings.sound !== 'off' && settings.sound_new_favorite === 'on'"
      :src="selectSound('toast')"
      autoplay
    />
    <div class="d-flex align-items-center">
      <span class="rbi rbi-heart" />
      <p v-html="$t('toasts.favorite', [name])" />
    </div>
    <div class="toast--link">
      <span class="rbi rbi-right-arrow" />
    </div>
    <slot name="usr-name" />
    <slot name="link" />
  </div>
</template>

<script>

import { NOTIFICATION_SOUND } from '../../../mixins/utils';

export default {
  mixins: [NOTIFICATION_SOUND],
  props: {
    name: String,
  },
  computed: {
    settings() {
      return this.$store.getters.settings;
    },
  },
  mounted() {
    /**
			 * Проиграть звуковое уведомление, если установлено соответствующее разрешение в настройках
			 */
    // if ( this.settings.sound !== 'off' && this.settings.sound_new_favorite === 'on' ) {
    // 	this.playNotification();
    // }
  },
  created() {
    /**
			 * Выбрать звук для уведомления
			 */
    // if ( this.settings.sound !== 'off' && this.settings.sound_new_favorite === 'on' ) {
    //   this.selectSound('toast');
    // }
  },
};
</script>
