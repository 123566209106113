<template>
  <div class="photo-box">
    <div class="contain-edit">
      <h2 v-if="myProfile" class="title-profile edit-caption">
        <router-link :to="{ name: 'albums' }">
          {{ $t('shared.common.myPhotos') }} </router-link
        ><span v-text="photos.length ? `(${photos.length})` : ''" />
      </h2>
      <h2 v-else class="title-profile edit-caption">
        {{ $t('shared.common.photos') }} <span v-text="photos ? `(${photos.length})` : ''" />
      </h2>
    </div>
    <div class="flexblock">
      <div v-if="!photos.length && myProfile" class="alert alert-tip">
        {{ $t('profile.errors.noPhotos') }}
        <router-link :to="{ name: 'albums' }">
          {{ $t('profile.addPhotos') }}
        </router-link>
      </div>
      <div
        v-for="(photo, i) in limited"
        :key="photo.photo_id"
        v-background="{ img: photo.img_preview, imgClass: 'obj-fit-dir' }"
        class="guest-gallery item"
        @click="takePhotos(preview(false), photos, i)"
      >
        <div class="img-wrap">
          <img class="obj-fit-dir" :src="photo.img_preview" :alt="name + $t('shared.alts.photo')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PHOTO_SRC, GALLERY_INDEX } from '../../../mixins/utils';
import { PREVIEW_MODAL, REGISTRATION_MODAL } from '../../../mixins/formsActions';

export default {
  mixins: [PHOTO_SRC, GALLERY_INDEX, PREVIEW_MODAL, REGISTRATION_MODAL],
  props: {
    name: String,
    photos: Array,
    limit: Number,
    id: Number,
  },
  data() {
    return {
      slideItem: 0,
    };
  },
  computed: {
    myProfile() {
      return this.id === this.myId;
    },
    selfUserLoaded() {
      return !!this.$store.getters.user.user_id; // check if self user loaded for preventing incorrect display some components
    },
    authorized() {
      return !!window.localStorage['access-token'];
    },
    myId() {
      return this.$store.getters.user.user_id;
    },
    limited() {
      return this.photos ? this.photos.slice(0, this.limit) : [];
    },
  },
  beforeUpdate() {
    //			this.cachePhotos();
  },
  mounted() {
    //			this.cachePhotos();
  },
  methods: {
    /**
     * Обработка привилегий на клик по фотографии
     *
     * @param bool {boolean} условие
     * @return {boolean}
     */
    preview(bool) {
      if (this.deviceWidth <= 768 && !this.authorized) {
        this.openRegistrationModal();
        return;
      }
      if (bool) {
        // show usual preview
        this.openPreviewModal(this.id, bool);
      } else if (this.authorized) {
        // if authorized and bool == false
        return true;
      } else {
        // if non authorized and bool == false
        this.openPreviewModal(this.id, bool);
      }
      return false;
    },
    /**
     * кэширование первых 6 фотографий
     */
    cachePhotos() {
      if (this.photos) {
        const cache = [];
        const photos = this.photos.slice(0, 6);
        photos.forEach((img) => {
          const tmp = new Image();
          tmp.src = this.setPhotoSource(img.img_origin);
          cache.push(tmp);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/variables';
@import '../../../assets/scss/vars';

.img-wrap {
  position: relative;
  padding-bottom: 88%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}

h2 {
  a {
    color: inherit;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
}

.alert-tip {
  flex: 0 0 100%;
  background: $light-grey;
  color: #828282;
  padding: 25px;

  a {
    color: $brand-primary;
  }
}
</style>
