<template>
  <aside
    class="sidebar_left"
    sticky-container
    :class="{ 'open-bar': sidebarStatus }"
    @click="toggleSidebar($event, false)"
  >
    <div v-sticky="shouldStick" sticky-offset="offset" sticky-side="top" class="sidebar-wrap">
      <div class="sidebar">
        <ul class="list-unstyled nav">
          <li>
            <router-link
              v-if="sex.isMan"
              :to="{ name: 'search-user', params: { id: user.user_id } }"
            >
              <span class="img-box rbi-female" />
              <span class="toggle">{{ $t('sidebar.women') }}</span>
              <template v-if="false">
                <!--hide counters-->
                <span v-if="+counters.online_woman" class="online-indicator">{{
                  +counters.online_woman
                }}</span>
              </template>
              <template v-else>
                <span
                  class="online-indicator"
                  style="
                    width: 8px;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                  "
                />
              </template>
            </router-link>
            <router-link
              v-if="sex.isWoman"
              :to="{ name: 'search-user', params: { id: user.user_id } }"
            >
              <span class="img-box rbi-male" />
              <span class="toggle">{{ $t('sidebar.men') }}</span>
              <template v-if="false">
                <!--hide counters-->
                <span v-if="+counters.online_man" class="online-indicator">{{
                  +counters.online_man
                }}</span>
              </template>
              <template v-else>
                <span
                  class="online-indicator"
                  style="
                    width: 8px;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                  "
                />
              </template>
            </router-link>
          </li>
          <li>
            <a @click.prevent="previewStartChat(false, true)">
              <span class="rbi-webcam" />
              <span class="toggle">{{ $t('sidebar.chat') }}</span>
            </a>
          </li>
          <!--<li>
            <a @click.prevent="previewStartChat(true, true)">
              <span class="rbi-webcam"></span>
              <span class="toggle">Go to tab chat. (test)</span>
            </a>
          </li>-->
          <li>
            <router-link :to="{ name: 'mailbox' }">
              <span class="rbi-message">
                <span
                  v-show="
                    sex.isMan ? counters.mails_inbox_new : +counters.mails_inbox_new_not_reply
                  "
                  class="badge"
                  >{{
                    sex.isMan ? counters.mails_inbox_new : counters.mails_inbox_new_not_reply
                  }}</span
                >
              </span>
              <span class="toggle">
                {{ $t('sidebar.mail') }}
                <span v-show="counters.mails_inbox" class="counter"
                  >({{ counters.mails_inbox }})</span
                >
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'winks' }">
              <span class="rbi-emoji">
                <span v-show="counters.winks_new" class="badge">{{ counters.winks_new }}</span>
              </span>
              <span class="toggle">
                {{ $t('sidebar.winks') }}
                <span v-show="counters.winks_all" class="counter">({{ counters.winks_all }})</span>
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'favorites' }">
              <span class="rbi-heart">
                <span
                  v-show="sex.isMan ? counters.invites_all : counters.favorites_new"
                  class="badge"
                  >{{ sex.isMan ? counters.invites_all : counters.favorites_new }}</span
                >
              </span>
              <span class="toggle">
                {{ $t('sidebar.favorites') }}
                <span v-show="counters.favorites_all" class="counter"
                  >({{ counters.favorites_all }})</span
                >
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'bookmarks' }">
              <span class="rbi-star" />
              <span class="toggle">
                {{ $t('sidebar.bookmarks') }}
                <span v-show="counters.bookmarks_all" class="counter"
                  >({{ counters.bookmarks_all }})</span
                >
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'guests' }">
              <span class="rbi-eye">
                <span v-show="counters.guests_new" class="badge">{{ counters.guests_new }}</span>
              </span>
              <span class="toggle">
                {{ $t('sidebar.guests') }}
                <span v-show="counters.guests_all" class="counter"
                  >({{ counters.guests_all }})</span
                >
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: sex.isWoman ? 'my-gifts' : 'gifts' }">
              <span class="rbi-gift">
                <span v-show="counters.giftsNew || counters.virtualGiftsNew" class="badge">{{
                  counters.giftsNew || counters.virtualGiftsNew
                }}</span>
              </span>
              <span class="toggle"
                >{{ $t('sidebar.gifts')
                }}<span v-show="counters.giftsAll || counters.virtualGiftsAll" class="counter"
                  >({{ counters.giftsAll || counters.virtualGiftsAll }})</span
                ></span
              >
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'feed' }">
              <span class="rbi-newspaper" />
              <span class="toggle">{{ $t('sidebar.news') }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'notifications' }">
              <span class="rbi-bell" />
              <span class="toggle">{{ $t('sidebar.notifications') }}</span>
            </router-link>
          </li>
          <li v-if="sex.isMan">
            <router-link :to="{ name: 'orders' }">
              <span class="rbi-credit-card" />
              <span class="toggle">{{ $t('sidebar.orders') }}</span>
            </router-link>
          </li>
          <li class="divider d-lg-none" />
          <li class="d-lg-none">
            <router-link :to="{ name: 'settings' }">
              <span class="rbi-gear" />
              <span class="toggle">{{ $t('sidebar.settings') }}</span>
            </router-link>
          </li>
          <li v-if="sex.isMan" class="d-lg-none">
            <router-link :to="{ name: 'payments' }">
              <span class="rbi-invoice" />
              <span class="toggle">{{ $t('sidebar.payments') }}</span>
            </router-link>
          </li>
          <li class="d-lg-none">
            <router-link to="/logout" @click.prevent="logout()">
              <span class="rbi-logout" />
              <span class="toggle">{{ $t('sidebar.logout') }}</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="banner auth hidden-md">
        <div v-if="sex.isMan || !isAuthorized" class="swiper-container banner-slider">
          <div class="swiper-wrapper">
            <router-link tag="div" :to="{ name: 'add-credits' }" class="swiper-slide">
              <img src="/static/images/sidebar_banner/banner_side1.png" alt="banner" />
            </router-link>
          </div>
          <!--<div class="swiper-pagination banner-pagination"></div>-->
        </div>
      </div>
    </div>

    <div class="backdrop" :class="{ open: sidebarStatus }" />
  </aside>
</template>

<script>
// import theiaStickySidebar from 'theia-sticky-sidebar'
import { mapGetters } from 'vuex';
import { USER_ACTIONS, LOGOUT, ACTIONS_DATA } from '../../../mixins/userActions';

export default {
  swiper: null,
  components: {},
  mixins: [USER_ACTIONS, LOGOUT, ACTIONS_DATA],
  props: {
    show: Boolean,
  },
  data() {
    return {
      offset: { top: 15, bottom: 10 },
      usersOnline: [],
    };
  },
  watch: {
    width(v) {
      if (v > 992) {
        this.$store.dispatch('sidebarHandler', false);
      }
    },
    sex() {
      //				this.initSwiper();
    },
  },
  mounted() {
    if (this.sex.isMan || !this.isAuthorized) {
      //				this.initSwiper();
    }
    /**
     * инициализация sticky-сайдбара
     */
    // jQuery( document ).ready( function () {
    // 	jQuery( '.theiaStickySidebar' ).theiaStickySidebar( {
    // 		// Settings
    // 		additionalMarginTop: 15
    // 	} );
    // } );
  },
  methods: {
    /**
     * закрыть сайдбар клиике
     * @param $event
     */

    toggleSidebar(e, status) {
      if (e.target.className !== 'sidebar') {
        this.$store.dispatch('sidebarHandler', status);
      }
    },
    closeBar($event) {
      if (
        $event.target !==
        'div#sticky-sidebar.col-lg-2.col-md-2.col-sm-1.theiaStickySidebar.open-bar'
      ) {
        this.$emit('closeSidebar');
      }
    },
    /**
     * инициализация плагина с галереей (свипер)
     */
    initSwiper() {
      const self = this;
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        self.swiper = new Swiper('.banner-slider', {
          //						pagination: '.banner-pagination',
          slidesPerView: 1,
          spaceBetween: 0,
          paginationClickable: true,
        });
      }, 10);
    },
  },
  computed: {
    sidebarStatus() {
      return this.$store.getters.sidebarStatus;
    },

    shouldStick() {
      return this.width > 991;
    },

    width() {
      return this.$store.getters.deviceWidth;
    },

    countOnline() {
      return this.usersOnline.length;
    },
    ...mapGetters(['counters', 'user', 'sex']),
    isAuthorized() {
      return !!window.localStorage['access-token'];
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/vars';
</style>
