<template>
  <span class="zodiac-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.997"
      height="31.997"
      viewBox="0 0 31.997 31.997"
    >
      <path d="M31.455 21.75l-4.507-3.23c-.4-.285-.92-.325-1.354-.103-.435.223-.707.67-.707 1.16v1.983c-.863-.207-2.01-.65-2.01-1.333V8.197c0-2.903-3.006-4.25-4.902-4.25-1.27 0-2.454.478-3.333 1.35l-.005.004c-.93-.888-2.167-1.354-3.367-1.354-1.27 0-2.452.48-3.33 1.35-.007.006-.013.016-.02.022-.898-.75-2.02-1.156-3.12-1.156-1.27 0-2.453.48-3.33 1.35C.8 6.178 0 7.383 0 9.343c0 .896.724 1.618 1.618 1.618s1.617-.724 1.617-1.617c0-1.806 1.198-1.945 1.565-1.945.77 0 1.67.51 1.67 1.945v13.685c0 .895.722 1.617 1.616 1.617s1.617-.725 1.617-1.617v-13.9c0-1.806 1.2-1.946 1.566-1.946 2.19 0 1.902 2.105 1.902 2.734v13.112c0 .895.724 1.617 1.617 1.617.894 0 1.617-.725 1.617-1.617v-13.9c0-1.806 1.196-1.946 1.565-1.946.77 0 1.668.51 1.668 1.946v11.104c0 3.082 3.14 4.32 5.242 4.622v1.893c0 .512.3.975.766 1.188.174.078.354.117.537.117.31 0 .615-.11.857-.32l4.507-3.938c.3-.262.465-.646.444-1.045-.015-.404-.215-.77-.54-1z" />
    </svg>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style>
</style>
