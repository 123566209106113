<template>
  <div class="virtual-gifts">
    <div class="virtual-gifts__heading">
      <h2 class="virtual-gifts__header">
        {{ categoryName }} <span>({{ gifts.length }})</span>
      </h2>
      <div v-if="opt.navigation" class="virtual-gifts__nav">
        <button
          class="nav-btn prev-btn"
          :class="[opt.navigation.prevEl.replace('.', ''), { hidden: gifts.length < 5 }]"
        >
          <span class="rbi rbi-left-arrow" />
        </button>
        <button
          class="nav-btn next-btn"
          :class="[opt.navigation.nextEl.replace('.', ''), { hidden: gifts.length < 5 }]"
        >
          <span class="rbi rbi-right-arrow" />
        </button>
      </div>
    </div>
    <div class="virtual-gifts__body">
      <div v-if="opt.navigation" class="swiper-container virtual-gifts__slider">
        <swiper :options="opt">
          <swiper-slide v-for="(gift, index) in gifts" :key="`gift-${index}${gift.id}`">
            <div class="img-wrapper" @click="selectGift(gift)">
              <img :src="gift.photo" :alt="gift.name" />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  swiper: null,
  name: 'VirtualGiftsSlider',
  props: {
    categoryName: {
      type: String,
    },
    gifts: {
      type: Array,
    },
    update: {
      type: Boolean,
    },
  },
  data() {
    return {
      opt: {
        slidesPerView: 5,
        spaceBetween: 10,
        breakpoints: {
          992: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 3,
          },
          500: {
            slidesPerView: 1,
          },
        },
      },
      viewSlide: {
        desktop: 5,
        tablet: 5,
        mobile768: 3,
        mobile: 1,
      },
    };
  },
  computed: {
    width() {
      return this.$store.getters.deviceWidth;
    },
  },
  watch: {
    update(v) {
      if (v) {
        setTimeout(() => {
          this.swiper.update();
        }, 350);
      }
    },
  },
  created() {
    Object.assign(this.opt, {
      navigation: {
        prevEl: `.gifts-prev-btn-${this._uid}`,
        nextEl: `.gifts-next-btn-${this._uid}`,
      },
    });
  },

  methods: {
    selectGift(gift) {
      this.$emit('selected', gift);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/vars';
.virtual-gifts {
  &__heading {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey-two;
    padding-bottom: 15px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 15%;
      max-width: 30px;
      height: 3px;
      bottom: -3px;
      left: 0;
      background: $red;
    }
  }
  &__header {
    font-size: 16px;
    color: $black;
    span {
      color: #a4a4a4;
    }
  }
  &__nav {
    display: flex;
    .nav-btn {
      background: none;
      border: none;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:last-child {
        margin-left: 15px;
      }
      &.hidden {
        display: none;
      }
    }
  }
  &__slider {
    height: 210px;
  }
}

.img-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px;
}

.img-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
</style>
