<template>
  <span class="zodiac-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32.001"
      viewBox="0 0 32 32.001"
    >
      <path d="M21.653 23.9V8.267c2.225-.496 3.968-1.356 5.188-2.59 1.69-1.712 1.758-3.477 1.754-3.813C28.58.856 27.78.086 26.774.05 25.755.022 24.91.828 24.83 1.83c-.085 1.1-1.918 3.172-8.234 3.172-6.448 0-8.56-2.188-8.778-3.354C7.69.645 6.796-.106 5.774.013 4.74.11 3.98 1.027 4.076 2.063c.02.21.482 4.043 6.01 5.814v16.055c-6.152 1.666-6.647 5.79-6.668 6.01-.095 1.004.625 1.863 1.625 1.998.997.135 1.936-.58 2.114-1.572.208-1.165 2.313-3.365 8.78-3.365 6.316 0 8.15 2.073 8.234 3.097-.012 1.038.82 1.892 1.86 1.902h.02c1.03 0 1.87-.83 1.883-1.86.003-.335-.062-2.1-1.753-3.81-1.094-1.11-2.618-1.917-4.527-2.43zm-7.806-.59V8.62c.85.086 1.744.146 2.747.146.446 0 .87-.02 1.294-.037v14.576c-.626-.043-1.272-.07-1.952-.07-.74 0-1.435.026-2.09.075z" />
    </svg>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style>
</style>
