<template>
  <transition name="modal">
    <div v-show="modalOpened" class="vue-modal modal-mask thanks-for-purchase-modal">
      <div class="vue-modal--wrapper">
        <div v-if="success" class="vue-modal--container mail-modal--body">
          <div class="modal-header">
            <h4 class="modal-title">Successful Transaction</h4>
          </div>
          <div class="modal-body">
            <div class="purchase-modal-content">
              Your transaction was successful, thanks for the purchase.
            </div>
            <div class="purchase-modal-bottom">
              <div>
                <button class="btn btn-outline" @click="closeModal()">Ok</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="vue-modal--container mail-modal--body">
          <div class="modal-header">
            <h4 class="modal-title">Transaction Failed</h4>
          </div>
          <div class="modal-body">
            <div class="purchase-modal-content">
              Unfortunately, your transaction didnt go through.
            </div>
            <div class="purchase-modal-bottom">
              <div>
                <button class="btn btn-outline" @click="closeModal()">Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PurchaseProcessingModal',
  data: () => ({
    modalOpened: false,
    success: false,
  }),

  mounted() {
    if (this.$route.path.match('/thanks-for-purchase')) {
      this.success = true;
      this.modalOpened = true;
    }
    if (this.$route.path.match('/failed-transaction')) {
      this.success = false;
      this.modalOpened = true;
    }
  },

  methods: {
    closeModal() {
      this.$router.push('/');
      this.modalOpened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.thanks-for-purchase-modal {
  .redbini-red-text {
    color: #e21919;
  }

  .modal-title {
    font-size: 1.6em;
  }

  .purchase-modal-content {
    padding: 1em 0;
    font-size: 1.1em;
  }

  .purchase-modal-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px;
    margin-top: 10px;
    background: #ffffff;

    text-align: center;
    .tip {
      font-weight: lighter;
      font-size: 1.1em;
    }
  }
}
</style>
