<template>
  <div v-if="open" class="vue-gallery" :class="{ 'edit-mode': edit.open }">
    <div class="vue-gallery__wrap">
      <div class="vue-gallery__body">
        <div class="itemGal vue-gallery__image">
          <div class="itemGal image-wrap">
            <img
              v-show="!showPreloader && !uploading"
              ref="image-inside"
              class="gallery-image-inside"
              :src="displayedPhoto"
              @load="showPreloader = false"
            />
            <div v-show="showPreloader || uploading" class="loader" />
          </div>
          <button class="itemGal controls controls--prev" @click="changeIndex(false)">
            <span class="rbi rbi-left-arrow" />
          </button>
          <button class="itemGal controls controls--next" @click="changeIndex(true)">
            <span class="rbi rbi-right-arrow" />
          </button>
          <button class="close-gallery" @click="close">
            <span class="rbi rbi-close" />
          </button>
          <div class="vue-gallery__header">
            <div class="set-flex set-flex-y-center vue-gallery__nav">
              <span class="rbi rbi-camera" />
              <span class="count">{{ gallery.index + 1 }}/{{ gallery.photos.length }}</span>
            </div>
            <div class="set-flex set-flex-y-center set-flex-x-center vue-gallery__actions">
              <button
                v-if="displaySetAvatarBtn"
                @click="setAvatar(gallery.photos[gallery.index].photo_id)"
              >
                <span>
                  <span class="rbi rbi-male" />
                  <span class="hidden-xs">
                    {{ $t('gallery.setAva') }}
                  </span>
                </span>
              </button>
              <div v-if="displayEditBox" class="vue-gallery__edit">
                <button
                  v-show="!edit.open"
                  type="button"
                  class="vue-gallery__edit--btn"
                  @click="startEdit"
                >
                  <span>
                    <span class="rbi rbi-edit" />
                    <span class="hidden-xs">{{ $t('shared.common.edit') }}</span>
                  </span>
                </button>
                <div
                  v-if="edit.open"
                  class="vue-gallery__edit--controls set-flex set-flex-y-center"
                >
                  <template v-if="edit.preloader">
                    <div>
                      <p>{{ $t('gallery.preloader') }}</p>
                    </div>
                  </template>
                  <template v-else>
                    <p v-if="displayEditControls">{{ $t('gallery.rotate') }}:</p>
                    <ul class="list-unstyled set-flex set-flex-y-center">
                      <li v-if="displayEditControls" class="left" @click="rotateCanvas(1)">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="481.95"
                            height="481.95"
                            viewBox="0 0 481.95 481.95"
                          >
                            <path
                              d="M331.5 114.75L216.75 0v79.05C117.3 91.8 38.25 175.95 38.25 280.5s79.05 188.7 178.5 201.45v-51C145.35 418.2 89.25 357 89.25 280.5s56.1-137.7 127.5-150.45v99.45L331.5 114.75zM443.7 255c-5.1-35.7-17.85-68.85-40.8-99.45l-35.7 35.7c12.75 20.4 22.95 40.8 25.5 63.75h51zM267.75 430.95v51c35.7-5.1 68.85-17.85 99.45-40.8l-35.7-35.7c-20.4 12.75-40.8 22.95-63.75 25.5zm99.45-61.2l35.7 35.7c22.95-30.6 38.25-63.75 40.8-99.45h-51c-2.55 22.95-12.75 43.35-25.5 63.75z"
                            />
                          </svg>
                        </span>
                      </li>
                      <li v-if="displayEditControls" class="right" @click="rotateCanvas(-1)">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="481.95"
                            height="481.95"
                            viewBox="0 0 481.95 481.95"
                          >
                            <path
                              d="M114.75 191.25l-35.7-35.7C56.1 186.15 40.8 219.3 38.25 255h51c2.55-22.95 12.75-43.35 25.5-63.75zM89.25 306h-51c5.1 35.7 17.85 68.85 40.8 99.45l35.7-35.7C102 349.35 91.8 328.95 89.25 306zm25.5 135.15c30.6 22.95 63.75 35.7 99.45 40.8v-51c-22.95-2.55-43.35-12.75-63.75-25.5l-35.7 35.7zM265.2 79.05V0L150.45 114.75 265.2 229.5v-99.45C336.6 142.8 392.7 204 392.7 280.5s-56.1 137.7-127.5 150.45v51c99.45-12.75 178.5-99.45 178.5-201.45S364.65 91.8 265.2 79.05z"
                            />
                          </svg>
                        </span>
                      </li>
                      <li v-if="displayEditControls" class="accept" @click="confirmEditing">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="448.8"
                            height="448.8"
                            viewBox="0 0 448.8 448.8"
                          >
                            <path
                              d="M142.8 323.85L35.7 216.75 0 252.45l142.8 142.8 306-306-35.7-35.7"
                            />
                          </svg>
                        </span>
                      </li>
                      <li v-if="displayEditControls" class="cancel" @click="clearEditing(false)">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="357"
                            height="357"
                            viewBox="0 0 357 357"
                          >
                            <path
                              d="M357 35.7L321.3 0 178.5 142.8 35.7 0 0 35.7l142.8 142.8L0 321.3 35.7 357l142.8-142.8L321.3 357l35.7-35.7-142.8-142.8"
                            />
                          </svg>
                        </span>
                      </li>
                    </ul>
                  </template>
                </div>
              </div>
              <button
                v-if="displayDeletePhotoBtn"
                @click="deletePhoto(gallery.photos[gallery.index].photo_id)"
              >
                <span>
                  <span class="rbi rbi-trash" />
                  <span class="hidden-xs">
                    {{ $t('gallery.del') }}
                  </span>
                </span>
              </button>
            </div>

            <div class="dummy" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WATERMARK, PHOTOS_UPLOAD } from '../../mixins/utils';

export default {
  components: {},
  mixins: [WATERMARK, PHOTOS_UPLOAD],
  data() {
    return {
      watcher: false,
      showPreloader: true,
      storedPhoto: {},
      edit: {
        open: false,
        preloader: false,
        tmpImg: null,
        img: null,
        counter: 0,
      },
      uploading: false,
      canvas: {},
    };
  },
  computed: {
    /**
     * Проверяет, нужно ли показывать контейнер с редактированием фотографии
     */
    displayEditBox() {
      if (!this.currentPhotoObj.album_type) return false;
      if (this.sex.isMan) {
        return this.currentPhotoObj.album_type !== 'purchased';
      }
      if (this.sex.isWoman) {
        return false;
      }

      return false;
    },

    /**
     * Проверяет, нужно ли показывать кнопки поворота фотографии
     *
     * @return {boolean}
     */
    displayEditControls() {
      if (!this.currentPhotoObj.album_type) return false;
      return this.sex.isMan;
    },

    /**
     * Проверяет, нужно ли показывать кнопку с установкой фото как аватарки
     *
     * @return {boolean}
     */
    displaySetAvatarBtn() {
      if (!this.currentPhotoObj.album_type) return false;
      if (!this.edit.open) {
        if (this.currentPhotoObj.album_type === 'public' && !this.currentPhotoObj.disableActions) {
          return this.sex.isMan;
        }
        if (this.currentPhotoObj.album_type === 'private' && this.sex.isMan) {
          return this.sex.isMan;
        }
        return false;
      } else {
        return false;
      }
    },

    /**
     * Проверяет, нужно ли показывать кнопку с удалением фотографии
     *
     * @return {boolean}
     */
    displayDeletePhotoBtn() {
      if (!this.currentPhotoObj.album_type) return false;
      if (!this.currentPhotoObj.disableActions && !this.edit.open) {
        if (
          this.sex.isMan &&
          (this.currentPhotoObj.album_type === 'private' ||
            this.currentPhotoObj.album_type === 'public')
        ) {
          return true;
        }
        if (this.sex.isWoman) {
          return this.currentPhotoObj.album_type === 'private';
        }
        return false;
      }
      return false;
    },

    /**
     * Возвращает текущий URL фотографии
     *
     * @return {string}
     */
    currentPhotoURL() {
      return this.currentPhotoObj.img_origin;
    },

    /**
     * Возвращает текущий объект фотографии
     *
     * @return {object}
     */
    currentPhotoObj() {
      if (this.gallery.photos.length) {
        return this.gallery.photos[this.gallery.index];
      }
      return {};
    },

    /**
     * Возвращает объект с след. фото
     *
     * @return {object}
     */
    nextPhoto() {
      if (this.gallery.index === this.gallery.photos.length - 1) {
        return this.gallery.photos[0];
      }
      return this.gallery.photos[this.gallery.index + 1];
    },

    /**
     * Возвращает объект с пред. фото
     *
     * @return {object}
     */
    prevPhoto() {
      if (this.gallery.index === 0) {
        return this.gallery.photos[this.gallery.photos.length - 1];
      }
      return this.gallery.photos[this.gallery.index - 1];
    },
    gallery() {
      return this.$store.getters.gallery;
    },
    open() {
      return this.gallery.open;
    },

    /**
     * Возвращает, нужно ли показывать ватермарку
     *
     * @return {boolean}
     */
    requireWatermark() {
      return !this.gallery.hideWatermark || !this.currentPhotoObj.hideWatermark;
    },

    /**
     * Возвращает показываемую фотографию
     * @return {object | string}
     */
    displayedPhoto() {
      if (this.edit.open && this.edit.img) {
        return this.edit.img;
      }
      return this.gallery.hideWatermark || this.currentPhotoObj.hideWatermark
        ? this.currentPhotoURL
        : this.storedPhoto.watermark;
    },
    sex() {
      return this.$store.getters.sex;
    },
  },
  watch: {
    /**
     * Получить URL просматриваемой фотографии
     *
     * @param v {string}
     */
    currentPhotoURL(v) {
      if (v && !this.gallery.hideWatermark) this.setWatermark(v);
      this.clearEditing(false);
    },

    /**
     * Очистить режим редактирования при закрытии галереи
     *
     * @param v {boolean}
     */
    open(v) {
      if (!v) this.clearEditing(false);
    },

    /**
     * Прятать прелоадер при смене фотографии. Нужен для случая, когда фото достается из кэша
     */
    watcher() {
      const img = new Image();
      img.src = this.displayedPhoto;

      if (img.complete) {
        this.showPreloader = false;
      }
    },
  },
  methods: {
    /**
     * Вход в режим редактирования
     */
    startEdit() {
      this.edit.open = !this.edit.open;
      if (this.edit.open) {
        this.prepareCanvas();
      } else {
        this.clearEditing(false);
      }
    },

    /**
     * Продолжить / просто очистить режим редактирования
     *
     * @param photo {boolean|objtect} - условие для дальнейшей обработки
     */
    clearEditing(photo) {
      if (photo) {
        this.storedPhoto = {};
        const img = document.createElement('img');
        img.crossOrigin = 'anonymous';
        const reader = new FileReader();
        reader.onloadend = (e) => {
          img.src = e.target.result;
          this.setWatermark(img.src, true);
        };
        reader.readAsDataURL(photo.photo);
        //					img.src = URL.createObjectURL(photo.photo);
      }

      //				this.drpOpen = false;
      this.edit.open = false;
      this.canvas = {};
      this.edit.img = null;
      this.edit.tmpImg = null;
    },

    /**
     *  Подготовить канвас с изображением, которое будем поворачивать
     */
    prepareCanvas() {
      this.edit.preloader = true;
      const img = document.createElement('img');
      img.crossOrigin = 'anonymous';

      const src = `${this.currentPhotoURL}?${Date.now()}`;
      img.src = src;
      img.onload = (e) => {
        if (e.target.src === src) this.createCanvas(e);
      };
    },

    /**
     * Создать канвас изображение, которое будем поворачивать
     *
     * @param e {object} - event
     */
    createCanvas(e) {
      const canvas = document.createElement('canvas');
      const img = e.target;
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;

      this.edit.tmpImg = img;
      this.canvas = canvas;
      this.edit.preloader = false;
    },

    /**
     * Обработать параметры канваса
     *
     * @param w {number} - ширина канваса
     * @param h {number} - высота канваса
     * @param angle {number} - угол поворота
     * @param ctx {object} - контексное представление кнваса
     */
    processingCanvas(w, h, angle, ctx) {
      this.canvas.width = w;
      this.canvas.height = h;
      ctx.rotate((angle * Math.PI) / 180);
    },

    /**
     * Поворот канваса
     *
     * @param i {number}  1 = по часовой стрелке, -1 = против
     */
    rotateCanvas(i) {
      const h = this.canvas.height;
      const w = this.canvas.width;
      const ctx = this.canvas.getContext('2d');

      this.edit.counter += i;
      if (this.edit.counter > 4 || this.edit.counter < -4) this.edit.counter = 1;

      const angle = this.edit.counter * 90;

      switch (angle) {
        default:
        case 0:
          this.processingCanvas(w, h, angle, ctx);
          ctx.drawImage(this.edit.tmpImg, 0, 0);
          break;
        case 90:
        case -270:
          this.processingCanvas(h, w, angle, ctx);
          ctx.drawImage(this.edit.tmpImg, 0, -h);
          break;
        case 180:
        case -180:
          this.processingCanvas(w, h, angle, ctx);
          ctx.drawImage(this.edit.tmpImg, -w, -h);
          break;
        case 270:
        case -90:
          this.processingCanvas(h, w, angle, ctx);
          ctx.drawImage(this.edit.tmpImg, -w, 0);
          break;
      }

      //				this.edit.img = this.canvas.toDataURL( 'image/jpeg', 1 );
      this.edit.img = this.fetchSize(1).base;

      this.canvas.width = w;
      this.canvas.height = h;
    },

    /**
     * Подогнать размер и вес канваса переред сохранением в Blob. Подгоняет рекурсивно.
     *
     * @param quality {number} - качество, с которым нужно собирать Blob
     *
     * @return {{base: base64, blob: Blob} || рекурсивный вызов}
     */
    fetchSize(quality) {
      const base = this.canvas.toDataURL('image/jpeg', quality);
      const blob = this.dataURItoBlob(base);
      return blob.size <= this.constants.imageSize
        ? {
            base,
            blob,
          }
        : this.fetchSize(quality - this.constants.qualityStep);
    },

    /**
     * Подтвердить поворот фотографии
     */
    confirmEditing() {
      if (!this.edit.img) {
        this.clearEditing(false);
        return;
      }

      this.$store.commit('addAlert', {
        type: 'success',
        text: this.$t('gallery.startUpdate'),
      });

      this.uploading = true;

      const blob = this.dataURItoBlob(this.edit.img);

      const formData = new FormData();
      formData.append('access_token', window.localStorage['access-token']);
      formData.append('blob', blob);
      formData.append('photo_id', this.currentPhotoObj.photo_id);
      formData.append('is_blob', 1);
      this.$http.post('v1/photo/upload-update', formData).then(
        (r) => {
          if (r.body.status) {
            this.$store.commit('addAlert', {
              type: 'success',
              text: this.$t('gallery.updated'),
            });
            this.$store.commit('updateGalleryState');
          } else {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('gallery.updateFailed'),
              info: 'saving updated photo',
            });
          }
          this.uploading = false;
        },
        () => {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.requestError'),
            info: 'saving updated photo',
          });
          this.uploading = false;
        }
      );

      this.clearEditing({
        photo: blob,
      });
    },

    /**
     * Установить ватермарку на изображение
     *
     * @param v {string|blob} - ссылка с изображением или Blob
     * @param isBlob {boolean} - сообщает, Blob ли формат передаваемого изображение
     */
    setWatermark(v, isBlob) {
      if (this.requireWatermark) {
        this.showPreloader = true;
        this.getWatermark({
          targetObj: this.storedPhoto,
          parentImg: v,
          wmImg: this.config.watermarkLarge,
          modifierX: 10,
          modifierY: 10,
          variable: 'watermark',
          isBlob,
        }).then(() => {
          this.showPreloader = false;
        });
      }
    },

    /**
     * Закрыть галерею
     */
    close() {
      this.showPreloader = true;
      this.storedPhoto = {};
      this.$store.commit('updateGallery', {
        open: false,
        index: 0,
        hideWatermark: false,
        photos: [],
      });
      this.clearEditing(false);
    },

    /**
     * Переключить фотографию на следующую или предыдущую
     *
     * @param inc {boolean} true = increment, false = decrement
     */
    changeIndex(inc) {
      let tmp = this.gallery.index;
      if (tmp === 0) {
        inc ? tmp : (tmp = this.gallery.photos.length);
      }
      inc ? tmp++ : tmp--;
      if (this.gallery.photos.length === tmp) {
        tmp = 0;
      }
      this.showPreloader = true;
      this.$store.commit('updateGallery', {
        index: tmp,
      });
      this.watcher = !this.watcher;
    },

    /**
     * Кэширование фотографий
     *
     * @param photo {object}
     * */
    cachePhoto(photo) {
      const tmp = new Image();
      tmp.src = photo.img_origin;
    },

    /**
     * Установить фото на аватар
     *
     * @param val {number} - ИД фотографии
     */
    setAvatar(val) {
      if (!this.currentPhotoObj.album_type) return false;
      if (this.displaySetAvatarBtn && !this.edit.open) {
        if (this.currentPhotoObj.album_type === 'private' && this.sex.isMan) {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.canSetAvaFromPublic'),
          });
        } else {
          this.$http
            .post('v1/photo/avatar-set', {
              access_token: window.localStorage['access-token'],
              photo_id: val,
            })
            .then(
              (r) => {
                if (r.body.status) {
                  this.$store.commit('addAlert', {
                    type: 'success',
                    text: this.$t('alerts.avatarSet'),
                  });
                } else {
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: this.$t('alerts.someErr'),
                  });
                }
              },
              () => {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.someErr'),
                });
              }
            );
        }
      }
    },
    /**
     * Удалить фотографию
     *
     * @param val {id} ИД фотографии
     */
    deletePhoto(val) {
      let conf = false;

      this.$confirm(this.$t('gallery.delConfirm'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      })
        .then(() => {
          conf = true;
          if (this.displayDeletePhotoBtn && conf) {
            this.$http
              .post('v1/photo/delete', {
                access_token: window.localStorage['access-token'],
                photo_id: val,
              })
              .then(
                (r) => {
                  if (r.body.status) {
                    this.$store.commit('addAlert', {
                      type: 'success',
                      text: this.$t('alerts.deletePhoto'),
                    });
                  } else {
                    this.$store.commit('addAlert', {
                      type: 'error',
                      text: this.$t('alerts.someErr'),
                    });
                  }
                },
                () => {
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: this.$t('alerts.someErr'),
                  });
                }
              );
            this.gallery.photos.splice(this.gallery.index, 1);
            if (this.gallery.photos.length - this.gallery.index === 0) {
              this.gallery.index--;
            }

            if (this.gallery.photos.length === 0) {
              this.close();
            }
            this.clearEditing(false);
          }
        })
        .catch(() => {
          conf = false;
        });
    },
  },
  /* beforeUpdate() {
         if ( this.gallery.photos.length ) {
         this.cachePhoto( this.nextPhoto );
         this.cachePhoto( this.prevPhoto );
         }
         } */
};
</script>
