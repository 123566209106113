<template>
  <aside
    class="sidebar_left"
    sticky-container
  >
    <div
      v-sticky="shouldStick"
      class="banner hidden-md hidden-xs hidden-sm"
      sticky-offset="offset"
      sticky-side="top"
    >
      <div class="swiper-container banner-slider">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            style="cursor: pointer;"
            @click="openRegistrationModal"
          >
            <img
              src="/static/images/guest_banner.png"
              alt="banner"
            >
          </div>
        </div>
        <div class="swiper-pagination banner-pagination" />
      </div>
    </div>
  </aside>
</template>

<script>
import { REGISTRATION_MODAL } from '../../mixins/formsActions';

export default {
  components: {},
  mixins: [REGISTRATION_MODAL],
  data() {
    return {
      offset: { top: 15, bottom: 10 },
    };
  },
  computed: {
    shouldStick() {
      return this.width > 991;
    },
    width() {
      return this.$store.getters.deviceWidth;
    },
  },
  methods: {},
};
</script>

<style>
</style>
