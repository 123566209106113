<template>
  <transition v-if="modal.open" name="modal">
    <div class="vue-modal user-preview-modal modal-mask">
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container modal-dialog">
<!--          <div v-click-away="closeModal" class="vue-modal&#45;&#45;container modal-dialog">-->
          <div class="auth">
            <div class="preview-body">
              <div class="two-column">
                <div class="second">
                  <div class="title-wrap">
                    <p class="user-preview-modal__title">
                      EMAIL VERIFICATION
                    </p>
<!--                    <button type="button" class="close" @click="closeModal">-->
<!--                      <span class="rbi-close" />-->
<!--                    </button>-->
                  </div>
                  <template v-if="linkEnter">
                      <div class="form-group d-flex flex-wrap">
                        <rb-button class="btn-outline" :class="{ bdn: enteringReg }">
                          <span v-if="enteringReg" :class="{ 'wait-spin': enteringReg }" />
                          <span :class="{ wait: enteringReg }">VERIFY EMAIL</span>
                        </rb-button>
                      </div>
                  </template>
                  <template v-else>
                  <div class="subtitle">
                    To access our website you need to verify your email address.
                  </div>
                  <div class="form-code__spam">
                    Please mark the letter as "not spam" if you see this in spam folder 🙏
                  </div>
<!--                  <form class="user-preview-modal__form" @submit.prevent="submitRegistration">-->
                  <form class="user-preview-modal__form" @submit.prevent="checkCode">
                      <div class='form-code'>
                        To verify your email
                        <span class="form-code__email">{{ email }}</span>
                      </div>
<!--                      <div class="form-group">-->
<!--                        <input-->
<!--                          v-model="code"-->
<!--                          class="form-control"-->
<!--                        />-->
<!--                      </div>-->
                      <div class="form-code__light">
                        please click the button "verify" in your inbox
                      </div>
                      <div class="form-code__return">
                        <a href="https://redbini.com/">I dont have access to the email address I used</a>
                      </div>
<!--                      <div class="form-group d-flex flex-wrap">-->
<!--                        <rb-button type="submit" class="btn-outline" :class="{ bdn: enteringReg }">-->
<!--                          <span v-if="enteringReg" :class="{ 'wait-spin': enteringReg }" />-->
<!--                          <span :class="{ wait: enteringReg }">VERIFY EMAIL</span>-->
<!--                        </rb-button>-->
<!--                      </div>-->
                  </form>
                  </template>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import RbButton from '../../Button';
import CONFIG from '../../../config/config';
import Vue from 'vue';

export default {
  name: 'VerificationModal',
  components: {
    RbButton,
  },
  data() {
    return {
      enteringReg: false,
      linkEnter: false,
      code: '',
      email: '',
      answer: false,
      interval: null,
      modalOpen: false,
    };
  },

  computed: {
    modal() {
      return this.$store.getters.verificationModal;
    },
    modalIsOpened() {
      return this.modal.open;
    },
    openStatus() {
      return this.$store.getters.verificationModalStatus;
    },
  },
  mounted() {
    console.log('this.openStatus', this.openStatus);
    Vue.http
      .post('v1/profile/profile', {
        access_token: window.localStorage['access-token'],
      })
      .then(
        (response) => {
          this.email = response.body.result.email
        })

    if (this.$route.path.match('/verification')) {
      if (this.$route.query.email === undefined || this.$route.query.code === undefined) {
        return
      }
      this.$store.commit('updateVerificationModal', {
        open: true,
      });
      this.email = this.$route.query.email
      this.code = this.$route.query.code
      this.linkEnter = true
      this.enteringReg = true

      if (window.localStorage['access-token'] !== undefined) {

        this.$http
          .post('v1/user/verify', {
            // access_token: window.localStorage['access-token'],
            verification_code: this.code,
            email: this.email,
          })
          .then(
            (r) => {
              setTimeout(() => {
                if (r.body.status) {
                  setTimeout(() => {
                    this.linkEnter = false
                    this.enteringReg = false
                    this.$store.commit('updateVerificationModal', {
                      open: false,
                    });
                    setTimeout(() => {
                      this.$store.commit('updateQuestionnaireModal', {
                        open: true,
                      });
                    }, 2000);
                  }, 2000);

                } else {
                  setTimeout(() => {
                    this.linkEnter = false
                    this.enteringReg = false
                    this.$store.commit('updateVerificationModal', {
                      open: true,
                    });
                    this.$store.commit('addAlert', {
                      type: 'error',
                      text: this.$t('alerts.verificationError'),
                    });
                    this.code = ''
                    // window.location.href = '/';
                  }, 3000);
                }
              }, 2000);
            })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.$http
          .post('v1/user/verify', {
            // access_token: window.localStorage['access-token'],
            verification_code: this.code,
            email: this.email,
          })
          .then(
            (r) => {
              // setTimeout(() => {
              if (r.body.status) {
                this.$store.commit('updateVerificationModal', {
                  open: false,
                });
                this.auth(this, r.body, false)
                setTimeout(() => {
                  this.linkEnter = false
                  this.enteringReg = false

                  setTimeout(() => {
                    this.$store.commit('updateQuestionnaireModal', {
                      open: true,
                    });
                  }, 2000);
                }, 2000);

              } else {
                setTimeout(() => {
                  this.linkEnter = false
                  this.enteringReg = false
                  this.$store.commit('updateVerificationModal', {
                    open: true,
                  });
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: this.$t('alerts.verificationError'),
                  });
                  this.code = ''
                  // window.location.href = '/';
                }, 3000);
              }
              // }, 2000);
            })
          .catch((err) => {
            console.error(err);
          });

      }

    }
    // setTimeout(() => {
    //   console.log('this.openStatus', this.openStatus);
    // }, 8000)

      setTimeout(() => {
        if (this.openStatus === true) {
          this.intervalFunction()
        }
      }, 8000)

  },
    methods: {

    intervalFunction() {
      this.interval = setInterval(() => {

        this.closeVerifyModal()
        if (this.answer === true) {
          clearInterval(this.interval)
        }
      },2000)
    },
      closeVerifyModal() {
        this.$http
          .post('v1/user-additional-info/is-verified', {
            access_token: window.localStorage['access-token'],
          })
          .then(
            (response) => {
              response.json().then(
                (response) => {
                  if (response.status === true) {
                    this.answer = true
                    this.$store.commit('updateVerificationModal', {
                      open: false,
                    });
                  }
                }
              )
            })
      },
    auth(scope, response, keepCaptcha) {
        const rgxp = new RegExp(CONFIG.baseURL, 'g');

        if (CONFIG.production && !window.location.href.match(rgxp)) {

          window.location = `${CONFIG.baseURL}/${response.result.user_id}?token=${response.result.access_token}`;
        } else {
          scope.$store.commit('SET_TOKEN', response.result.access_token);
          window.localStorage.setItem('access-token', response.result.access_token);

          scope.$store.dispatch('getUserData', { targetId: response.result.user_id });
          response.result.user_type === 'man'
            ? scope.$router.push({ name: 'feed' })
            : scope.$router.push({
              name: 'profile',
              params: { id: response.result.user_id },
            });
        }

        if (window.localStorage.ref) window.localStorage.removeItem('ref');
        if (!keepCaptcha) window.localStorage.removeItem('login');
      },
    closeModal() {
      // this.err.clear();
      this.$store.commit('updateVerificationModal', {
        open: false,
      });
    },
    checkCode() {
      if (this.code.length == 6) {
        this.$http
          .post('v1/user/verify', {
            access_token: window.localStorage['access-token'],
            verification_code: this.code,
          })
          .then(
            (r) => {
              if (r.body.status) {
                this.closeModal()
                setTimeout(() => {
                  this.$store.commit('updateQuestionnaireModal', {
                    open: true,
                  });
                }, 3000);
              } else {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.verificationError'),
                });

              }
            })
    //         () => {
    //           this.$store.commit('addAlert', {
    //             type: 'error',
    //             text: this.$t('alerts.someErr'),
    //           });
    //         }
    //       );
    //   }
    // })
// .catch((err) => {
//   console.error(err);
}
    },
    /**
     * Отправить запрос на регистрацию пользователя с предварительной валидацией
     */
    // submitRegistration() {
    //   const self = this;
    //   this.enteringReg = true;
    //   const rules = this.rules ? 1 : '';
    //   this.regValidator
    //     .validateAll({
    //       modRegName: this.name,
    //       modRegEmail: this.email,
    //       modRegPassword: this.password,
    //       modRegDay: this.day,
    //       modRegMonth: this.month,
    //       modRegYear: this.year,
    //       modRegTerms: rules,
    //     })
    //     .then((success) => {
    //       if (success) {
    //         this.mixinRegistration({
    //           sex: this.sex,
    //           dob: `${this.day < 10 ? `0${this.day}` : this.day}.${
    //             this.monthToNum(this.month) < 10
    //               ? `0${this.monthToNum(this.month)}`
    //               : this.monthToNum(this.month)
    //           }.${this.year}`,
    //           name: this.name,
    //           email: this.email,
    //           password: this.password,
    //         })
    //           .then(() => this.closeModal())
    //           .catch((err) => {
    //             console.log(err);
    //             self.$store.commit('addAlert', {
    //               type: 'error',
    //               text: err || 'Some error happened.',
    //             });
    //             this.enteringReg = false;
    //           });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       /* self.$store.commit('addAlert', {
    //         type: 'error',
    //         text: err ? err : 'Some error happened.',
    //       }) */
    //       this.enteringReg = false;
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/vars';
.user-preview-modal {
  &__title {
    &::first-letter {
      color: $red;
    }
  }
}
.user-preview-modal .modal-dialog {
  position: relative;
  top: 15%;
}
.user-preview-modal .auth .preview-body .btn {
  margin: 0 auto;
}
input {
  font-size:22px;
  letter-spacing: 20px;
  font-weight: 500;
  text-align: center;
}
.form-group.d-flex {
  justify-content: flex-end;
}
.user-preview-modal .title-wrap {
  justify-content: center;
}
.form-code__return {
  margin-bottom: 30px;
}
.user-preview-modal .auth .preview-body .d-flex {
  margin-top: 0;
}
.form-code {
  font-weight: 700;
}
.form-code__light {
  opacity: .7;
  margin-bottom: 25px;
}
.form-code__spam {
  font-weight: 700;
  margin-bottom: 25px;
}
.subtitle {
  font-size: 16px;
  margin-bottom: 10px;
}
.user-preview-modal__title {
  //justify-content: center;
  margin-bottom: 35px;
}
.form-group .form-control {
  border: 1px solid #d9d9d9;
}
.wait-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  width: 20px;
  height: 20px;
  border: 2px solid $black;
  border-left-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.user-preview-modal .first {
  background: #f6f6f6;
  padding: 5px;
  overflow: hidden;
}

.zoom-image-preview {
  transform: scale(1.35);
}

.another-variants {
  font-size: $basic-font-size;
  font-weight: 300;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  text-align: center;
  padding: 18px 0 15px;
  margin: 0;
  display: none;
}
.user-preview-modal .modal-dialog {
  max-width: 565px;
  width: 100%;
  padding: 0;
}
//@media screen and (max-width: 1720px) {
//  .user-preview-modal .modal-dialog {
//    max-width: 500px;
//    width: 100%;
//    padding: 0;
//  }
//}
@media screen and (max-width: 890px) {
  .zoom-image-preview {
    transform: scale(1.5);
  }

  .user-preview-modal .second {
    padding: 15px 25px;
  }

  .user-preview-modal .auth .preview-body .btn {
    padding-left: 15px;
    padding-right: 15px;
  }



  .user-preview-modal .form-group {
    margin-bottom: 5px;
  }
  .user-preview-modal .custom-checkbox {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 1440px) {
  .user-preview-modal .modal-dialog {
    max-width: 500px;
  }
  .user-preview-modal__title {
    font-size: 20px;
  }
  .subtitle {
    font-size: 14px;
  }
  .form-code {
    font-size: 12px;
  }
  .input {
    font-size: 18px;
    letter-spacing: 15px;
  }
}
@media only screen and (max-width: 920px) {
  .user-preview-modal .modal-dialog {
    max-width: 350px;
  }
  .user-preview-modal__title {
    font-size: 20px;
  }
  .subtitle {
    font-size: 16px;
  }
  .form-code {
    font-size: 12px;
  }
  .input {
    font-size: 18px;
    letter-spacing: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .user-preview-modal {
    .auth .preview-body .d-flex {
      margin-top: 30px;
    }
    .form-code__return {
      font-size: 12px;
    }
    .form-code__light {
      font-size: 10px;
    }
    .form-group {
      margin-bottom: 10px;
    }
    .custom-checkbox {
      margin-top: 30px;
    }

    .two-column {
      display: block;
    }

    .first {
      display: none;
    }

    .second {
      padding: 30px;
    }

    .modal-dialog {
      max-width: 350px;
    }

    .user-preview-modal__title {
      font-size: 20px;
    }

    .subtitle {
      font-size: 14px;
    }

    .form-code {
      font-size: 12px;
    }

    .input {
      font-size: 16px;
      letter-spacing: 13px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .another-variants {
    display: block;
  }
  .form-group.d-flex {
    flex-direction: column;
  }
}
</style>
