<template>
  <div class=" toast--guest toast--base set-flex">
    <audio
      v-if="settings.sound !== 'off' && settings.sound_new_guest === 'on'"
      :src="selectSound('toast')"
      autoplay
    />
    <div class="d-flex align-items-center">
      <span class="rbi rbi-eye" />
      <p v-html="$t('toasts.guest', [name])" />
    </div>
    <div class="toast--link">
      <span class="rbi rbi-right-arrow" />
    </div>
    <slot name="usr-name" />
    <slot name="link" />
  </div>
</template>

<script>

import { NOTIFICATION_SOUND } from '../../../mixins/utils';

export default {
  mixins: [NOTIFICATION_SOUND],
  props: {
    name: String,
  },
  computed: {
    settings() {
      return this.$store.getters.settings;
    },
  },
  mounted() {
    /**
       * Проиграть звуковое уведомление, если установлено соответствующее разрешение в настройках
       */
    // if (this.settings.sound !== 'off' && this.settings.sound_new_guest === 'on') {
    //   this.playNotification();
    // }
  },
  created() {
    /**
       * Выбрать звук для уведомления
       */
    // if (this.settings.sound !== 'off' && this.settings.sound_new_guest === 'on') {
    //   this.selectSound('toast');
    // }
  },
};
</script>
