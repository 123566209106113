<template>
  <div class="features self-features">
    <router-link
      tag="div"
      :to="{name: 'winks'}"
      class="item link-styled"
    >
      <div class="left">
        <span class="rbi rbi-emoji" />
      </div>
      <div class="right">
        <p class="feature-number">
          {{ counters.winks_all }}
        </p>
        <p class="feature-name hidden-xs">
          {{ $t('shared.common.winks') }}
        </p>
      </div>
    </router-link>
    <router-link
      tag="div"
      :to="{name: 'favorites'}"
      class="item link-styled"
    >
      <div class="left">
        <span class="rbi rbi-heart" />
      </div>
      <div class="right">
        <p class="feature-number">
          {{ counters.favorites_all }}
        </p>
        <p class="feature-name hidden-xs">
          {{ $t('shared.common.favourites') }}
        </p>
      </div>
    </router-link>
    <router-link
      tag="div"
      :to="{name: 'bookmarks'}"
      class="item link-styled"
    >
      <div class="left">
        <span class="rbi rbi-star" />
      </div>
      <div class="right">
        <p class="feature-number">
          {{ counters.bookmarks_all }}
        </p>
        <p class="feature-name hidden-xs">
          {{ $t('shared.common.bookmarks') }}
        </p>
      </div>
    </router-link>
    <router-link
      v-if="authorized"
      class="item link-styled"
      tag="div"
      :to="{name: 'albums'}"
    >
      <div class="left">
        <span class="rbi rbi-camera" />
      </div>
      <div class="right">
        <p class="feature-number">
          {{ counters.photos_all }}
        </p>
        <p class="feature-name hidden-xs">
          {{ $t('shared.common.photos') }}
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    	winksLength() {
    		return this.$store.getters.winks.myWinks.length;
    },
    bookmarksLength() {
      return this.$store.getters.bookmarks.length;
    },
    favoritesLength() {
      return this.$store.getters.favorites.length;
    },
    photosLength() {
    		return this.$store.getters.photos.length;
    },
    authorized() {
      return !!window.localStorage['access-token'];
    },
    counters() {
    		return this.$store.getters.counters;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
	.link-styled {
		cursor: pointer;
	}
</style>
