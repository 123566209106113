<template>
  <span class="zodiac-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.742"
      height="31.742"
      viewBox="0 0 31.742 31.742"
    >
      <path d="M30.096 24.16c-.237-1.158-1.373-1.91-2.526-1.67-1.16.235-1.906 1.367-1.668 2.526.053.27-2.26 3.37-3.908 2.63s-1.77-3.75-.705-5.126c2.333-3.002 7.603-10.612 6.122-16.393-.586-2.287-2.148-4.036-4.563-5.082-3.658-1.482-6.75-1.39-9.194.283-3.428 2.344-4.31 7.04-4.53 9.608-.014 0-.025-.005-.04-.005-4.152 0-7.53 3.38-7.53 7.533 0 4.152 3.378 7.53 7.53 7.53 4.153 0 7.53-3.378 7.53-7.53 0-2.57-1.297-4.842-3.27-6.203.037-1.81.56-5.92 2.73-7.4 1.19-.803 2.918-.755 5.114.137 1.52.657 1.91 1.567 2.07 2.194.682 2.656-1.42 7.642-5.354 12.702-2.955 3.8-2.44 7.938 1.37 11.065 2.26 1.416 5.692.635 7.03-.174 2.114-1.27 4.373-3.77 3.79-6.623zm-21.01-2.45c-1.79 0-3.247-1.458-3.247-3.247 0-1.79 1.455-3.25 3.246-3.25s3.247 1.46 3.247 3.25-1.458 3.246-3.247 3.246z" />
    </svg>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style>
</style>
