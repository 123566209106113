<template>
  <transition name="modal">
    <div class="vue-modal price-description-modal">
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container modal-dialog">
          <div class="message-and-chat">
            <button type="button" class="close" @click="$emit('closePriceDescriptionModal')">
              <span class="rbi-close"></span>
            </button>
            <div class="message-and-chat_body">
              <div class="card-description-options message-and-chat_body-item-left">
                <div class="card-description-options_header-item">
                  <img src="../../assets/images/svg/chat.svg" alt="chat-icon">
                  <p>Instant Chat</p>
                </div>
                <div class="card-description-options_body-content">
                  <h5 class="card-description-options_body-headline">Usually used for live short talks with smiles, stickers, video</h5>
                  <div class="card-description-options_body-container">
                    <div class="card-description-options_body-image">
                      <img class="card-description-options_body-image_item" src="../../assets/images/svg/accent-girl.svg" alt="girl-chat">
                      <span class="indicator-online card-description-options_body-image_status" titlestatus="Online">Online</span>
                    </div>
                    <ul class="list is-horizontal card-description-options_body-wrapper">
                      <li class="list-item">
                        <img src="../../assets/images/svg/chat.svg" alt="chat-icon">
                        <div>
                          <p class="card-description-options_body-title">Possible only with users online</p>
                        </div>
                      </li>
                      <li class="list-item">
                        <img src="../../assets/images/svg/chat.svg" alt="chat-icon">
                        <div>
                          <p class="card-description-options_body-title">You pay for time, not for quantity</p>
                          <span class="card-description-options_body-description">(1 credit for 1 minute extra)</span>
                        </div>
                      </li>
                      <li class="list-item">
                        <img src="../../assets/images/svg/chat.svg" alt="chat-icon">
                        <div>
                          <p class="card-description-options_body-title">You can use both text &amp; video</p>
                          <span class="card-description-options_body-description">(1 credit for 1 minute chatting)</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <button
                      :class="{ disabled: disabled.chat }"
                      class="button button-xs button-warning offer-prompt_case-button card-description-options_body-button text-uppercase"
                      type="button"
                      @click="previewStartChat(preview(false), user.user_id)"
                  >
                    <span class="button-text">
                      <div class="offer-prompt_button">
                        <img src="../../assets/images/svg/brown-chat.svg" alt="message">
                        <p>Chat Now</p>
                      </div>
                    </span>
                  </button>
                </div>
              </div>
              <div class="card-description-options message-and-chat_body-item">
                <div class="card-description-options_header-item">
                  <img src="../../assets/images/svg/chat.svg" alt="chat-icon">
                  <p>Messages</p>
                </div>
                <div class="card-description-options_body-content">
                  <h5 class="card-description-options_body-headline">Perfect for longtime mailing, sharing stories &amp; photos etc.</h5>
                  <div class="card-description-options_body-container">
                    <div class="card-description-options_body-image">
                      <img class="card-description-options_body-image_item" src="../../assets/images/svg/girl.svg" alt="girl-chat">
                      <span class="indicator-online indicator-online--offline card-description-options_body-image_status" titlestatus="Offline">Online</span>
                    </div>
                    <ul class="list is-horizontal card-description-options_body-wrapper">
                      <li class="list-item">
                        <img src="../../assets/images/svg/message.svg" alt="chat-icon">
                        <div>
                          <p class="card-description-options_body-title">You can send it when lady is offline</p>
                        </div>
                      </li>
                      <li class="list-item">
                        <img src="../../assets/images/svg/message.svg" alt="chat-icon">
                        <div>
                          <p class="card-description-options_body-title">You pay for quantity, not for time</p>
                          <span class="card-description-options_body-description">(10 credits for 1 sent message)</span>
                        </div>
                      </li>
                      <li class="list-item">
                        <img src="../../assets/images/svg/message.svg" alt="chat-icon">
                        <div>
                          <p class="card-description-options_body-title">Attach your files to message for free</p>
                          <span class="card-description-options_body-description">(photos, pictures...)</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <button
                      class="button button-xs button-warning offer-prompt_case-button card-description-options_body-button text-uppercase"
                      :class="{ disabled: disabled.mail }"
                      type="button"
                      @click="previewSendMail(preview(false), user)"
                  >
                    <span class="button-text">
                      <div class="offer-prompt_button">
                        <img src="../../assets/images/svg/brown-message.svg" alt="message">
                        <p>Send Message</p>
                      </div>
                    </span>
                  </button>
                </div>
              </div>
              <div class="message-and-chat_body-line"></div>
              <div class="message-and-chat_body-centered-title"> Difference </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {ACTIONS_DATA, CHECK_PRIVILEGES, COMPARE_DATA, USER_ACTIONS} from "@/mixins/userActions";
import {MAIL_MODAL, PREVIEW_MODAL, REGISTRATION_MODAL} from "@/mixins/formsActions";

export default {
  name: "PriceDescriptionModal",
  props: ['user'],
  mixins: [
    PREVIEW_MODAL,
    REGISTRATION_MODAL,
    USER_ACTIONS,

    ACTIONS_DATA,
    CHECK_PRIVILEGES,
    MAIL_MODAL,
    COMPARE_DATA,
  ],
  computed: {
    token() {
      return this.$store.getters.user.accessToken;
    },
  },
  mounted() {
    this.canChat(this.user.online);
  },
  methods: {
    /**
     * Обработка действия. Например, подпигивания
     *
     * @param bool {boolean} условие
     *
     * @return {boolean}
     */
    preview(bool) {
      this.$emit('closePriceDescriptionModal')
      if (this.deviceWidth <= 768 && !this.token) {
        this.openRegistrationModal();
        return;
      }
      if (bool) {
        // show usual preview
        this.openPreviewModal(this.user.user_id, bool);
      } else if (this.token) {
        // if authorized and bool == false
        return true;
      } else {
        // if non authorized and bool == false
        this.openPreviewModal(this.user.user_id, bool);
      }
      return false;
    },
  },
  // watch: {
  //   disabled() {
  //     console.log('disabled')
  //     this.canChat(this.user.online);
  //   },
  // }
}
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}
p, h5, ul {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
.vue-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  transition: opacity 0.3s ease;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .loader {
    align-self: center;
  }

  &--container {
    min-width: 260px;
    width: 850px;
    margin: 0 auto;
    background-color: #fff;
    transition: all 0.3s ease;
    padding: 0;
  }

  &--wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    min-width: 260px;
    height: auto;
    //height: 300px;

  }
}
.message-and-chat {
  width: 850px;
  position: relative;
}
.message-and-chat_body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 15px;
  position: relative;
}
.card-description-options {
  width: 50%;
}
.card-description-options_header-item {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 8px;
   font-weight: 700;
   padding: 15px;
   background: #e6eff5;
}
.card-description-options_header-item:first-child {
  border-right: 1px solid #fff;
}
.message-and-chat_body-item-left .card-description-options_body-content {
  padding-right: 25px;
}
.card-description-options_body-content {
  padding: 15px 15px 0 15px;
}
.card-description-options_body-headline {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 16px;
}
.card-description-options_body-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: flex-start;
  gap: 0 10px;
}
.card-description-options_body-image {
  background: #e6eff5;
  padding: 10px;
  min-height: 120px;
  position: relative;
  border-radius: 8px;
}
.card-description-options_body-image_item {
  width: 100%;
  height: 100%;
}
.card-description-options_body-image_status {
  position: absolute;
  top: 0;
  left: 0;
}
.indicator-online {
  background-color: #6ee563;
  border: 2px solid #fff;
  color: #046124;
  animation: highlight 3s ease-in-out infinite;
}
.indicator-online {
  padding: 4px 8px;
  font-size: .625rem;
  border-radius: 8px 0 12px 0;
  font-weight: 700;
}
.list.is-horizontal {
  display: flex;
  flex-wrap: wrap;
}
.card-description-options_body-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card-description-options_body-wrapper li {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
.card-description-options_body-title {
  font-size: 12px;
}
.card-description-options_body-description {
  color: #97a7bf;
  font-size: 10px;
}
.button-xs {
  padding: 4px 4px;
  font-size: .625rem;
}
.button-warning {
  background-color: #ffb700;
  color: #9d3e0e;
}
.offer-prompt_case-button {
  display: flex;
  width: 50%;
}
.button:not(:disabled) {
  cursor: pointer;
}
.card-description-options_body-button {
  width: 100%;
  margin-top: 15px;
}
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: 700 1rem/1.132 PT Sans,sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  box-shadow: 0 0 0 1px transparent;
  border: none;
  outline: none;
  border-radius: 4px;
  text-transform: uppercase;
  transition: transform .15s ease-in-out,color .3s ease-in-out,box-shadow .3s ease-in-out,background-color .3s ease-in-out,opacity .3s ease-in-out,border-color .3s ease-in-out;
}
.disabled {
  cursor: not-allowed;
  background: #acacac;
}
.offer-prompt_button {
  display: flex;
  justify-content: center;
  gap: 0 10px;
  align-items: center;
  color: #9d3e0e;
}
.message-and-chat_body-line {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background-color: #e6eff5;
  width: 2px;
  height: 70%;
  display: block;
}
.message-and-chat_body-centered-title {
  background-color: #fff;
  position: absolute;
  color: #97a7bf;
  left: 50%;
  top: 50%;
  padding: 5px 20px;
  transform: translate(-50%,-50%) rotate(270deg);
  display: block;
}
.message-and-chat_body-item .card-description-options_body-content {
  padding-left: 25px;
}
.close {
  position: absolute;
  top: 6px;
  right: 6px;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 1;
  span {
    color: #FF5C00;
    font-size: 20px;
  }
}
@media screen and (max-width: 880px) {
  .vue-modal--container {
    width: 600px;
  }
  .message-and-chat {
    width: 600px;
    max-height: 400px;
    overflow: auto;
  }
  .message-and-chat_body {
    flex-direction: column;
  }
  .card-description-options {
    width: 100%;
  }
  .message-and-chat_body-line,
  .message-and-chat_body-centered-title {
    display: none;
  }
  .message-and-chat_body-item-left .card-description-options_body-content {
    padding-right: 15px;
  }
  .card-description-options_body-content {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 650px) {
  .vue-modal--container {
    width: 400px;
  }
  .message-and-chat {
    width: 400px;
  }
}
@media screen and (max-width: 450px) {
  .vue-modal--container {
    width: 350px;
  }
  .message-and-chat {
    width: 350px;
  }
}
@media screen and (max-width: 380px) {
  .vue-modal--container {
    width: 320px
  }
  .message-and-chat {
    width: 320px
  }
}
@media screen and (max-width: 330px) {
  .vue-modal--container {
    width: 280px
  }
  .message-and-chat {
    width: 280px
  }
}
@media screen and (max-height: 420px) {
  .message-and-chat {
    max-height: 280px;
  }
}
</style>