<template>
  <div
    v-show="isActive"
    class="stepper_wrapper"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'StepperContent',
  props: ['step'],
  computed: {
    isActive() {
      return this.$parent.currentStep === this.step;
    },
  },
};
</script>

<style scoped>
  .component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
  }
  .component-fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
</style>
