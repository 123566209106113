<template>
<!--  <div v-show="display" class="responsive-box wrap-padding contacts-box">-->
    <div style="display: none" class="responsive-box wrap-padding contacts-box">
    <div class="flexblock">
      <div class="item item-title">
        <h2 class="title">
          {{ $t('profile.contacts') }}
        </h2>
      </div>
      <div class="item">
        <div class="caption">
          <span class="rbi rbi-phone" />
          <p class="hidden-xs">{{ $t('profile.phoneNumber') }}:</p>
        </div>
        <div class="val">
          {{ displayedNumber }}
        </div>
      </div>
      <div class="item">
        <div class="caption">
          <span class="rbi rbi-arroba" />
          <p class="hidden-xs">{{ $t('profile.email') }}:</p>
        </div>
        <div class="val">
          {{ displayedEmail }}
        </div>
      </div>
      <div class="item buy-button dropdown">
        <button
          v-if="viewedUser.isWoman && !sex.isWoman && !contact.phone_number && !contact.email"
          class="btn btn-outline-red"
          @click="buy"
        >
          {{ buttonText }}
        </button>
        <div v-if="!contact.buy_status && open" v-click-away="closeDropdown" class="c-buy-info">
          <div class="c-buy-info--header set-flex set-flex-y-center">
            <div class="set-flex set-flex-y-center">
              <span class="rbi rbi-attention" />
              <p class="title">
                {{ $t('profile.errors.failBuy') }}
              </p>
            </div>
            <button type="button" class="close" @click="closeDropdown">
              <span class="rbi-close" />
            </button>
          </div>
          <div class="c-buy-info--progress">
            <div ref="progress" />
          </div>
          <ul class="list-unstyled c-buy-info--list">
            <li class="text-muted" :class="{ completed: displLetters.completed }">
              <p>{{ $t('profile.vContact.letters', [displLetters.num, user.name]) }}</p>
            </li>
            <li class="text-muted" :class="{ completed: displMess.completed }">
              <p>{{ $t('profile.vContact.chat', [displMess.num, user.name]) }}</p>
            </li>
          </ul>
          <div class="c-buy-info--tip">
            {{ $t('profile.vContact.get', [user.name]) }}
          </div>
          <div v-if="displLetters.completed && displMess.completed" class="c-buy-info--tip">
            <button
              class="btn btn-orange transparent center-block"
              type="button"
              @click="buyContact"
            >
              {{ $t('profile.vContact.buy') }}
            </button>
          </div>
          <hr />
          <p class="text-muted text-small">
            {{ $t('profile.vContact.charge', [406]) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from 'progressbar.js';
import { PREVIEW_MODAL, REGISTRATION_MODAL, LOW_CREDITS_MODAL } from '../../../mixins/formsActions';

export default {
  name: 'ContactBox',
  bar: null,
  mixins: [PREVIEW_MODAL, REGISTRATION_MODAL, LOW_CREDITS_MODAL],
  props: {
    user: Object,
    viewedUser: Object,
    loaded: Boolean,
    isAuthorized: Boolean,
  },
  data() {
    return {
      display: false,
      open: false,
      asked: false,
      contact: {},
    };
  },
  computed: {
    /**
     * Возвращает отображаемый номер пользователя
     *
     * @return {string}
     */
    displayedNumber() {
      if (this.sex.isMan) {
        return this.contact.phone_number || '•••••••••••••';
      }
      if (this.sex.isWoman) {
        return this.user.phone || this.$t('profile.noFill');
      }
      return '•••••••••••••';
    },
    /**
     * Возвращает отображаемый email пользователя
     *
     * @return {string}
     */
    displayedEmail() {
      if (this.sex.isMan) {
        return this.contact.email || '•••••••••••••';
      }
      if (this.sex.isWoman) {
        return this.user.email || this.$t('profile.noFill');
      }
      return '•••••••••••••';
    },
    progress() {
      const lettersCur = +this.contact.count_message;
      const lettersMax = this.contact.need_count_message;
      const messCur = +this.contact.count_minute_chat;
      const messMax = this.contact.need_count_minute_chat;
      const letters = lettersCur <= lettersMax ? lettersCur : lettersMax;
      const mess = messCur <= messMax ? messCur : messMax;
      return (letters + mess) / (lettersMax + messMax);
    },
    sex() {
      return this.$store.getters.sex;
    },
    /**
     * Возвращает отображаемый текст кнопки покупки контактов
     *
     * @return {string}
     */
    buttonText() {
      if (this.contact.buy_status) {
        return this.compareStatus(this.contact.moderation_agency, this.contact.moderation_site);
      }
      return this.sex.isMan ? this.$t('profile.vContact.buy') : this.$t('profile.vContact.show');
    },
    /**
     * Возвращает отображаемое кол-во писем, необходимое для покупки контактов
     *
     * @return {string}
     */
    displLetters() {
      return this.calcAmount(+this.contact.count_message, this.contact.need_count_message) || {};
    },
    /**
     * Возвращает отображаемое кол-во сообщений в чате, необходимое для покупки контактов
     *
     * @return {string}
     */
    displMess() {
      return (
        this.calcAmount(+this.contact.count_minute_chat, this.contact.need_count_minute_chat) || {}
      );
    },
    selfUser() {
      return this.$store.getters.user;
    },
  },
  watch: {
    /**
     * При открытии дропдауна начинаем рисовать прогрессбар
     * при закрытии - уничтожаем прогрессбар
     *
     * @param v {boolean}
     */
    open(v) {
      const self = this;
      if (v) {
        setTimeout(() => {
          self.drawProgress();
        }, 0);
      } else if (this.bar) {
        this.bar.destroy();
        this.bar = null;
      }
    },
    /**
     * При изменеии пользователя очищаем данные и спрашиваем данные о контактах уже с ним
     *
     * @param v
     */
    user(v) {
      if (v.user_id !== undefined && !this.asked) {
        this.asked = true;
        this.bar = null;
        const self = this;
        setTimeout(() => {
          //						if ( self.sex.isMan ) {
          self.askStatus();
          //						}
        }, 100);
      } else {
        this.asked = false;
        this.display = false;
      }
    },
  },
  mounted() {
    if (!this.asked) {
      this.askStatus();
    }
  },
  methods: {
    /**
     * сменить отображение дропдауна
     */
    toggleDropdown() {
      if (!this.contact.buy_status) {
        this.open = !this.open;
      }
    },
    /**
     * спрятать дроадаун
     */
    closeDropdown() {
      this.open = false;
    },
    /**
     * покупка контактов. Если гость в респонзиве - открываем модалку с решистрацией
     * если гость - открываем модалку с превьюшкой
     * если зареганный = открываем дроадаун
     */
    buy() {
      if (this.deviceWidth <= 768 && !window.localStorage['access-token']) {
        // must be
        this.openRegistrationModal();
        return;
      }
      if (!window.localStorage['access-token']) {
        this.openPreviewModal(this.user.user_id, false);
      } else {
        this.toggleDropdown();
      }
    },
    /**
     * Считаем нужное кол-во чего-либо
     *
     * @param current {number} текущее значение
     * @param max {number} максимальное значение
     * @return {object}
     */
    calcAmount(current, max) {
      return current < max
        ? { num: max - current, completed: false }
        : { num: max, completed: true };
    },
    /**
     * создание и отправка запроса на покупку контакта
     */
    buyContact() {
      this.$http
        .post('v1/realinfo-request/make', {
          access_token: window.localStorage['access-token'],
          user_id: this.user.user_id,
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.$store.dispatch('addAlert', {
                type: 'success',
                text: this.$t('alerts.contactRequestSuccess'),
                info: 'real info buy',
              });
              this.closeDropdown();
            } else {
              const { code } = r.body;
              if (code === 10910) {
                this.$store.dispatch('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.contactRequestFail'),
                  info: 'real info buy',
                });
              } else if (code === 10028 || code === 10161 || code === 10025 || !r.body.status) {
                this.openCreditsModal();
              } else {
                this.$store.dispatch('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.someErr'),
                  info: 'real info buy',
                });
              }
            }
          },
          () => {
            this.$store.dispatch('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
              info: 'real info buy',
            });
          }
        );
    },
    /**
     * спросить статус о возможности приобритения контактов пользователя
     */
    askStatus() {
      if (this.user.user_id && this.user.user_id !== this.selfUser.user_id && this.isAuthorized) {
        this.$http
          .post('v1/realinfo-request/get-status', {
            access_token: window.localStorage['access-token'],
            user_id: this.user.user_id,
          })
          .then(
            (r) => {
              this.display = true;
              if (r.body.status) {
                this.contact = r.body;
              } else {
                this.$store.dispatch('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.someErr'),
                  info: 'real info request',
                });
              }
            },
            () => {
              this.display = true;
              this.$store.dispatch('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
                info: 'real info request',
              });
            }
          );
      }
      if (
        (this.user.user_id && this.user.user_id === this.selfUser.user_id) ||
        !this.isAuthorized
      ) {
        this.display = true;
      }
    },
    /**
     * Инициализация плагина с отрисовкой прогрессбара
     */
    drawProgress() {
      if (!this.bar) {
        this.bar = new ProgressBar.Line(this.$refs.progress, {
          strokeWidth: 2,
          easing: 'linear',
          duration: 60000,
          color: '#FFEA82',
          trailColor: '#eee',
          trailWidth: 2,
          svgStyle: { width: '100%', height: '100%', 'border-radius': '2px' },
          text: {
            style: {
              position: 'absolute',
              left: '0',
              top: 'auto',
              margin: 0,
            },
            autoStyleContainer: false,
          },
          from: { color: '#FFEA82' },
          to: { color: '#05ca6b' },
          step: (state, bar) => {
            bar.path.setAttribute('stroke', state.color);
            if (bar.text) {
              bar.text.style.left = `${Math.round(bar.value() * 100)}%`;
            }
            bar.setText(`${Math.round(bar.value() * 100)}%`);
          },
        });
      }
      this.bar.set(this.progress); // Number from 0.0 to 1.0
    },
    /**
     * Унификация статуса покупки контакта в зависимости от статусов агенства и сайта
     *
     * @param agency {string} статус модерации агенства
     * @param site {string} статус модерации сервиса
     */
    compareStatus(agency, site) {
      if (agency === 'free' && agency === site) {
        return this.$t('profile.vContact.process');
      }
      if (agency === 'reject') {
        return this.$t('profile.vContact.reject');
      }
      if (site === 'reject') {
        return this.$t('profile.vContact.verification');
      }
      if (agency === 'accept' && agency === site) {
        return this.$t('profile.vContact.accept');
      }
      if (agency === 'accept' && site !== 'reject') {
        return this.$t('profile.vContact.verification');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';
</style>
