<template>
  <transition name="modal">
    <div
      v-show="mailModal.open"
      class="vue-modal modal-mask mail-modal"
      @click="closeModal($event.target, false)"
    >
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container mail-modal--body">
          <div class="modal-header">
            <h4 class="modal-title">
              {{ title }}
            </h4>
            <button type="button" class="close" @click="closeModal(false, true)">
              <span class="rbi rbi-close" />
            </button>
          </div>
          <div class="modal-body">
            <form class="new-mail-form" @submit.prevent="submitLetter">
              <div
                v-if="user.user_id"
                class="form-group destination-users"
                :class="{ 'has-error': error }"
              >
                <p class="destination-users__item">{{ $t('mail.placeholders.to') }}:</p>
                <div class="destination-users__item user-addressee">
                  <div class="small-img-wrap">
                    <img
                      :src="setPhotoSource(user.avatar.img_small)"
                      :alt="user.name + $t('shared.alts.ava')"
                    />
                  </div>
                  <span>{{ user.name }}</span>
                  <button
                    v-if="!mailModal.isAnswer && !hideClearBtn"
                    type="button"
                    class="btn-close"
                    @mouseup="clearUser"
                  >
                    <span class="rbi rbi-close" />
                  </button>
                </div>
                <div v-show="error" class="error">
                  <i class="fa fa-warning" />
                  <span>{{ error }}</span>
                </div>
              </div>
              <div v-else class="form-group" :class="{ 'has-error': error }">
                <quick-search
                  :placeholder="$t('mail.placeholders.to')"
                  @selected="userSelected($event)"
                />
                <div v-show="error" class="error">
                  <i class="fa fa-warning" />
                  <span>{{ error }}</span>
                </div>
              </div>
              <div class="form-group" :class="{ 'has-error': err.first('mMailSubject') }">
                <input
                  v-model="subject"
                  type="text"
                  name="mMailSubject"
                  class="form-control"
                  :placeholder="$t('mail.placeholders.subject')"
                />
                <div v-show="err.first('mMailSubject')" class="error">
                  <i class="fa fa-warning" />
                  <span>{{ err.first('mMailSubject') }}</span>
                </div>
              </div>
              <div class="form-group" :class="{ 'has-error': err.first('mMailMessage') }">
                <content-editable
                  v-if="mailModal.open"
                  v-model="message"
                  :name="'mMailMessage'"
                  :placeholder="$t('mail.placeholders.message')"
                  class="message form-control"
                  @clearText="clearText = $event"
                />
                <p v-if="sex.isWoman" v-show="clearText" class="small text-muted">
                  {{ $t('mail.utils.symbCount') }} : {{ clearText.length }}
                </p>
                <div v-show="err.first('mMailMessage')" class="error">
                  <i class="fa fa-warning" />
                  <span>{{ err.first('mMailMessage') }}</span>
                </div>
              </div>
              <div v-show="attachments.length" class="attach-box--photos">
                <div
                  v-for="(photo, index) in attachments"
                  :key="photo.id"
                  class="attach-box--photos__item"
                >
                  <div class="image-wrap">
                    <img
                      class="img-respons"
                      :src="photo._photo"
                      :alt="user.name + $t('shared.alts.photo')"
                      @load="photo.loading = false"
                    />
                    <div v-show="!photo.loading" class="del-btn" @mouseup="unAttachPhoto(index)">
                      <span class="rbi rbi-close" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="send-box set-flex form-group">
                <rb-button type="submit" :disabled="isSending" class="btn-black">
                  {{ $t('mail.utils.send') }}
                </rb-button>
                <attach-box
                  :photos="attachments"
                  :clear="clear"
                  :user-id="user.user_id"
                  @attached="attachments = $event"
                />
              </div>
            </form>
            <div v-if="sex.isMan && letterPrice" style="font-size: smaller">
              Sending a letter costs {{ letterPrice }} credits.
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Validator } from 'vee-validate';
import { LOW_CREDITS_MODAL } from '../../../mixins/formsActions';
import QuickSearch from '../parts/QuickSearch.vue';
import RbButton from '../../Button';
import ContentEditable from '../../Authorized/parts/ContentEditable.vue';
import AttachBox from '../parts/AttachPhotosBox.vue';
import { PHOTO_SRC } from '../../../mixins/utils';
import { ATTACHMENTS, CLEAN_HTML } from '../../../mixins/types/mail';

export default {
  name: 'ModalMail',
  validator: null,
  components: {
    QuickSearch,
    AttachBox,
    ContentEditable,
    RbButton,
  },
  mixins: [PHOTO_SRC, ATTACHMENTS, LOW_CREDITS_MODAL, CLEAN_HTML],
  data() {
    return {
      isSending: false,
      message: '',
      clearText: '',
      subject: '',
      error: '',
      clear: 0,
      keepUser: false,
      err: [],
    };
  },
  computed: {
    letterPrice() {
      return this.$store.getters.siteSettings && this.$store.getters.siteSettings.write_letter
        ? this.$store.getters.siteSettings.write_letter
        : null;
    },
    mailModal() {
      return this.$store.getters.mailModal;
    },
    /**
     * возвращает заголовок для модалки
     */
    title() {
      return this.mailModal.womanCanAnswer
        ? this.$t('mail.utils.answerOn')
        : this.$t('mail.utils.createNew');
    },
    user() {
      return this.mailModal.to;
    },
    sex() {
      return this.$store.getters.sex;
    },
    /**
     * проверяет, нужно ли прятать кнопку с очисткой пользователя
     *
     * @return {boolean}
     */
    hideClearBtn() {
      return this.$route.name === 'dialog' || this.$route.name === 'mail';
    },
  },
  watch: {
    /**
     * Валидация текста письма
     *
     * @param v {string}
     */
    clearText(v) {
      this.validator.validate('mMailMessage', v).catch(() => {});
    },
    /**
     * Валидация ntvs письма
     *
     * @param v {string}
     */
    subject(v) {
      this.validator.validate('mMailSubject', v).catch(() => {});
    },
    /**
     * Считаем, нужно ли сохранять состояние модалки при её закрытии
     * (мисклик или обычное закрытие)
     *
     * @param n {object}
     * @param o {object}
     */
    user(n, o) {
      if (o && o.user_id !== n.user_id && !this.keepUser) {
        this.clearData();
      }
    },
  },
  created() {
    this.initValidation();
  },
  methods: {
    unAttachPhoto(index) {
      this.attachments.splice(index, 1);
    },

    /**
     * Переадресация пользователя на соответствующий роут при ответе на письмо
     */
    additionalActions() {
      switch (this.$route.name) {
        case 'dialog':
          this.$router.push({
            name: 'mailbox',
            query: { view: 'dialog', in: 'all' },
          });
          break;
        case 'mail':
          this.$router.push({ name: 'mailbox' });
          break;
      }
    },
    /**
     * закрыть модалку
     *
     * @param e {object} event
     * @param clear {boolean}
     */
    closeModal(e, clear) {
      // eslint-disable-next-line no-undef
      if (!$(e).closest('.mail-modal--body').length || !e) {
        if (clear) {
          this.clearData();
          this.clearUser();
        }
        this.$store.commit('updateMailModal', {
          open: false,
        });
      }
    },
    /**
     * очистка состояния компонента
     */
    clearData() {
      this.message = '';
      this.clearText = '';
      this.subject = '';
      this.keepUser = false;
      this.clear++;
      this.attachments = [];
      this.error = '';

      const self = this;
      setTimeout(() => {
        self.err.clear();
      }, 0);
    },
    /**
     * выбор пользователя, которому будет отправлено письмо
     *
     * @param user {object}
     */
    userSelected(user) {
      this.$store.commit('updateMailModal', {
        to: user,
      });
      this.error = '';
    },
    /**
     * очистка пользователя-получателя
     */
    clearUser() {
      this.keepUser = true;
      this.$store.commit('updateMailModal', {
        to: '',
      });
    },
    /**
     * ответить на письмо (только клиентки)
     */
    replyLetter() {
      const attachments = this.attachments.map((i) => i.id) || [];
      this.isSending = true;

      this.$http
        .post('v1/message/reply', {
          access_token: window.localStorage['access-token'],
          target_message_id: this.mailModal.data.targetMessage,
          message: this.cleanHtml(this.message),
          subject: this.subject,
          attachments: JSON.stringify(attachments),
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.$store.commit('updateMailState');
              this.closeModal(false, true);
              this.clearData();
              this.$store.commit('updateMailModal', {
                data: {
                  targetMessage: 0,
                },
                isAnswer: false,
              }); // clear
              this.$store.commit('addAlert', {
                type: 'success',
                text: this.$t('alerts.letterSuccess'),
              });
              this.isSending = false;
              this.additionalActions();
            } else {
              this.isSending = false;
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
              });
            }
          },
          () => {
            this.isSending = false;
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
            });
          }
        );
    },
    /**
     * написать новое письмо (клиентки и клиенты)
     */
    sendLetter() {
      const attachments = this.attachments.map((i) => i.id) || [];
      this.isSending = true;
      this.$http
        .post('v1/message/make', {
          access_token: window.localStorage['access-token'],
          target_user_id: this.user.user_id,
          message: this.cleanHtml(this.message),
          subject: this.subject,
          attachments: JSON.stringify(attachments),
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.$store.dispatch('getUserBalance');
              this.$store.commit('updateMailState');
              this.closeModal(false, true);
              this.clearData();
              this.$store.commit('addAlert', {
                type: 'success',
                text: this.$t('alerts.letterSuccess'),
              });
              this.isSending = false;
              this.additionalActions();
            } else {
              this.isSending = false;
              const { code } = r.body;
              if (code === 10028 || code === 10161 || code === 10025 || !r.body.status) {
                this.openCreditsModal();
              } else {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.someErr'),
                });
              }
            }
          },
          () => {
            this.isSending = false;
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
            });
          }
        );
    },
    /**
     * иницииировать отправку письма, запуск валидации полей
     */
    submitLetter() {
      !this.user.user_id ? (this.error = this.$t('mail.errors.noUser')) : '';
      this.validator
        .validateAll({
          mMailSubject: this.subject,
          mMailMessage: this.clearText,
        })
        .then((success) => {
          if (success && !this.error && !this.isSending) {
            this.mailModal.isAnswer ? this.replyLetter() : this.sendLetter();
          }
        })
        .catch(() => {});
    },
    /**
     * инициализировать плагин с валидацией
     */
    initValidation() {
      this.validator = new Validator();
      const titleValidation = this.sex.isMan ? 'max:255' : 'required|min:10|max:255';
      const letterValidation = this.sex.isMan ? 'required|max:5000' : 'required|min:200|max:5000';
      this.validator.attach('mMailSubject', titleValidation, {
        prettyName: this.$t('mail.placeholders.subject'),
      });
      this.validator.attach('mMailMessage', letterValidation, {
        prettyName: this.$t('mail.placeholders.message'),
      });
      this.$set(this, 'err', this.validator.errorBag);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/vars';
.mail-modal {
  .q-search {
    display: block !important;
    &--input {
      width: 100% !important;
    }
  }
  .form-control {
    width: 100%;
  }

  .modal-header {
    padding: 20px 20px 0;
  }
  .send-box {
    margin-top: 30px;
  }

  .destination-users {
    display: flex;
    align-items: center;

    .small-img-wrap {
      padding: 3px;
      position: relative;
      margin-right: 10px;

      &:before {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: 1px solid transparent;
        border-right-color: $black;
      }
      img {
        width: 45px;
        height: 45px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }
    }

    .btn-close {
      background: transparent;
      border: none;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      color: $black;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      align-self: flex-start;
    }
    .user-addressee {
      display: flex;
      align-items: center;
    }
  }

  .form-group {
    margin-bottom: 20px;

    .message {
      border: 1px solid $grey-two;
    }
  }

  .attach-box--photos {
    display: flex;
    flex-wrap: wrap;
    margin: 25px -2px 0;
    &__item {
      width: 20%;
      position: relative;
      padding: 0 2px;

      .image-wrap {
        position: relative;
        padding-bottom: 88%;
        &:before {
          position: absolute;
          bottom: -3px;
          width: 25%;
          left: 0;
          height: 3px;
          display: block;
          content: '';
          background: #e21919;
        }

        .img-respons {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .del-btn {
        position: absolute;
        top: 5px;
        right: 5px;
        background: #ffffff;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;
        transition: 0.3s;
      }
    }
  }

  &--body {
    max-width: 100%;
    padding: 0;
  }

  .modal-body {
    padding: 0 20px 20px;
  }

  .send-box {
    flex-wrap: wrap;
    .btn {
      font-size: 12px;
      margin-right: 15px;
    }
    .attach-box {
      &--inner {
        .btn {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
