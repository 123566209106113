<template>
  <span class="zodiac-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M31.882 17.006c.498-2.98-.598-5.78-2.93-7.495-1.226-.9-2.632-1.363-3.89-1.353V6.654c0-3.987-3.293-4.66-5.37-4.66-1.395 0-2.69.525-3.65 1.48l-.006.006c-1.02-.974-2.374-1.485-3.69-1.485-1.39 0-2.685.524-3.647 1.48-.01.005-.015.017-.022.024-.985-.823-2.214-1.27-3.418-1.27-1.392 0-2.688.525-3.65 1.48C.876 4.44 0 5.76 0 7.91c0 .978.792 1.77 1.772 1.77s1.772-.792 1.772-1.77c0-1.98 1.312-2.134 1.715-2.134.842 0 1.826.558 1.826 2.133V22.9c0 .978.793 1.77 1.772 1.77s1.772-.793 1.772-1.77V7.67c0-1.978 1.544-2.128 1.968-2.128.424 0 1.877.113 1.877 2.41 0 2.87-.045 14.946-.045 14.946 0 .98.792 1.77 1.772 1.77s1.772-.792 1.772-1.77V7.67c0-1.978 1.312-2.13 1.715-2.13.842 0 1.827.556 1.827 2.13 0 0 .05 16.174.05 17.227-1.44.844-2.84 1.396-3.894 1.596-.962.182-1.595 1.107-1.414 2.07.16.85.903 1.442 1.74 1.442.106 0 .22-.012.33-.03 1.42-.27 3.4-1.045 5.417-2.277 1.896.97 4.486 1.44 5.8 1.627.955.13 1.866-.537 2.003-1.504.14-.97-.533-1.866-1.504-2.005-1.008-.146-2.057-.375-2.95-.627 2.317-2.123 4.234-4.88 4.786-8.182zm-6.762 5.182c.002-.78.005-1.646.01-2.55.01-2.866.02-6.07.02-7.955.397.02 1.146.22 1.877.82.662.542 1.718 1.774 1.36 3.917-.367 2.203-1.653 4.167-3.267 5.768z" />
    </svg>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style>
</style>
