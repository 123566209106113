<template>
  <li ref="invite" class="invites--item ml">
    <div class="invites__body">
      <!--<audio
        v-if="
          invite.sound &&
          settings.sound !== 'off' &&
          settings.sound_chat === 'on' &&
          settings.sound_chat_notification === 'on'
        "
        :src="selectSound('chatInvite')"
        autoplay
      ></audio>-->
      <router-link
        class="uname d-none d-lg-block"
        :to="{ name: 'profile', params: { id: invite.senderId } }"
      >
        {{ invite.senderName }} <span> ID:{{ invite.senderId }}</span>
      </router-link>
      <div class="invite__sender">
        <div class="invite__img" @click="avaClick">
          <div class="circle-indicator d-block d-lg-none" :class="'circle-' + invite.senderId" />
          <img
            :src="invite.user.avatar.img_medium"
            class="user-avatar"
            :alt="invite.senderName + $t('shared.alts.ava')"
          />
          <span v-if="userWebcam(invite.user.camera).status" class="webcam d-none d-lg-block">
            <span class="rbi rbi-webcam" />
          </span>
        </div>
        <div class="invite__sender_text">
          <p ref="inviteMsg" v-html="messageFromShort(invite.text)" style='font-size: 18px;' />
<!--          <p style='padding-top: 3px'>{{ invite.text }}</p>-->
        </div>
      </div>
      <div class="invite__controls d-none d-lg-flex">
        <rb-button
          class="btn-white"
          :disabled="invite.inviteType !== 'videoInvite'"
          @click.native="
            acceptInvite(invite.senderId, 'videoInvite', invite.roomId, invite.notificationId)
          "
        >
          <span class="rbi rbi-video" />

          <span>
            <template v-if="sex.isMan"> video<br />chat </template>
            <template v-else> видео<br />чат </template>
          </span>
        </rb-button>
        <rb-button
          v-if="invite.inviteType === 'textInvite' || sex.isMan"
          class="btn-white"
          @click.native="
            acceptInvite(invite.senderId, 'textInvite', invite.roomId, invite.notificationId)
          "
        >
          <span class="rbi rbi-chat" />
          <span>
            <template v-if="sex.isMan"> text<br />chat </template>
            <template v-else> текст<br />чат </template>
          </span>
        </rb-button>
      </div>
      <div class="life-indicator d-none d-lg-block" :class="'line-' + invite.senderId" />
    </div>

    <!--    <div class="invites__body">
          <div class="invite__img" @click="avaClick">
            <div class="circle-indicator"
                 :class="'circle-' + invite.senderId" v-if="sex.isMan"></div>
            <img :src="invite.user.avatar.img_medium"
                 class="user-avatar"
                 :alt="invite.senderName + $t('shared.alts.ava')">
          </div>
          <div :class="{'d-none d-lg-block':sex.isMan }" class=" invite__text">
            <router-link :to="{name: 'profile', params:{id:invite.senderId}}">{{ invite.senderName
              }},<span> ID:{{ invite.senderId }}</span>
            </router-link>
            <p v-html="messageFromShort(invite.text)"></p>
          </div>
          <div :class="{'d-none d-lg-flex': sex.isMan}" class=" invite__block-btn">
            <button class="invite__btn"
                    v-if="invite.inviteType === 'videoInvite'"
                    @click="acceptInvite(invite.senderId, 'videoInvite', invite.roomId, invite.notificationId)">

              <span class="rbi rbi-video"></span>
            </button>
            <button class="invite__btn" v-if="invite.inviteType === 'textInvite' || sex.isMan"
                    @click="acceptInvite(invite.senderId, 'textInvite', invite.roomId, invite.notificationId)">
              <span class="rbi rbi-chat"></span>
            </button>
          </div>
        </div>-->

    <!--<div class="invites&#45;&#45;item__info visible-lg">-->
    <!--<router-link :to="{name: 'profile', params:{id:invite.senderId}}">{{ invite.senderName-->
    <!--}} | ID:{{ invite.senderId }}-->
    <!--</router-link>-->
    <!--</div>-->
    <!--<div class="invites&#45;&#45;item__body">-->
    <!--<div class="img-container"-->
    <!--@click="avaClick"-->
    <!--:class="{'is-new': invite.user.new, 'is-online': invite.user.online}">-->
    <!--<div class="circle-indicator hidden-lg"-->
    <!--:class="'circle-' + invite.senderId" v-if="sex.isMan"></div>-->
    <!--<img :src="invite.user.avatar.img_medium"-->
    <!--class="user-avatar"-->
    <!--:alt="invite.senderName + $t('shared.alts.ava')">-->
    <!--<span :class="userWebcam(invite.user.camera)"></span>-->
    <!--<div class="pseudo-button" v-if="sex.isWoman">-->
    <!--<template v-if="invite.inviteType === 'textInvite'">-->
    <!--<span><svg style="enable-background:new 0 0 487.7 487.7" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 487.7 487.7" version="1.1" y="0px" x="0px"><path d="m437 10.8h-386c-27.9 0-50.5 22.6-50.5 50.5v276c0 27.9 22.6 50.5 50.5 50.5h11.2v81.4c0 6.6 8 9.9 12.6 5.2l86.6-86.6h276c27.9 0 50.5-22.6 50.5-50.5v-276c0-26.5-23-49.2-51-49.2zm-281 215c-14.5 0-26.3-11.8-26.3-26.3s11.8-26.3 26.3-26.3 26.3 11.8 26.3 26.3-11 26-26 26zm88 0c-14.5 0-26.3-11.8-26.3-26.3s11.8-26.3 26.3-26.3c14.5 0 26.3 11.8 26.3 26.3s-12 26-26 26zm87 0c-14.5 0-26.3-11.8-26.3-26.3s11.8-26.3 26.3-26.3 26.3 11.8 26.3 26.3-11 26-26 26z"/></svg></span>-->
    <!--{{ $t( 'shared.utils.text' ) }}-->
    <!--</template>-->
    <!--<template v-else>-->
    <!--<span><svg xmlns="http://www.w3.org/2000/svg" class="svg" version="1.1"  x="0px" y="0px" viewBox="0 0 37 33" xml:space="preserve" enable-background="new 0 0 37 33"><switch><foreignObject requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/" x="0" y="0" width="1" height="1"/><g><path d="M18.6 1C8.9 1 1 7.7 1 15.9c0 4 1.8 7.6 4.8 10.2l-1.6 6 8.4-2.2c1.9 0.6 3.9 0.9 6 0.9 9.7 0 17.6-6.7 17.6-14.9S28.3 1 18.6 1zM28.5 20.3c0 0.6-0.7 1-1.2 0.7L24 19.1v0.6c0 1.7-1.3 3-3 3h-8.8c-1.7 0-3-1.3-3-3v-6.7c0-1.7 1.3-3 3-3h8.8c1.7 0 3 1.3 3 3v0.6l3.4-1.9c0.5-0.3 1.2 0.1 1.2 0.7V20.3z"/></g></switch></svg></span>-->
    <!--{{ $t( 'shared.utils.video' ) }}-->
    <!--</template>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="blockquote">-->
    <!--<p class="invites&#45;&#45;item__info-resp" v-if="sex.isWoman"><b>{{ invite.senderName }}</b>-->
    <!--| ID:{{ invite.senderId }}</p>-->
    <!--<div class="blockquote&#45;&#45;inner">-->
    <!--<p v-html="messageFromShort(invite.text)"></p>-->
    <!--</div>-->
    <!--<div class="blockquote&#45;&#45;indicator hidden-lg" v-if="sex.isWoman"-->
    <!--:class="'responsive-' + invite.senderId"></div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="invites&#45;&#45;item__buttons clearfix">-->
    <!--<button class="btn btn-primary"-->
    <!--v-if="invite.inviteType === 'videoInvite'"-->
    <!--@click="acceptInvite(invite.senderId, 'videoInvite', invite.roomId, invite.notificationId)">-->
    <!--<span>-->
    <!--<span><svg xmlns="http://www.w3.org/2000/svg" class="svg" version="1.1"  x="0px" y="0px" viewBox="0 0 37 33" xml:space="preserve" enable-background="new 0 0 37 33"><switch><foreignObject requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/" x="0" y="0" width="1" height="1"/><g><path d="M18.6 1C8.9 1 1 7.7 1 15.9c0 4 1.8 7.6 4.8 10.2l-1.6 6 8.4-2.2c1.9 0.6 3.9 0.9 6 0.9 9.7 0 17.6-6.7 17.6-14.9S28.3 1 18.6 1zM28.5 20.3c0 0.6-0.7 1-1.2 0.7L24 19.1v0.6c0 1.7-1.3 3-3 3h-8.8c-1.7 0-3-1.3-3-3v-6.7c0-1.7 1.3-3 3-3h8.8c1.7 0 3 1.3 3 3v0.6l3.4-1.9c0.5-0.3 1.2 0.1 1.2 0.7V20.3z"/></g></switch></svg></span>-->
    <!--{{ $t( 'shared.btn.chatVideo' ) }}-->
    <!--</span>-->
    <!--</button>-->
    <!--<button class="btn btn-primary" v-if="invite.inviteType === 'textInvite' || sex.isMan"-->
    <!--@click="acceptInvite(invite.senderId, 'textInvite', invite.roomId, invite.notificationId)">-->
    <!--<span>-->
    <!--<span><svg style="enable-background:new 0 0 487.7 487.7" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 487.7 487.7" version="1.1" y="0px" x="0px"><path d="m437 10.8h-386c-27.9 0-50.5 22.6-50.5 50.5v276c0 27.9 22.6 50.5 50.5 50.5h11.2v81.4c0 6.6 8 9.9 12.6 5.2l86.6-86.6h276c27.9 0 50.5-22.6 50.5-50.5v-276c0-26.5-23-49.2-51-49.2zm-281 215c-14.5 0-26.3-11.8-26.3-26.3s11.8-26.3 26.3-26.3 26.3 11.8 26.3 26.3-11 26-26 26zm88 0c-14.5 0-26.3-11.8-26.3-26.3s11.8-26.3 26.3-26.3c14.5 0 26.3 11.8 26.3 26.3s-12 26-26 26zm87 0c-14.5 0-26.3-11.8-26.3-26.3s11.8-26.3 26.3-26.3 26.3 11.8 26.3 26.3-11 26-26 26z"/></svg></span>-->
    <!--{{ $t( 'shared.btn.chatText' ) }}-->
    <!--</span>-->
    <!--</button>-->
    <!--</div>-->
    <!--<div class="life-indicator" :class="'line-' + invite.senderId"></div>-->
  </li>
</template>

<script>
import ProgressBar from 'progressbar.js';
import Config from '../../../assets/js/emoji/config.js'
import { EmojiPicker } from '../../../assets/js/emoji/emoji-picker.js';
import { MINI_PROFILE, WEBCAM, NOTIFICATION_SOUND } from '../../../mixins/utils';
import RbButton from '../../Button';


export default {
  progressLine: null,
  progressResponsive: null,
  progressCircle: null,
  components: {
    RbButton,
  },
  mixins: [MINI_PROFILE, WEBCAM, NOTIFICATION_SOUND],

  props: {
    invite: Object,
    sex: Object,
    showTime: Number,
    // playInviteAudio: Function,
  },
  data() {
    return {
      user: {
        avatar: {},
      },
    };
  },
  computed: {
    settings() {
      return this.$store.getters.settings;
    },
    config() {
      return this.$store.getters.config;
    },
    deviceWidth() {
      return this.$store.getters.deviceWidth;
    },
  },
  created() {
    // if (this.invite.sound && this.settings.sound !== 'off' && this.settings.sound_chat === 'on' && this.settings.sound_chat_notification === 'on') {
    //
    //   this.selectSound('chatInvite');
    // }
    this.askProfile(this.invite.senderId);
  },
  mounted() {
    // console.log('EmojiPicker.prototype', EmojiPicker.prototype);
    console.log('config', Config);

    EmojiPicker.prototype.generateEmojiIconSets('', Config)
    // console.log('messageFromShort(text)', messageFromShort('sdfasdfsadf'));
    /**
     * Проверка настроек пользователя (звук) и запуск звукового уведомления, если прошли проверку
     */
    // setTimeout(() => {
      // console.log('this.$refs.invite', this.$refs.invite);
      // console.log('this.$refs.inviteMsg', this.$refs.inviteMsg);
    // }, 3000)

    // if (!this.invite.hasChatSession) this.playInviteAudio();

    setTimeout(() => {
      this.removeInvite();
    }, this.showTime);

    this.$emit('updateLifeCycle', this.invite.senderId);

    this.initPrLine();

    this.initPrCircle();
  },
  methods: {
    /**
     * Принять инвайт в чат от другого пользователя
     * @param id {number}
     * @param type {string}
     * @param room {string}
     * @param notificationId {string}
     */
    acceptInvite(id, type, room, notificationId) {
      const w = window.outerWidth;
      const h = window.outerHeight - 60;
      console.log('id', id);
      console.log('type', type);
      console.log('room', room);
      console.log('notificationId', notificationId);
      window.open(
        `${this.config.chatURL}?id=${id}&token=${window.localStorage['access-token']}&type=${type}&r=${room}&n=${notificationId}`,
        'Chat',
        `toolbar=no,
					menubar=no, width=${w}, height=${h}, resizable=no, status=no`
      );

      /* var windowReference = window.open()
      windowReference.location = `${this.config.chatURL}?id=${id}&token=${window.localStorage['access-token']}&type=${type}&r=${room}&n=${notificationId}` */
    },
    /**
     * Отклонить инвайт и сообщить серверу об этом
     */
    removeInvite() {
      this.$socket.emit('invite_close', {
        token: window.localStorage['chat-token'],
        notificationId: this.invite.notificationId,
      });
      this.$store.commit('removeInvite', this.invite.notificationId);
    },
    /**
     * Получить отформатированное сообщение с учетом смайликом (из юникода)
     *
     * @param text {string}
     */
    messageFromShort(text) {
      console.log('Config', Config);
      // console.log('EmojiPicker.prototype.appendUnicodeAsImageToElement(this.$refs.invite, text)', EmojiPicker.prototype.appendUnicodeAsImageToElement(this.$refs.invite, text));
      // setTimeout(() => {
        // eslint-disable-next-line no-undef
        return EmojiPicker.prototype.appendUnicodeAsImageToElement(this.$refs.invite, text, Config);
      // }, 500)
    },
    /**
     * Получить информацию о профиле пользователя, приславшего инвайт
     *
     * @param id {number}
     */
    askProfile(id) {
      this.$http
        .post('v1/profile/profile', {
          access_token: window.localStorage['access-token'],
          target_user_id: id,
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.$set(this.invite, 'user', r.body.result);
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.cannotLoadProfile'),
                info: 'ask users profile',
              });
            }
          },
          () => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.cannotLoadProfile'),
              info: 'ask users profile',
            });
          }
        );
    },
    /**
     * Инициализировать прогрессбар-линию
     */
    initPrLine() {
      this.$set(this.invite, 'progressLine', null);

      this.invite.progressLine = new ProgressBar.Line(`.line-${this.invite.senderId}`, {
        strokeWidth: 2,
        easing: 'linear',
        duration: this.showTime,
        trailColor: '#fff',
        trailWidth: 2,
        svgStyle: { width: '100%', height: '100%', 'border-radius': '2px' },
        from: { color: '#FFEA82' },
        to: { color: '#05ca6b' },
        step(state, bar) {
          bar.path.setAttribute('stroke', state.color);
        },
      });
      this.invite.progressLine.set(1);
      this.invite.progressLine.animate(0);
    },
    /**
     * Инициализировать прогресс-бар для клиентки в респонзиве
     */
    initPrResp() {
      if (this.sex.isWoman) {
        this.$set(this.invite, 'progressResponsive', null);

        this.invite.progressResponsive = new ProgressBar.Line(
          `.responsive-${this.invite.senderId}`,
          {
            strokeWidth: 4,
            easing: 'linear',
            duration: this.showTime,
            trailColor: '#bebebe',
            trailWidth: 2,
            svgStyle: { width: '100%', height: '100%', 'border-radius': '2px' },
            from: { color: '#FFEA82' },
            to: { color: '#05ca6b' },
            step(state, bar) {
              bar.path.setAttribute('stroke', state.color);
            },
          }
        );
        this.invite.progressResponsive.set(1);
        this.invite.progressResponsive.animate(0);
      }
    },
    /**
     * Инициализировать прогрессбар для клиента в респонзиве
     */
    initPrCircle() {
      this.$set(this.invite, 'progressCircle', '');
      this.invite.progressCircle = new ProgressBar.Circle(`.circle-${this.invite.senderId}`, {
        strokeWidth: 5,
        easing: 'linear',
        duration: this.showTime,
        trailColor: '#fff',
        trailWidth: 4,
        svgStyle: {
          width: '100%',
          height: '100%',
          transform: {
            prefix: true,
            value: 'scaleX(-1)',
          },
        },
        from: { color: '#FFEA82' },
        to: { color: '#05ca6b' },
        step(state, bar) {
          bar.path.setAttribute('stroke', state.color);
        },
      });
      this.invite.progressCircle.set(1);
      this.invite.progressCircle.animate(0);
    },
    /**
     * Обработчик клика по аватарке. Либо перекинуть на профиль отправителя, либо принять инвайт (зависит от ширины дисплея)
     */
    avaClick() {
      if (this.deviceWidth > 991) {
        this.$router.push({
          name: 'profile',
          params: { id: this.invite.senderId },
        });
      } else {
        this.acceptInvite(
          this.invite.senderId,
          this.invite.inviteType,
          this.invite.roomId,
          this.invite.notificationId
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';
@import '../../../assets/scss/vars';

.invites {
  &__body {
    .uname {
      font-size: 14px;
      font-weight: 600;
      color: $white;
      margin-bottom: 10px;

      > span {
        font-size: 11px;
        font-weight: 400;
      }
    }

    .invite {
      &__sender {
        max-height: 60px;
        display: flex;
        margin-bottom: 15px;

        .webcam {
          position: absolute;
          z-index: 3;
          bottom: 0;
          right: 0;
          background: #ffffff;
          color: #000000;
          border-radius: 10px 10px 0 0;
          padding: 2px;
          font-size: 12px;
        }

        &_text {
          width: 100%;
          margin-left: 10px;
          padding: 10px;
          background: #ffffff;
          color: #000000;
          position: relative;

          p {
            -webkit-column-width: 400px;
            -moz-column-width: 400px;
            column-width: 400px;
            -webkit-column-count: 1;
            -moz-column-count: 1;
            column-count: 1;
            overflow: hidden;
            font-size: 12px;
            height: 100%;
            margin: 0;
          }

          &:after {
            content: '';
            border: 5px solid transparent;
            border-right: 5px solid #fff;
            display: block;
            position: absolute;
            left: -10px;
            top: 15px;
          }
        }
      }

      &__controls {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
      }

      &__img {
        flex: 0 0 60px;
        height: 60px;
        position: relative;

        .circle-indicator {
          position: relative;
          z-index: 1;
        }

        .user-avatar {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      &__text {
        flex: 1 1 auto;
        padding: 0 10px;
        margin-top: 2px;
        -webkit-column-width: 400px;
        -moz-column-width: 400px;
        column-width: 400px;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        overflow: hidden;

        a {
          color: $white;
          display: inline-block;
          margin-bottom: 5px;
          font-weight: 700;
          text-decoration: underline;
        }

        p {
          font-size: 14px;
        }
      }

      &__block-btn {
        flex: 0 0 40px;
        display: flex;
        align-items: center;

        .invite__btn {
          background: $red;
          border: 1px solid $red;
          font-size: 20px;
          padding: 5px 10px;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          color: $white;
          width: 38px;
          height: 38px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          cursor: pointer;
          transition: 0.3s;
          outline: none;

          &:hover {
            background: lighten($red, 5%);
          }

          &:focus {
            box-shadow: 0 0 0 2px $white;
          }
        }
      }
    }
  }
}

.invites {
  &--item {
    padding: 20px;
    line-height: 1;
    background: rgba(#000, 0.75);
    color: #ffffff;

    &.ml {
      .invites--item__info-resp {
        display: none;
      }
    }

    &.fml {
      .circle-indicator {
        display: none;
      }
    }

    &__info {
      font-weight: bold;
      margin-bottom: 10px;

      a {
        color: #ffffff;

        &:hover,
        &:focus,
        &:active {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }

    &__info-resp {
      color: #3e3f42;
      margin-bottom: 15px;
    }

    &__body {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      .circle-indicator {
        position: relative;
        z-index: 3;
      }

      .img-container {
        position: relative;
        flex: 0 0 60px;
        cursor: pointer;
      }

      img {
        max-width: 100%;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 2px;
        width: calc(100% - 4px);
        z-index: 1;
      }

      .webcam-icon {
        position: absolute;
        bottom: -4px;
        right: -7px;
      }

      .blockquote {
        margin-left: 15px;
        flex: 1 1;

        &--inner {
          position: relative;
          max-height: 80px;
          padding: 5px 10px;
          border-radius: 8px;
          overflow: hidden;
          color: #83858b;
          line-height: 1.77;
          background-color: #f1f1f1;
          word-break: break-all;
          /* Non standard for WebKit */
          word-break: break-word;
          hyphens: auto;

          &:before {
            content: '';
            position: absolute;
            top: 8px;
            right: 100%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 4px 4px 0;
            border-color: transparent #f1f1f1 transparent transparent;
          }
        }
      }
    }

    &__buttons {
      margin-bottom: 10px;
    }

    svg {
      height: 16px;
      width: 16px;
      fill: currentColor;
    }

    .btn {
      padding: 8px 10px;
      width: 45%;
      line-height: 1;
      font-size: 12px;
      font-weight: 300;

      &:disabled {
        opacity: 0.5 !important;

        .rbi {
          color: $white;
        }

        &:hover {
          &:after {
            height: 100%;
            width: 100%;
          }
        }
      }

      > span {
        text-align: left;
      }

      .rbi {
        margin-right: 0.5em;
        font-size: 16px;
        color: $red;
      }
    }

    .pseudo-button {
      position: absolute;
      top: calc(100% - 7px);
      left: 0;
      width: 100%;
      display: none;
      background: $brand-primary;
      align-items: center;
      justify-content: center;
      height: 36px;
      color: #fff;
      border-radius: 6px;
      background: linear-gradient(to bottom, #ffb34e 0%, #ff7f2e 100%);

      svg {
        margin-right: 5px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .invites {
    &__body {
      display: flex;
      justify-content: space-between;
      max-height: 50px;
      overflow: hidden;

      .invite {
        &__img {
          flex: 0 0 50px;
          height: 50px;
          position: relative;

          .circle-indicator {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
          }

          .user-avatar {
            width: calc(100% - 4px);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            position: absolute;
            left: 2px;
          }
        }

        &__text {
          flex: 1 1 auto;
          padding: 0 10px;
          margin-top: 2px;
          -webkit-column-width: 400px;
          -moz-column-width: 400px;
          column-width: 400px;
          -webkit-column-count: 1;
          -moz-column-count: 1;
          column-count: 1;
          overflow: hidden;

          a {
            color: $white;
            display: inline-block;
            margin-bottom: 5px;
            font-weight: 700;
            text-decoration: underline;
          }

          p {
            font-size: 14px;
          }
        }

        &__block-btn {
          flex: 0 0 40px;
          display: flex;
          align-items: center;

          .invite__btn {
            background: $red;
            border: 1px solid $red;
            font-size: 20px;
            padding: 5px 10px;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: $white;
            width: 38px;
            height: 38px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            cursor: pointer;
            transition: 0.3s;
            outline: none;

            &:hover {
              background: lighten($red, 5%);
            }

            &:focus {
              box-shadow: 0 0 0 2px $white;
            }
          }
        }
      }
    }
  }
  .invite__sender_text {
    display: flex;
    align-items: center;
  }
  .invites {
    &--item {
      background: rgba($dark, 0.9);
      margin-bottom: 0;

      &.fml {
        padding: 10px;
        margin-bottom: 10px;
      }

      &.ml {
        padding: 5px;

        .img-container {
          height: 40px;
          width: 40px;
          flex: 0 0 40px;

          &:before,
          &:after {
            display: none;
          }

          .circle-indicator {
            height: 40px;
            width: 40px;
          }

          img {
            position: absolute;
            top: 2px;
            left: 2px;
            height: 36px;
            width: 36px;
            border-radius: 50%;
          }
        }

        .webcam-icon {
          display: none;
        }

        .blockquote {
          display: none;
        }
      }

      &.fml {
        .pseudo-button {
          display: flex;
        }
      }

      .blockquote {
        &--inner {
          margin-bottom: 15px;
        }
      }

      &__body {
        margin-bottom: 0;
      }

      &__buttons {
        display: none;
      }

      .life-indicator {
        display: none;
      }
    }
  }
}
</style>
