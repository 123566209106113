<template>
  <transition name="modal">
    <div
      v-show="modal.open"
      class="vue-modal modal-mask credits-modal"
      @click="closeModal($event.target)"
    >
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container credits-modal--container">
          <button type="button" class="close" @click="closeModal(false)">
            <span class="rbi rbi-close" />
          </button>
          <div class="modal-body credits-modal--body">
            <h4 class="modal-title">
              {{ $t('creditsModal.heading') }}
            </h4>
            <p class="text-muted">
              {{ $t('creditsModal.body') }}
            </p>
            <router-link
              :to="{ name: 'add-credits' }"
              target="_blank"
              class="btn btn-red"
              @click.native="closeModal(false)"
            >
              <span>{{ $t('creditsModal.buy') }}</span>
              <span class="rbi rbi-credit-card" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    modal() {
      return this.$store.getters.lowCreditsModal;
    },
  },
  methods: {
    /**
     * закрыть модалку
     *
     *
     * @param e {object} event
     */
    closeModal(e) {
      // eslint-disable-next-line no-undef
      if (!$(e).closest('.mail-modal--body').length || !e) {
        this.$store.commit('updateLowCreditModal', {
          open: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credits-modal {
  &--container {
    width: 320px;
    position: relative;
  }

  h4 {
    font-weight: bold;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &--body {
    text-align: center;
    padding: 20px 30px;
    p {
      margin: 20px 0;
    }
  }

  .btn {
    .rbi {
      margin-left: 0.5em;
    }
  }
}
</style>
