<template>
  <aside v-show="invites.length > 0" class="invites--wrap ml" :class="{ open: open }">
    <div class="invites--box dis-row">
      <div class="dis-cell">
        <div ref="simplebar" class="simplebar">
          <ul class="list-unstyled inv">
            <app-invite
              v-for="invite in invites"
              :key="invite.params.notificationId"
              :invite="invite.params"
              :sex="sex"
              :show-time="inviteShowTime"
              @updateLifeCycle="updateLifeCycle"
              :play-invite-audio="playInviteSound"
            >

              <div slot="line" :class="'aline-' + invite.params.senderId" />
            </app-invite>
          </ul>
        </div>
      </div>
    </div>
    <div class="dis-row invites--btn__wrap">
      <div class="invites--btn set-flex" @click="openInvites">
        <span class="set-flex invites--btn__text">
          <p class="visible-lg">{{ $t('shared.btn.invitations') }}</p>
          <span class="count">{{ invites.length }}</span>
        </span>
        <span class="icon d-none d-lg-block">
          <span v-show="open" class="rbi rbi-down-arrow" />
          <span v-show="!open" class="rbi rbi-top-arrow" />
        </span>
        <div class="d-flex d-lg-none icon-responsive">
          <div class="icon-responsive--circle circle-base" />
          <div class="icon-responsive--static">
            <span class="rbi rbi-chat" />
          </div>
          <span class="icon-responsive--label">{{ invites.length }}</span>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import SimpleBar from 'simplebar';
import ProgressBar from 'progressbar.js';
import Invite from './chatInvites/Invite.vue';
import { NOTIFICATION_SOUND } from '../../mixins/utils';

export default {
  scroll: null,
  progressCircle: null,
  components: {
    appInvite: Invite,
  },
  mixins: [NOTIFICATION_SOUND],
  data() {
    return {
      open: true,
      inviteAudio: null,
    };
  },
  computed: {
    settings() {
      return this.$store.getters.settings;
    },
    /**
     * Получить инвайты для пользователя в зависимости от пола
     * @return {*}
     */
    invites() {
      return this.$store.getters.invites;
    },
    /**
     * Получить длину массива с приглашениями
     */
    invitesLength() {
      return this.invites.length;
    },
    sex() {
      return this.$store.getters.sex;
    },
    inviteShowTime() {
      return this.$store.getters.config.lifeTimes.invite;
    },
  },
  watch: {
    /**
     * Открываем список приглашений, когда они закончились
     * Чтобы, в случае появления нового инвайта список был открыт
     * @param val {array}
     */
    invites(val) {
      if (val.length === 0) {
        this.open = true;
      }
    },

    /**
     * Обновить состояние скроллбара при изменении кол-ва инвайтов
     */
    invitesLength() {
      if (!this.scroll) {
        this.scroll.getContentElement();
        this.scroll.recalculate();
      }
    },
  },
  mounted() {
    this.inviteAudio = new Audio(this.selectSound('chatInvite'));
    /**
     * Инициализация скроллбара и прогрессбара
     */
    this.scroll = new SimpleBar(this.$refs.simplebar);
    this.progressCircle = new ProgressBar.Circle('.circle-base', {
      strokeWidth: 5,
      easing: 'linear',
      duration: this.inviteShowTime,
      trailColor: '#fff',
      trailWidth: 4,
      svgStyle: {
        width: '100%',
        height: '100%',
        transform: {
          prefix: true,
          value: 'scaleX(-1)',
        },
      },
      from: { color: '#FFEA82' },
      to: { color: '#05ca6b' },
      step(state, bar) {
        bar.path.setAttribute('stroke', state.color);
      },
    });
    this.progressCircle.set(1);
  },
  methods: {
    /**
     * Проиграть звук инвайта
     */
    playInviteSound() {
      if (this.settings && this.settings.sound_chat_notification === 'on') {
        this.inviteAudio.play();
      }
    },
    /**
     * Спрятать/показать инвайты в чат
     */
    openInvites() {
      this.open = !this.open;
    },

    /**
     * Обновить время жизни прогрессбара
     * @param id
     */
    updateLifeCycle() {
      this.progressCircle.set(1);
      this.progressCircle.animate(0);
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/variables';
@import '../../assets/scss/vars';

.touch {
  .invites {
    &--wrap {
      .simplebar-scroll-content {
        &::-webkit-scrollbar {
          width: 0;
        }
      }
    }
  }
}

.inv {
  margin-bottom: 0;
  border-top: 5px solid $red;
}

.invites {
  &--wrap {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 300px;
    z-index: 100;
    &.open {
      .invites--box {
        padding-bottom: 40px;
        display: block;
      }

      .invites--btn__wrap {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
      }

      .invites--box,
      .dis-cell,
      .simplebar {
        height: 100%;
      }
    }

    .simplebar-scroll-content {
      overflow-x: hidden;
      max-height: 100vh;
      padding-left: 0 !important;
      padding-right: 15px !important;

      &::-webkit-scrollbar {
        width: 15px;
      }
    }

    .simplebar-track.horizontal {
      display: none;
    }

    .blockquote {
      .emojione {
        width: 16px;
        height: 16px;
      }
    }
  }

  &--box {
    display: none;
  }

  &--btn {
    height: 40px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    color: #fff;
    line-height: 1;
    pointer-events: all;
    cursor: pointer;
    background-color: $dark;
    user-select: none;

    &__text {
      align-items: center;
    }

    svg {
      height: 16px;
      width: 16px;
      fill: currentColor;
    }

    p + span {
      margin-left: 5px;
    }

    .count {
      display: block;
      height: 18px;
      width: 18px;
      text-align: center;
      padding: 3px 0;
      border-radius: 50%;
      background-color: $red;
      font-size: 12px;
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
}

@media only screen and (max-width: 991px) {
  .invites {
    &--wrap {
      width: 60px;
      &.open {
        &.fml {
          width: 320px;

          .invites--btn {
            justify-content: flex-end;
          }

          .icon-responsive {
            flex: 0 0 30px;
          }
        }
      }
    }

    &--btn {
      background-color: $dark;
      color: #bababa;

      &:before {
        display: none !important;
      }

      &__text {
        display: none;
      }

      .icon-responsive {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &--circle {
          height: 30px;
          width: 30px;
        }

        &--label {
          position: absolute;
          top: -3px;
          right: -3px;
          padding: 2px 3px;
          color: #fff;
          background-color: $brand-primary;
          border-radius: 7px;
          height: 15px;
          min-width: 15px;
          display: flex;
          align-content: center;
          justify-content: center;
          font-size: 11px;
        }

        &--static {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          line-height: 0;
        }
      }
    }
  }
}
</style>
