<template>
  <transition v-if="modal.open && sex.isMan" name="modal">
    <div class="vue-modal modal-mask photos-modal">
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container photos-modal--body">
          <div class="photos-modal--header set-flex flex-wrap">
            <h4 class="modal-title">
              {{ $t('shared.common.allPhotos') }}
            </h4>
            <form ref="upload-form" class="set-flex flex-wrap">
              <input
                ref="ava-uploader"
                :disabled="uploading"
                accept="image/*"
                name="image"
                type="file"
                @change="getPhoto($event)"
              />
              <template v-if="!fromCamera">
                <rb-button v-if="!isIOS" class="upload mr-4" @click.prevent.native="startup">
                  <span class="rbi rbi-camera" /> upload From Camera
                </rb-button>
                <rb-button :disabled="uploading" class="upload" @click.prevent.native="openPick">
                  <template v-if="!uploading">
                    <span class="rbi rbi-upload" />
                    <span>{{ $t('profile.uploadAvaDevice') }}</span>
                  </template>
                  <template v-else>
                    <i class="fa fa-refresh fa-spin fa-fw" />
                    {{ $t('profile.uploading') }}
                  </template>
                </rb-button>
              </template>
              <button class="close" type="button" @click="closeModal">
                <span class="rbi rbi-close" />
              </button>
            </form>
          </div>
          <div class="photos-modal-body">
            <div class="warning-comment">
              Using photos containing nudity is prohibited on our website. <br />
              Failure to comply may result in loss of membership
            </div>
            <template v-if="fromCamera">
              <div class="webcam">
                <div class="camera">
                  <img v-show="imgSrc" ref="image" />
                  <video v-show="!imgSrc" ref="video" class="" />
                  <canvas ref="canvas" />
                  <div class="control">
                    <rb-button class="btn-outline-red btn-square" @click.native="offCamera">
                      <span class="rbi rbi-close" />
                    </rb-button>
                    <rb-button v-if="!imgSrc" class="btn-red" @click.native="takePicture">
                      Take Photo
                    </rb-button>
                    <rb-button v-if="imgSrc" class="btn-red btn-square" @click.native="saveImg">
                      <span class="rbi rbi-check" />
                    </rb-button>
                    <span v-else />
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-show="showPreloader || uploading" class="status-wrap">
                <div class="loader center-block" />
                <p>{{ uploadStatus }}</p>
                <rb-button v-if="previousRequest" class="mt-2" @click.native="cancelUpload">
                  cancel upload
                </rb-button>
              </div>

              <div v-show="!uploading" class="photos">
                <div
                  v-for="photo in photos"
                  :key="photo.photo_id"
                  class="photos--item photos-modal--photo"
                >
                  <div class="img-wrap">
                    <div v-background="{ img: photo._photo, imgClass: 'obj-fit' }">
                      <img
                        :alt="userName + $t('shared.alts.photo')"
                        :src="photo._photo"
                        class="obj-fit"
                      />
                    </div>
                    <div class="shadow">
                      <button class="btn" type="button" @click="setAvatar(photo.id)">
                        {{ $t('gallery.setAva') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!photos.length && !showPreloader" class="no-photo">
                <h2 v-if="sex.isWoman">В приватном альбоме нет фото</h2>
                <h2 v-else>You have no photo</h2>
              </div>
              <load-more
                v-show="showLimit < count && !uploading"
                :counter="defaultLimit"
                @click.native="loadMore"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { PHOTO_SRC, PHOTOS_UPLOAD } from '../../../mixins/utils';
import loadMore from '../../Authorized/parts/LoadMore.vue';
import RbButton from '../../Button';

export default {
  components: {
    loadMore,
    RbButton,
  },
  mixins: [PHOTO_SRC, PHOTOS_UPLOAD],
  data() {
    return {
      refVideo: null,
      refCanvas: null,
      refImage: null,
      globalStream: null,
      imgSrc: null,
      fromCamera: false,
      streaming: false,
      width: 1024,
      height: 0,
      showPreloader: true,
      storedPhotos: [],
      showLimit: 30,
      defaultLimit: 30,
      count: 0,
      file: {},
      uploading: false,
      uploadStatus: '',
    };
  },
  computed: {
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    modal() {
      return this.$store.getters.uploadPhotosModal;
    },
    open() {
      return this.modal.open;
    },
    sex() {
      return this.$store.getters.sex;
    },
    /**
     * отображение ограниченного кол-ва показываемых фотографий
     *
     * @return {Array}
     */
    photos() {
      return this.storedPhotos.slice(0, this.showLimit);
    },
    userName() {
      return this.$store.getters.user.name;
    },
  },
  watch: {
    /**
     * В зависимости от статуса показа модалки либо загружаем фото, либо очищает компонент
     *
     * @param val
     */
    open(val) {
      val ? this.loadPhotos() : this.clearData();
    },
  },
  methods: {
    /**
     * Получает файл с input[type=file]  и начинает обработку с валидацией
     *
     * @param e {object} event
     */
    startup() {
      this.fromCamera = true;
      this.$nextTick(() => {
        this.refVideo = this.$refs.video;
        this.refImage = this.$refs.image;
        this.refCanvas = this.$refs.canvas;

        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
            this.globalStream = this.refVideo.srcObject = stream;
            this.refVideo.play();
          })
          .catch((err) => {
            console.log(`An error occurred: ${err}`);
          });

        this.refVideo.addEventListener(
          'canplay',
          () => {
            if (!this.streaming) {
              this.height = this.refVideo.videoHeight / (this.refVideo.videoWidth / this.width);

              // Firefox currently has a bug where the height can't be read from
              // the video, so we will make assumptions if this happens.

              if (isNaN(this.height)) {
                this.height = this.width / (4 / 3);
              }

              this.refCanvas.setAttribute('width', this.width);
              this.refCanvas.setAttribute('height', this.height);
              this.streaming = true;
            }
          },
          false
        );
        this.clearPhoto();
      });
    },
    clearPhoto() {
      const context = this.refCanvas.getContext('2d');
      context.fillStyle = '#AAA';
      context.fillRect(0, 0, this.refCanvas.width, this.refCanvas.height);

      const data = this.refCanvas.toDataURL('image/png');
      this.refImage.setAttribute('src', data);
    },

    // Capture a photo by fetching the current contents of the video
    // and drawing it into a canvas, then converting that to a PNG
    // format data URL. By drawing it on an offscreen canvas and then
    // drawing that to the screen, we can change its size and/or apply
    // other changes before drawing it.

    takePicture() {
      const context = this.refCanvas.getContext('2d');
      if (this.width && this.height) {
        this.refCanvas.width = this.width;
        this.refCanvas.height = this.height;
        context.drawImage(this.refVideo, 0, 0, this.width, this.height);

        const data = this.refCanvas.toDataURL('image/png');

        this.refImage.setAttribute('src', data);

        this.imgSrc = this.refCanvas;
      } else {
        this.clearPhoto();
      }
    },
    saveImg() {
      this.uploading = true;
      this.uploadStatus = this.$t('uploadModal.status.uploading');
      this.fromCamera = false;

      const blob = this.reduceImage(this.imgSrc, this.constants.startQuality);
      this.sendBlob(blob, 'public')
        .then((sentData) => {
          this.loadPhotos(true);
          this.setAvatar(sentData.photo_id);
        })
        .catch((e) => {
          this.showPreloader = false;
          this.uploading = false;
          if (e.type !== 'cancel') {
            this.$store.commit('addAlert', {
              type: 'error',
              text: e.description,
            });
          }
        });
      this.offCamera();
    },

    offCamera() {
      if (this.imgSrc) {
        this.imgSrc = null;
        return;
      }
      if (this.refVideo) {
        this.refVideo.pause();
        this.refVideo.srcObject = null;
      }
      if (this.globalStream) {
        this.globalStream.getVideoTracks()[0].stop();
        this.globalStream = null;
      }
      this.fromCamera = false;
      this.imgSrc = null;
    },
    getPhoto(e) {
      if (!this.uploading) {
        const file = e.target.files[0];
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          this.file = {
            _file: file,
          };
          if (this.$refs['upload-form']) {
            this.checkImage(this.file._file)
              .then((r) => {
                if (r) this.startTransformation(this.file);
                this.$refs['upload-form'].reset();
              })
              .catch((e) => {
                this.$refs['upload-form'].reset();
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: e.description,
                });
              });
          }
        } else {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.incorrectImgExt'),
          });
          this.$refs['upload-form'].reset();
        }
      }
    },
    /**
     * начало трансформации файла, создания блоба, загрузки фото и установки как аватар
     *
     * @param file {object}
     */
    startTransformation(file) {
      this.uploading = true;
      this.uploadStatus = this.$t('uploadModal.status.uploading');
      const img = document.createElement('img');
      const arr = [];
      const self = this;
      img.src = URL.createObjectURL(file._file);
      arr.push(img);
      img.onload = (e) => {
        this.makeCanvas(e)
          .then((r) => {
            const blob = self.reduceImage(r.canvas, self.constants.startQuality);
            self
              .sendBlob(blob, 'public')
              .then((sentData) => {
                self.loadPhotos(true);
                self.setAvatar(sentData.photo_id);
              })
              .catch((e) => {
                this.showPreloader = false;
                this.uploading = false;
                if (e.type !== 'cancel') {
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: e.description,
                  });
                }
              });
          })
          .catch((e) => {
            this.uploading = false;
            this.$store.commit('addAlert', {
              type: 'error',
              text: e.description,
            });
          });
      };
    },
    /**
     * Отправка запроса на установление фотографии как аватар
     *
     * @param photo_id {number}
     */
    setAvatar(photo_id) {
      this.uploadStatus = this.$t('uploadModal.status.settingAva');
      this.$http
        .post('v1/photo/avatar-set', {
          access_token: window.localStorage['access-token'],
          photo_id,
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.$emit('updatePage');

              this.$store.commit('addAlert', {
                type: 'success',
                text: this.$t('alerts.avatarSet'),
              });
              this.uploading = false;
              this.closeModal();
            } else {
              this.uploading = false;
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
              });
            }
            this.uploadStatus = '';
          },
          () => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
            });
            this.uploadStatus = '';
            this.uploading = false;
          }
        );
    },
    /**
     * очистить состояние компонента
     */
    clearData() {
      this.storedPhotos = [];
      this.count = 0;
      this.showLimit = this.defaultLimit;
      this.fromCamera = false;
      this.refVideo = null;
      this.refCanvas = null;
      this.refImage = null;
      this.globalStream = null;
      this.imgSrc = null;
      this.fromCamera = false;
      this.streaming = false;
      this.width = 1024;
      this.height = 0;
    },

    openPick() {
      this.$refs['ava-uploader'].click();
    },
    /**
     * показать больше фотографий
     */
    loadMore() {
      this.showLimit += this.defaultLimit;
    },
    /**
     * загрузить фото пользователя с сервера
     *
     * @param hidePreloader {boolean} - нужно ли прятать прелоадер
     */
    loadPhotos(hidePreloader) {
      if (!hidePreloader) this.showPreloader = true;

      this.$http
        .post('v1/photo/my-album-photo', {
          access_token: window.localStorage['access-token'],
          limit: 0,
          offset: 0,
          album_type: 'public',
          sort: 'DESC',
        })
        .then((response) => {
          this.count = response.body.count;
          this.storedPhotos = response.body.result.map((item) => ({
            _photo: item.img_preview,
            id: item.photo_id,
          }));
          this.showPreloader = false;
        });
    },
    /**
     * закрыть модалку
     */
    closeModal() {
      if (!this.uploading) {
        this.$store.commit('updateUploadPhotosModal', {
          open: false,
          skipToggle: this.$store.getters.questionnaireModal.open,
        });
        this.showPreloader = true;
        this.uploadStatus = '';
        this.offCamera();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';
@import '../../../assets/scss/vars';

.no-photo {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 30px;
}

.photos-modal {
  .close {
    position: absolute;
    top: 5px;
    right: 0;
  }

  .upload {
    font-size: 12px;
    padding: 10px 15px;
    background: $red;
    color: $white;
    border-color: $red;

    &:after {
      border-color: $red;
    }

    .rbi {
      margin-right: 10px;
    }
  }

  .alert {
    margin-top: 10px;
  }

  &--header {
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-right: 35px;

    svg {
      height: 16px;
      width: 16px;
      fill: currentColor;
    }

    .btn + .close {
      margin-left: 25px;
    }

    .icon + span {
      margin-left: 10px;
    }

    .btn-primary {
      display: flex;
      align-items: center;
      line-height: 1;
      font-size: 13px;
      padding: 10px 20px;
    }

    input {
      display: none;
    }
  }

  .status-wrap {
    margin-top: 15px;
    text-align: center;
    color: #8a8a8a;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    div + p {
      margin-top: 15px;
    }
  }

  &--body {
    width: 920px;
    padding: 25px;

    .photos {
      display: flex;
      margin: 0 -3px;

      &--item {
        position: relative;
        margin-top: 20px;
        cursor: pointer;
        user-select: none;
        width: 20%;
        padding: 0 3px;

        .img-wrap {
          position: relative;
          padding-bottom: 88%;

          &:after {
            position: absolute;
            bottom: -3px;
            width: 25%;
            height: 3px;
            left: 0;
            content: '';
            display: block;
            background: $red;
          }

          .obj-fit {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }

        &:hover {
          .shadow {
            opacity: 1;
          }
        }

        .shadow {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.25s ease;
          background-color: rgba(#000, 0.4);

          .btn {
            border-color: $white;
            background: $white;
            font-size: 12px;
            padding: 10px 15px;

            &:after {
              border-color: $white;
            }
          }
        }
      }
    }
  }
}

.webcam {
  max-width: 720px;
  width: 100%;
  position: relative;
  margin: 0 auto;

  video,
  img {
    width: 100%;
    display: block;
  }

  canvas {
    display: none;
  }

  .control {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;

    .btn {
      min-height: 50px;
      padding: 10px;

      &-square {
        width: 50px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .photos-modal {
    &--body,
    .vue-modal--wrapper {
      width: 100%;
    }

    &--body {
      .photos {
        @include customGrid(100%, 4, 24%, photos--item);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .webcam {
    .control {
      .btn {
        min-height: 35px;
        padding: 5px;
        font-size: 14px !important;

        &-square {
          width: 35px;
        }
      }
    }
  }
  .photos-modal {
    &--body {
      padding: 15px;

      .photos {
        @include customGrid(100%, 3, 32%, photos--item);
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .photos-modal {
    &--body {
      .photos {
        @include customGrid(100%, 2, 49%, photos--item);
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .photos-modal {
    &--header {
      .upload + .upload {
        margin-top: 10px;
      }
    }
  }
}

.mt-2 {
  margin-top: 8px !important;
}

.mr-4 {
  margin-right: 8px;
}
</style>
