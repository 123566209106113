<template>
  <span class="zodiac-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M27.955 19.752c-.087.078-7.05 6.217-14.847 6.047.944-1.232 1.53-2.757 1.53-4.43 0-4.034-3.283-7.316-7.32-7.316C3.284 14.054 0 17.336 0 21.372c0 3.6 2.614 6.582 6.04 7.19 2.52 1.15 5.032 1.63 7.44 1.63 9.28 0 16.985-6.835 17.392-7.203.894-.808.962-2.182.16-3.073-.807-.897-2.182-.967-3.077-.165zM7.32 24.334c-1.635 0-2.963-1.326-2.963-2.963 0-1.632 1.328-2.96 2.962-2.96 1.633 0 2.96 1.328 2.96 2.96 0 1.637-1.327 2.964-2.96 2.964z" />
      <path d="M4.045 12.247c.087-.077 7.05-6.215 14.848-6.045-.943 1.23-1.53 2.755-1.53 4.426 0 4.036 3.282 7.318 7.32 7.318 4.034 0 7.317-3.282 7.317-7.318 0-3.598-2.615-6.58-6.04-7.188-2.52-1.153-5.032-1.633-7.44-1.633-9.28 0-16.985 6.836-17.392 7.204-.895.808-.962 2.182-.16 3.072.808.9 2.183.97 3.077.165zm20.637-4.58c1.633 0 2.96 1.326 2.96 2.96 0 1.636-1.327 2.963-2.96 2.963-1.635 0-2.963-1.327-2.963-2.962 0-1.634 1.327-2.962 2.962-2.962z" />
    </svg>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style>
</style>
