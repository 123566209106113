<template>
  <span class="zodiac-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.001"
      height="32"
      viewBox="0 0 32.001 32"
    >
      <path d="M31.983 2.058c-.01-.243-.05-.49-.146-.718-.104-.25-.253-.474-.44-.664-.003-.006-.005-.012-.01-.017-.192-.195-.424-.348-.68-.454-.254-.106-.523-.163-.797-.163H18.748c-1.156 0-2.094.937-2.094 2.093 0 1.157.938 2.093 2.093 2.093h6.1l-10 9.966-3.592-3.73c-.802-.83-2.128-.855-2.96-.052-.832.8-.857 2.125-.055 2.96l3.642 3.776L.615 28.383c-.818.814-.82 2.14-.004 2.96.41.41.945.614 1.482.614.534 0 1.07-.204 1.478-.61l11.215-11.182 4.11 4.265c.41.426.957.64 1.508.64.52 0 1.047-.19 1.45-.585.833-.8.857-2.127.057-2.96l-4.158-4.314L27.814 7.18v5.945c0 1.157.938 2.094 2.096 2.094 1.154 0 2.092-.938 2.092-2.095V2.135c-.002-.028-.017-.05-.02-.077z" />
    </svg>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style>
</style>
