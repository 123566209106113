<template>
  <div class="stepper">
    <div class="stepper_items">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  props: [
    'value',
  ],
  computed: {
    currentStep() {
      return `${this.value}`;
    },
  },
};
</script>
