<template>
  <div class="alerts--box">
    <a-template
      v-for="alert in alerts"
      :key="alert.id"
      :alert="alert"
      :class="alert.type ? `alerts--${alert.type} ` : ''"
      class="alerts--base set-flex"
    />
  </div>
</template>

<script>

import Template from './alerts/_template.vue';

export default {
  components: {
    aTemplate: Template,
  },
  data() {
    return {};
  },
  computed: {
    alerts() {
      return this.$store.getters.alerts;
    },
  },
  methods: {},
};
</script>

<style lang="scss">

  @import "../../assets/scss/variables";
  @import "../../assets/scss/vars";

  .alerts {

    &--box {
      z-index: 9999;
      position: fixed;
      top: 0;
      left: 0;
    }

    &--success {
      .rbi {
        color: $green;
      }
    }

    &--error {
      .rbi {
        color: $red;
      }
    }

    &--info {
      .rbi {
        color: $yellow;
      }
    }

    &--base {
      align-items: center;
      color: #ffffff;
      margin-top: 5px;
      margin-left: 5px;
      padding: 15px 20px;
      width: auto;
      cursor: pointer;
      line-height: 1;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: zoomInDown;
      background-color: $dark;

      .rbi {
        font-size: 18px;
        margin-right: 10px;
        font-weight: 700;
      }
    }

    @keyframes zoomInDown {
      from {
        opacity: 0;
        transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
      }

      60% {
        opacity: 1;
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
      }
    }
  }

</style>
