<template>
  <div id="appearance" class="responsive-box info-block">
    <div class="box box-grey">
      <div class="text-overflow contain-edit">
        <h2 class="title-profile edit-caption">
          {{ $t('profile.appearance') }}
        </h2>
        <router-link
          v-if="sex.isMan && viewedUser.isMan"
          :to="{ name: 'edit-appearance' }"
          tag="button"
          type="button"
          class="contain-edit__btn"
        >
          <span>
            <span class="rbi rbi-edit" />
            {{ $t('profile.edit') }}
          </span>
        </router-link>
      </div>
      <div class="info-box column">
        <div class="item">
          <div class="caption">
            {{ $t('profile.hairColor') }}: <span>{{ displayedHair }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t('profile.eyesColor') }}:
            <span class="capitalize">{{ noData(profile.eye_color) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t('profile.bodyType') }}: <span class="capitalize">{{ noData(profile.body) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t('profile.height') }}:
            <span class="capitalize">{{ displayedHeight(profile.height) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="caption">
            {{ $t('profile.weight') }}:
            <span class="capitalize">{{ displayedWeight(profile.weight) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box box-light-grey">
      <div class="text-overflow contain-edit">
        <h2
          class="title-profile edit-caption"
          v-text="viewedUser.isMan ? $t('profile.perfWoman') : $t('profile.perfMan')"
        />
        <router-link
          v-if="sex.isMan && viewedUser.isMan"
          tag="button"
          :to="{ name: 'edit-prefers' }"
          type="button"
          class="contain-edit__btn"
        >
          <span>
            <span class="rbi rbi-edit" />
            {{ $t('profile.edit') }}
          </span>
        </router-link>
      </div>
      <div class="info-box column">
        <div v-if="profile.about_man_age_from && profile.about_man_age_to" class="item">
          <div class="caption">
            {{ $t('shared.common.age') }}:
            <span>{{
              displayedRangeData(profile.about_man_age_from, profile.about_man_age_to)
            }}</span>
          </div>
        </div>
        <div v-if="viewedUser.isMan" class="item">
          <div class="caption">
            {{ $t('shared.common.age') }}:
            <span
              v-text="displayedRangeData(profile.about_woman_age_from, profile.about_woman_age_to)"
            />
          </div>
        </div>
        <div v-if="viewedUser.isMan" class="item">
          <div class="caption">
            {{ $t('profile.hairColor') }}:
            <span class="capitalize">{{ noData(profile.about_woman_hair_color) }}</span>
          </div>
        </div>
        <div v-if="viewedUser.isMan" class="item">
          <div class="caption">
            {{ $t('profile.eyesColor') }}:
            <span class="capitalize">{{ noData(profile.about_woman_eye_color) }}</span>
          </div>
        </div>
        <div v-if="viewedUser.isMan" class="item">
          <div class="caption">
            {{ $t('profile.bodyType') }}:
            <span class="capitalize">{{ noData(profile.about_woman_body) }}</span>
          </div>
        </div>
        <div v-if="viewedUser.isMan" class="item">
          <div class="caption">
            {{ $t('profile.height') }}:
            <span class="capitalize">{{
              displayedRangeData(
                displayedHeight(profile.about_woman_growth_from),
                displayedHeight(profile.about_woman_growth_to)
              )
            }}</span>
          </div>
        </div>
        <div v-if="viewedUser.isMan" class="item">
          <div class="caption">
            {{ $t('profile.weight') }}:
            <span class="capitalize">{{
              displayedRangeData(
                displayedWeight(profile.about_woman_weight_from),
                displayedWeight(profile.about_woman_weight_to)
              )
            }}</span>
          </div>
        </div>
        <div v-if="viewedUser.isMan" class="item">
          <div class="caption">
            {{ $t('profile.lvlEng') }}:
            <span class="capitalize">{{ noData(profile.about_woman_english_level) }}</span>
          </div>
        </div>
        <div v-if="viewedUser.isMan" class="item">
          <div class="caption">
            {{ $t('profile.children') }}:
            <span class="capitalize">{{
              noData(profile.about_woman_children) === 'I have no children'
                ? 'No children'
                : noData(profile.about_woman_children)
            }}</span>
          </div>
        </div>
        <div class="item wrap-text">
          <div
            class="caption"
            v-html="
              viewedUser.isMan
                ? $t('profile.womanType') +
                  ':' +
                  '<span>' +
                  noData(profile.about_woman_text) +
                  '</span>'
                : $t('profile.manType') + ':' + '<span>' + noData(profile.about_man) + '</span>'
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NO_DATA } from '../../../mixins/utils';
import { MEASURE_CALCULATIONS } from '../../../mixins/types/profile';

export default {
  mixins: [NO_DATA, MEASURE_CALCULATIONS],
  props: {
    user: Object,
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    sex() {
      return this.$store.getters.sex;
    },
    displayedRangeHeight() {
      if (!this.profile.about_woman_growth_from) return this.$t('profile.noFill');
      return `${this.displayedHeight(
        this.profile.about_woman_growth_from
      )} - ${this.displayedHeight(this.profile.about_woman_growth_to)}`;
    },
    displayedRangeWeight() {
      if (!this.profile.about_woman_growth_from) return this.$t('profile.noFill');
      return `${this.displayedWeight(
        this.profile.about_woman_weight_from
      )} - ${this.displayedWeight(this.profile.about_woman_weight_to)}`;
    },
    displayedHair() {
      const tmp = this.noData(this.profile.hair_color);
      if (this.viewedUser.isMan) {
        return tmp === 'blonde' ? 'blond' : tmp;
      }

      return tmp;
    },
    isAuthorized() {
      return !!window.localStorage['access-token'];
    },
    viewedUser() {
      const tmp = this.user.user_type === 'man';
      return {
        isMan: tmp,
        isWoman: !tmp,
      };
    },
  },
  methods: {
    /**
     * Возвращает данные для отображение веса
     *
     * @param v {string}
     */
    displayedWeight(v) {
      if (!v) return this.$t('profile.noFill');
      if (this.sex.isWoman) {
        return this.$t('shared.utils.kg', [Math.round(v)]);
      }
      return this.$t('shared.utils.lbs', [Math.round(this.kgToLbs(v))]);
    },
    /**
     * Возвращает данные для отображение роста
     *
     * @param v {string}
     */
    displayedHeight(v) {
      if (!v) return this.$t('profile.noFill');

      if (this.sex.isWoman) {
        return this.$t('shared.utils.cm', [Math.round(v)]);
      }
      return `${this.cmToFeetInch(v).feet}' ${this.cmToFeetInch(v).inches}"`;
    },
    /**
     * Возвращает данные для отображение промежутка возвраста
     *
     * @param v {string}
     */
    displayedRangeData(a, b) {
      if (a) {
        return a === b ? a : `${a} - ${b}`;
      }
      return this.noData(a);
    },
  },
};
</script>

<style></style>
