<template>
  <component
    :is="alert.type"
    @click.native="removeAlert"
  >
    <p slot="text">
      {{ alert.text }}
    </p>
  </component>
</template>

<script>

import success from './success.vue';
import error from './error.vue';
import info from './info.vue';

export default {
  components: {
    success,
    error,
    info,
  },
  props: {
    alert: Object,
  },
  computed: {
    lifeTime() {
      return this.$store.getters.config.lifeTimes.alert;
    },
  },
  mounted() {
    setTimeout(() => {
      this.removeAlert();
    }, this.lifeTime);
  },
  methods: {
    /**
			 * Удалить экземпляр уведомления
			 */
    removeAlert() {
      this.$store.commit('removeAlert', this.alert.id);
    },
  },
};
</script>

<style lang="scss">

</style>
