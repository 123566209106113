<template>
  <transition v-if="modalOpened" name="modal">
    <div class="vue-modal questionnaire-modal modal-mask">
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container modal-dialog">
          <transition v-if="closed" name="modal">
            <div class="modal-close-dialog">
              <div class="modal-close-body">
                <h6 class="title">Are you sure you want to close the survey?</h6>
                <div class="d-flex justify-content-around">
                  <rb-button class="btn-red" @click.native="closeMe"> No </rb-button>
                  <rb-button style="color: #000" @click.native="closeModal"> Yes </rb-button>
                </div>
                <div>
                  <custom-checkbox id="never" v-model="never"> Dont show me again </custom-checkbox>
                </div>
              </div>
            </div>
          </transition>

          <div class="modal-content">
            <div class="d-flex justify-content-between">
              <div>
                <template v-if="start || (initQuestionIndex + 1 > 1 && !success)">
                  {{ initQuestionIndex + 1 }} /
                  {{ questionsForQuestionnaire.length }}
                </template>
              </div>
              <button class="close" type="button" @click="closeHandler">
                <span class="rbi-close" />
              </button>
            </div>
            <template v-if="success">
              <div class="d-flex align-items-center justify-content-center h-100">
                <div class="text-center">
                  <h4 class="content_title">Congratulations!</h4>
                  <div v-if="newReward" class="content_subtitle" style="padding: 15px">
                    {{ newReward }} credits have been added to your account!
                  </div>
                  <rb-button class="btn-red" @click.native="closeHandler"> Ok </rb-button>
                </div>
              </div>
            </template>
            <template v-else-if="start || initQuestionIndex + 1 > 1">
              <stepper v-model="step">
                <stepper-content step="profile_avatar">
                  <div class="content">
                    <h4 class="content_title">Upload avatar</h4>
                    <div class="d-flex justify-content-center">
                      <div class="max-w-400px text-center">
                        <img
                          v-show="avatarUpload"
                          alt=""
                          class="success_icon"
                          src="../../../assets/images/success-icon.png"
                        />
                        <rb-button
                          v-show="!avatarUpload"
                          class="btn-red"
                          @click.native="openUploader"
                        >
                          {{ $t('profile.uploadAva') }}
                        </rb-button>
                      </div>
                    </div>
                  </div>
                </stepper-content>
                <stepper-content step="profile_address">
                  <div class="content">
                    <h4 class="content_title">Where do you live?</h4>
                    <div class="d-flex justify-content-center">
                      <div class="max-w-400px">
                        <div class="form-group mb-2">
                          <label class="form-group--label font-weight-bold"
                            >{{ $t('profile.country') }}:</label
                          >
                          <div class="form-group--input">
                            <multiselect
                              v-model="selectedCountry"
                              :allow-empty="false"
                              :max-height="150"
                              :options="countriesLists"
                              :placeholder="$t('profile.no')"
                              :show-labels="false"
                              label="name"
                              track-by="name"
                              @select="onCountryClose"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="form-group--label font-weight-bold"
                            >{{ $t('profile.city') }}:</label
                          >
                          <div class="form-group--input">
                            <multiselect
                              v-model="selectedCity"
                              :allow-empty="false"
                              :max-height="150"
                              :options="citiesLists"
                              :placeholder="$t('profile.no')"
                              :show-labels="false"
                              label="name"
                              track-by="name"
                              @search-change="searchCity"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </stepper-content>
                <stepper-content step="profile_birthday">
                  <div class="content">
                    <h4 class="content_title">When is your birthday?</h4>
                    <div class="d-flex justify-content-center">
                      <div class="max-w-400px">
                        <div class="form-group">
                          <label class="form-group--label font-weight-bold"
                            >{{ $t('profile.dateBirth') }}:</label
                          >
                          <div class="form-group--input">
                            <date-picker
                              :date="birth"
                              :max-height-select="150"
                              :max-age="80"
                              :min-age="18"
                              @change="getDate"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </stepper-content>
                <stepper-content step="profile_additional_info_target">
                  <div class="content">
                    <h4 class="content_title">What is your goal for dating?</h4>
                    <div class="d-flex justify-content-center">
                      <div class="max-w-600px">
                        <div class="form-group">
                          <div class="form-group--input">
                            <div class="form-group--checkboxes-box row">
                              <div class="col-sm-4 col-xs-6">
                                <custom-checkbox
                                  id="friendship"
                                  v-model="targets.target_friendship"
                                >
                                  {{ $t('profile.vGoals.friendship') }}
                                </custom-checkbox>
                              </div>
                              <div class="col-sm-4 col-xs-6">
                                <custom-checkbox
                                  id="communication"
                                  v-model="targets.target_communication"
                                >
                                  {{ $t('profile.vGoals.communication') }}
                                </custom-checkbox>
                              </div>
                              <div class="col-sm-4 col-xs-6">
                                <custom-checkbox id="journey" v-model="targets.target_journey">
                                  {{ $t('profile.vGoals.journey') }}
                                </custom-checkbox>
                              </div>
                              <div class="col-sm-4 col-xs-6">
                                <custom-checkbox
                                  id="flirtation"
                                  v-model="targets.target_flirtation"
                                >
                                  {{ $t('profile.vGoals.flirt') }}
                                </custom-checkbox>
                              </div>
                              <div class="col-sm-4 col-xs-6">
                                <custom-checkbox id="meeting" v-model="targets.target_meeting">
                                  {{ $t('profile.vGoals.meeting') }}
                                </custom-checkbox>
                              </div>
                              <div class="col-sm-4 col-xs-6">
                                <custom-checkbox id="family" v-model="targets.target_family">
                                  {{ $t('profile.vGoals.family') }}
                                </custom-checkbox>
                              </div>
                              <div class="col-sm-4 col-xs-6">
                                <custom-checkbox id="children" v-model="targets.target_children">
                                  {{ $t('profile.vGoals.children') }}
                                </custom-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </stepper-content>
                <stepper-content step="profile_additional_children">
                  <div class="content">
                    <h4 class="content_title">Do you have children?</h4>
                    <div class="d-flex justify-content-center">
                      <div class="max-w-400px">
                        <div class="form-group">
                          <label class="form-group--label font-weight-bold"
                            >{{ $t('profile.children') }}:</label
                          >
                          <div class="form-group--input">
                            <multiselect
                              v-model="selected.children"
                              :allow-empty="true"
                              :max-height="150"
                              :options="values.children"
                              :placeholder="$t('profile.no')"
                              :searchable="false"
                              :show-labels="false"
                              label="v"
                              track-by="k"
                            />
                            <!--                          <multiselect v-model="selected.children" :maxHeight="150" :placeholder="$t('profile.no')" :options="values.children"-->
                            <!--                                       track-by="k" label="v"></multiselect>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </stepper-content>
                <stepper-content step="profile_additional_info_interests">
                  <div class="content">
                    <h4 class="content_title">Your interests?</h4>
                    <div class="d-flex justify-content-center">
                      <div class="max-w-400px">
                        <div :class="{ 'has-error': errors.first('int') }" class="form-group">
                          <label class="form-group--label font-weight-bold"
                            >{{ $t('profile.hobbies') }}:</label
                          >
                          <div class="form-group--input">
                            <textarea
                              v-model="selected.interests"
                              v-validate="'required|max:160'"
                              class="form-control transparent w-full"
                              data-vv-as="Interests"
                              name="int"
                            />
                            <div v-show="errors.has('int')" class="error py-0">
                              <i class="fa fa-warning is-danger" />
                              <span class="is-danger">{{ errors.first('int') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </stepper-content>
                <stepper-content step="profile_additional_info_about_me">
                  <div class="content">
                    <h4 class="content_title">Tell us about yourself</h4>
                    <div class="d-flex justify-content-center">
                      <div class="max-w-400px">
                        <div :class="{ 'has-error': errors.first('about') }" class="form-group">
                          <label class="form-group--label font-weight-bold"
                            >{{ $t('profile.aboutMe') }}:</label
                          >
                          <div class="form-group--input">
                            <textarea
                              v-model="selected.about_me"
                              v-validate="'required|max:1000'"
                              class="form-control transparent w-full"
                              data-vv-as="About me"
                              name="about"
                            />
                            <div v-show="errors.has('about')" class="error py-0">
                              <i class="fa fa-warning is-danger" />
                              <span class="is-danger">{{ errors.first('about') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </stepper-content>
                <stepper-content step="profile_additional_info_appearance">
                  <div class="content">
                    <h4 class="content_title">What is your appearance?</h4>
                    <div class="d-flex justify-content-center">
                      <div class="max-w-600px">
                        <div class="row">
                          <div class="col-sm-6 col-xs-6">
                            <div class="form-group mb-2">
                              <label class="form-group--label font-weight-bold" for="hair"
                                >{{ $t('profile.hairColor') }}:</label
                              >
                              <div class="form-group--input">
                                <multiselect
                                  id="hair"
                                  v-model="selected.hair_color"
                                  :allow-empty="false"
                                  :max-height="150"
                                  :options="values.hair"
                                  :searchable="false"
                                  :show-labels="false"
                                  label="v"
                                  track-by="k"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6 col-xs-6">
                            <div class="form-group mb-2">
                              <label class="form-group--label font-weight-bold" for="eyes"
                                >{{ $t('profile.eyesColor') }}:</label
                              >
                              <div class="form-group--input">
                                <multiselect
                                  id="eyes"
                                  v-model="selected.eye_color"
                                  :allow-empty="false"
                                  :max-height="150"
                                  :options="values.eyes"
                                  :searchable="false"
                                  :show-labels="false"
                                  label="v"
                                  track-by="k"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6 col-xs-6">
                            <div class="form-group mb-2">
                              <label class="form-group--label font-weight-bold" for="bd"
                                >{{ $t('profile.bodyType') }}:</label
                              >
                              <div class="form-group--input">
                                <multiselect
                                  id="bd"
                                  v-model="selected.body"
                                  :allow-empty="false"
                                  :max-height="150"
                                  :options="values.body"
                                  :searchable="false"
                                  :show-labels="false"
                                  label="v"
                                  track-by="k"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6 col-xs-6">
                            <div class="form-group mb-2">
                              <label class="form-group--label font-weight-bold" for="h-1"
                                >{{ $t('profile.height') }}:</label
                              >
                              <div class="form-group--input">
                                <div class="set-flex multiple-row">
                                  <div class="appearance-height">
                                    <multiselect
                                      id="h-1"
                                      v-model="height.feet"
                                      :allow-empty="false"
                                      :max-height="150"
                                      :options="values.feet"
                                      :searchable="false"
                                      :show-labels="false"
                                      label="v"
                                      track-by="k"
                                    />
                                  </div>
                                  <div class="appearance-height">
                                    <multiselect
                                      v-model="height.inches"
                                      :allow-empty="false"
                                      :max-height="150"
                                      :options="values.inches"
                                      :searchable="false"
                                      :show-labels="false"
                                      label="v"
                                      track-by="k"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6 col-xs-6">
                            <div
                              :class="{ 'has-error': errors.first('weight') }"
                              class="form-group mb-2"
                            >
                              <label class="form-group--label font-weight-bold" for="weight"
                                >{{ $t('profile.weight') }}:</label
                              >
                              <div class="form-group--input">
                                <div class="input-group">
                                  <input
                                    id="weight"
                                    v-model="weight"
                                    v-validate="'min_value:0|numeric'"
                                    class="form-control transparent w-full"
                                    data-vv-as="Weight"
                                    name="weight"
                                    type="number"
                                  />
                                  <div class="input-group-addon">
                                    {{ $t('profile.lbs') }}
                                  </div>
                                </div>
                                <div v-show="errors.has('weight')" class="error">
                                  <i class="fa fa-warning is-danger" />
                                  <span class="is-danger">{{ errors.first('weight') }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </stepper-content>
                <stepper-content step="profile_additional_info_about_woman_age">
                  <div class="content">
                    <h4 class="content_title">At what age are women interested?</h4>
                    <div class="d-flex justify-content-center">
                      <div class="max-w-400px">
                        <div :class="{ 'has-error': validateAge }" class="form-group">
                          <div class="form-group--input">
                            <div class="set-flex multiple-row">
                              <div class="appearance-height">
                                <label class="form-group--label font-weight-bold mb-0" for="m-a"
                                  >{{ $t('profile.ageFrom') }}:</label
                                >
                                <div class="input-group">
                                  <input
                                    id="m-a"
                                    v-model="ageFrom"
                                    v-validate="'min_value:18|max_value:100|numeric'"
                                    class="form-control transparent w-full"
                                    data-vv-as="Age from"
                                    name="ageFrom"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div class="appearance-height">
                                <label class="form-group--label font-weight-bold mb-0" for="ma-a"
                                  >{{ $t('profile.to') }}:</label
                                >
                                <div class="input-group">
                                  <input
                                    id="ma-a"
                                    v-model="ageTo"
                                    v-validate="'min_value:18|max_value:100|numeric'"
                                    class="form-control transparent w-full"
                                    data-vv-as="Age to"
                                    name="ageTo"
                                    type="number"
                                  />
                                </div>
                              </div>
                            </div>
                            <div v-show="validateAge" class="error">
                              <i class="fa fa-warning is-danger" />
                              <span class="is-danger">{{ $t('profile.errors.failAge') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </stepper-content>
                <stepper-content step="profile_additional_info_about_woman_appearance">
                  <div class="content">
                    <h4 class="content_title">What interests a woman's appearance?</h4>
                    <div class="d-flex justify-content-center">
                      <div class="max-w-400px">
                        <div class="row">
                          <div class="col-sm-6 col-xs-6">
                            <div class="form-group mb-2">
                              <label class="form-group--label font-weight-bold"
                                >{{ $t('profile.hairColor') }}:</label
                              >
                              <div class="form-group--input">
                                <multiselect
                                  v-model="selected.about_woman_hair_color"
                                  :allow-empty="false"
                                  :max-height="150"
                                  :options="values.hair"
                                  :searchable="false"
                                  :show-labels="false"
                                  label="v"
                                  track-by="k"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6 col-xs-6">
                            <div class="form-group mb-2">
                              <label class="form-group--label font-weight-bold"
                                >{{ $t('profile.eyesColor') }}:</label
                              >
                              <div class="form-group--input">
                                <multiselect
                                  v-model="selected.about_woman_eye_color"
                                  :allow-empty="false"
                                  :max-height="150"
                                  :options="values.eyes"
                                  :searchable="false"
                                  :show-labels="false"
                                  label="v"
                                  track-by="k"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6 col-xs-6">
                            <div class="form-group">
                              <label class="form-group--label font-weight-bold"
                                >{{ $t('profile.bodyType') }}:</label
                              >
                              <div class="form-group--input">
                                <multiselect
                                  v-model="selected.about_woman_body"
                                  :allow-empty="false"
                                  :max-height="150"
                                  :options="values.body"
                                  :searchable="false"
                                  :show-labels="false"
                                  label="v"
                                  track-by="k"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </stepper-content>
                <stepper-content step="profile_additional_info_about_woman_text">
                  <div class="content">
                    <h4 class="content_title">Describe your ideal woman?</h4>
                    <div class="d-flex justify-content-center">
                      <div class="max-w-400px">
                        <div
                          :class="{ 'has-error': errors.first('about_woman') }"
                          class="form-group"
                        >
                          <label class="form-group--label font-weight-bold"
                            >{{ $t('profile.womanType') }}:</label
                          >
                          <div class="form-group--input">
                            <textarea
                              v-model="selected.about_woman_text"
                              v-validate="'required|max:400'"
                              class="form-control transparent w-full"
                              data-vv-as="About me"
                              name="about_woman"
                            />
                            <div v-show="errors.has('about_woman')" class="error py-0">
                              <i class="fa fa-warning is-danger" />
                              <span class="is-danger">{{ errors.first('about_woman') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </stepper-content>
                <stepper-content step="profile_additional_info_about_woman_children">
                  <div class="content">
                    <h4 class="content_title">
                      Is it acceptable for you that a woman will already have children?
                    </h4>
                    <div class="d-flex justify-content-center">
                      <div class="max-w-400px">
                        <div class="form-group">
                          <label class="form-group--label font-weight-bold"
                            >{{ $t('profile.children') }}:</label
                          >
                          <div class="form-group--input">
                            <multiselect
                              v-model="selected.about_woman_children"
                              :allow-empty="true"
                              :max-height="150"
                              :options="values.childrenWoman"
                              :placeholder="$t('profile.no')"
                              :searchable="false"
                              :show-labels="false"
                              label="v"
                              track-by="k"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </stepper-content>
              </stepper>
            </template>
            <template v-else>
              <div class="d-flex align-items-center justify-content-center h-100">
                <div class="text-center">
                  <h4 class="content_title">Take our Survey and earn bonus credits</h4>
                  <rb-button class="btn-red" @click.native="start = true"> Start! </rb-button>
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="(start || (initQuestionIndex + 1 > 1 && !success)) && !never"
            class="modal-action"
          >
            <rb-button
              :disabled="!isValidStep || loading"
              class="btn-black next_step"
              @click.native="nextStep"
            >
              Next
            </rb-button>
          </div>
        </div>
      </div>
      <upload-photos-modal @updatePage="updatePageInfo" />
    </div>
  </transition>
</template>

<script>
import moment from 'moment';
import UploadPhotosModal from './UploadPhotosModal.vue';
import RbButton from '../../Button';
import Stepper from '../../Stepper/Stepper';
import StepperContent from '../../Stepper/StepperContent';
import DatePicker from '../../Authorized/parts/DatePicker.vue';
import customCheckbox from '../parts/CustomCheckbox';
import { MEASURE_CALCULATIONS } from '../../../mixins/types/profile';
import isEqual from 'lodash/isEqual';

export default {
  name: 'QuestionnaireModal',
  components: {
    UploadPhotosModal,
    RbButton,
    Stepper,
    StepperContent,
    DatePicker,
    customCheckbox,
  },
  mixins: [MEASURE_CALCULATIONS],
  data() {
    return {
      newReward: null,
      isFirst: false,
      loading: false,
      success: false,
      closed: false,
      never: false,
      start: false,
      step: 1,
      initQuestionIndex: 0,
      avatarUpload: false,
      selectedCountry: null,
      countriesLists: [],
      selectedCity: null,
      citiesLists: [],
      birth: '',
      newBirth: '',
      values: {
        children: [
          { k: 'no', v: this.$t('profile.vChildren.no') },
          { k: 'I have no children', v: this.$t('profile.vChildren.myNot') },
          {
            k: 'no but I want to have children',
            v: this.$t('profile.vChildren.want'),
          },
          { k: 'have children', v: this.$t('profile.vChildren.have') },
          {
            k: 'have children, but living separately',
            v: this.$t('profile.vChildren.haveSep'),
          },
          { k: 'have adult children', v: this.$t('profile.vChildren.adult') },
        ],
        childrenWoman: [
          { k: 'no', v: this.$t('profile.vChildren.no') },
          { k: 'I have no children', v: this.$t('profile.vChildren.not') },
          {
            k: 'no but I want to have children',
            v: this.$t('profile.vChildren.want'),
          },
          { k: 'have children', v: this.$t('profile.vChildren.have') },
          {
            k: 'have children, but living separately',
            v: this.$t('profile.vChildren.haveSep'),
          },
          { k: 'have adult children', v: this.$t('profile.vChildren.adult') },
        ],
        feet: [
          { k: 'no', v: this.$t('profile.vFeet.no') },
          { k: 0, v: this.$t('profile.vFeet.v', ['0']) },
          { k: 4, v: this.$t('profile.vFeet.v', ['4']) },
          { k: 5, v: this.$t('profile.vFeet.v', ['5']) },
          { k: 6, v: this.$t('profile.vFeet.v', ['6']) },
        ],
        inches: [
          { k: 'no', v: this.$t('profile.vInches.no') },
          { k: 0, v: this.$t('profile.vInches.v', ['0']) },
          { k: 1, v: this.$t('profile.vInches.v', ['1']) },
          { k: 2, v: this.$t('profile.vInches.v', ['2']) },
          { k: 3, v: this.$t('profile.vInches.v', ['3']) },
          { k: 4, v: this.$t('profile.vInches.v', ['4']) },
          { k: 5, v: this.$t('profile.vInches.v', ['5']) },
          { k: 6, v: this.$t('profile.vInches.v', ['6']) },
          { k: 7, v: this.$t('profile.vInches.v', ['7']) },
          { k: 8, v: this.$t('profile.vInches.v', ['8']) },
          { k: 9, v: this.$t('profile.vInches.v', ['9']) },
          { k: 10, v: this.$t('profile.vInches.v', ['10']) },
          { k: 11, v: this.$t('profile.vInches.v', ['11']) },
        ],
        eyes: [
          { k: 'no', v: this.$t('profile.vEyes.no') },
          { k: 'black', v: this.$t('profile.vEyes.black') },
          { k: 'green', v: this.$t('profile.vEyes.green') },
          { k: 'gray', v: this.$t('profile.vEyes.gray') },
          { k: 'brown', v: this.$t('profile.vEyes.brown') },
          { k: 'light-brown', v: this.$t('profile.vEyes.lightBrown') },
          { k: 'blue', v: this.$t('profile.vEyes.blue') },
        ],
        hair: [
          { k: 'no', v: this.$t('profile.vHair.no') },
          { k: 'blonde', v: this.$t('profile.vHair.blond') },
          { k: 'brown', v: this.$t('profile.vHair.brown') },
          { k: 'redhead', v: this.$t('profile.vHair.redhead') },
          { k: 'brunette', v: this.$t('profile.vHair.brunette') },
          { k: 'colored', v: this.$t('profile.vHair.colored') },
        ],
        body: [
          { k: 'no', v: this.$t('profile.vBody.no') },
          { k: 'thin', v: this.$t('profile.vBody.thin') },
          { k: 'slender', v: this.$t('profile.vBody.slender') },
          { k: 'athletic', v: this.$t('profile.vBody.athletic') },
          { k: 'a few extra pounds', v: this.$t('profile.vBody.extra') },
          { k: 'plump', v: this.$t('profile.vBody.plump') },
        ],
      },
      targets: {
        target_family: false,
        target_meeting: false,
        target_children: false,
        target_communication: false,
        target_flirtation: false,
        target_friendship: false,
        target_journey: false,
      },
      selected: {
        children: '',
        about_woman_age_from: '',
        about_woman_age_to: '',
        about_woman_hair_color: '',
        about_woman_eye_color: '',
        about_woman_body: '',
        about_woman_children: '',
        about_woman_text: '',
        interests: '',
        about_me: '',
        eye_color: '',
        hair_color: '',
        body: '',
        weight: 0,
        height: 0,
      },
      height: {
        feet: 0,
        inches: 0,
      },
    };
  },
  computed: {
    questionnaireCreditsReward() {
      return this.$store.getters.questionnaireCreditsReward;
    },
    questionnaireStep() {
      return this.$store.getters.questionnaireStep;
    },
    user() {
      return this.$store.getters.user;
    },
    ageFrom: {
      get() {
        return this.selected.about_woman_age_from;
      },
      set(v) {
        this.selected.about_woman_age_from = v;
      },
    },
    ageTo: {
      get() {
        return this.selected.about_woman_age_to;
      },
      set(v) {
        this.selected.about_woman_age_to = v;
      },
    },
    validateAge() {
      const from = +this.selected.about_woman_age_from ? +this.selected.about_woman_age_from : 0;
      const to = +this.selected.about_woman_age_to ? +this.selected.about_woman_age_to : 0;
      return this.errors.has('ageFrom') || this.errors.has('ageTo') || from > to;
    },
    modal() {
      return this.$store.getters.questionnaireModal;
    },
    modalOpened() {
      return this.$store.getters.questionnaireModal.open;
    },
    questionsForQuestionnaire() {
      return this.$store.getters.questionsForQuestionnaire;
    },
    weight: {
      get() {
        return Math.round(this.kgToLbs(this.selected.weight));
      },
      set(v) {
        this.selected.weight = this.lbsToKg(v);
      },
    },
    feet() {
      return this.height.feet;
    },
    inches() {
      return this.height.inches;
    },
    isValidStep() {
      switch (this.step) {
        case 'profile_avatar':
          return this.avatarUpload;
        case 'profile_address':
          return Boolean(this.selectedCountry && this.selectedCity);
        case 'profile_birthday':
          return Boolean(this.newBirth);
        case 'profile_additional_info_target':
          return Object.values(this.targets).some((i) => i);
        case 'profile_additional_children':
          return Boolean(
            this.selected.children ? Boolean(this.selected.children.k) : this.selected.children
          );
        case 'profile_additional_info_interests':
          return this.selected.interests
            ? this.selected.interests.length > 0 && this.selected.interests.length <= 160
            : false;
        case 'profile_additional_info_about_me':
          return this.selected.about_me
            ? this.selected.about_me.length > 0 && this.selected.about_me.length <= 1000
            : false;
        case 'profile_additional_info_appearance':
          return (
            Boolean(
              this.selected.hair_color
                ? Boolean(this.selected.hair_color.k)
                : this.selected.hair_color
            ) &&
            Boolean(this.selected.body ? Boolean(this.selected.body.k) : this.selected.body) &&
            Boolean(
              this.selected.eye_color ? Boolean(this.selected.eye_color.k) : this.selected.eye_color
            ) &&
            Boolean(this.feetInchToCm(this.height.feet.k, this.height.inches.k)) &&
            Boolean(+this.selected.weight)
          );
        case 'profile_additional_info_about_woman_age':
          return (
            this.selected.about_woman_age_from &&
            this.selected.about_woman_age_from &&
            !this.validateAge
          );
        case 'profile_additional_info_about_woman_appearance':
          return (
            Boolean(
              this.selected.about_woman_hair_color
                ? Boolean(this.selected.about_woman_hair_color.k)
                : this.selected.about_woman_hair_color
            ) &&
            Boolean(
              this.selected.about_woman_eye_color
                ? Boolean(this.selected.about_woman_eye_color.k)
                : this.selected.about_woman_eye_color
            ) &&
            Boolean(
              this.selected.about_woman_body
                ? Boolean(this.selected.about_woman_body.k)
                : this.selected.about_woman_body
            )
          );
        case 'profile_additional_info_about_woman_text':
          return this.selected.about_woman_text
            ? this.selected.about_woman_text.length > 0 &&
                this.selected.about_woman_text.length <= 400
            : false;
        case 'profile_additional_info_about_woman_children':
          return Boolean(
            this.selected.about_woman_children
              ? Boolean(this.selected.about_woman_children.k)
              : this.selected.about_woman_children
          );
        default:
          return false;
      }
    },
    stepAction() {
      switch (this.step) {
        case 'profile_address':
          return () =>
            new Promise((resolve, reject) => {
              this.$http
                .post('v1/profile/edit-main', {
                  access_token: window.localStorage['access-token'],
                  name: this.user.name,
                  city: this.selectedCity && this.selectedCity.name ? this.selectedCity.name : '',
                  country:
                    this.selectedCity && this.selectedCountry.name ? this.selectedCountry.name : '',
                  birthday: this.newBirth ? moment(this.newBirth).unix() : 'null',
                  family_status: this.user.profile_additional_info.family_status,
                  lang_english: this.user.profile_additional_info.lang_english,
                  lang_spanish: this.user.profile_additional_info.lang_spanish,
                  lang_french: this.user.profile_additional_info.lang_french,
                  lang_portuguese: this.user.profile_additional_info.lang_portuguese,
                  lang_german: this.user.profile_additional_info.lang_german,
                })
                .then(
                  (r) => {
                    if (r.body.status) {
                      resolve();
                    }
                  },
                  (e) => {
                    reject(e);
                  }
                );
            });
        case 'profile_birthday':
          return () =>
            new Promise((resolve, reject) => {
              this.$http
                .post('v1/profile/edit-main', {
                  access_token: window.localStorage['access-token'],
                  name: this.user.name,
                  city: this.selectedCity && this.selectedCity.name ? this.selectedCity.name : '',
                  country:
                    this.selectedCity && this.selectedCountry.name ? this.selectedCountry.name : '',
                  birthday: this.newBirth ? moment(this.newBirth).unix() : 'null',
                  family_status: this.user.profile_additional_info.family_status,
                  lang_english: this.user.profile_additional_info.lang_english,
                  lang_spanish: this.user.profile_additional_info.lang_spanish,
                  lang_french: this.user.profile_additional_info.lang_french,
                  lang_portuguese: this.user.profile_additional_info.lang_portuguese,
                  lang_german: this.user.profile_additional_info.lang_german,
                })
                .then(
                  (r) => {
                    if (r.body.status) {
                      resolve();
                    }
                  },
                  (e) => {
                    reject(e);
                  }
                );
            });
        case 'profile_additional_info_target':
          return () =>
            new Promise((resolve, reject) => {
              this.$http
                .post('v1/profile/edit-about-me', {
                  access_token: window.localStorage['access-token'],
                  religion: this.user.profile_additional_info.religion || 'no',
                  profession: this.user.profile_additional_info.profession,
                  education: this.user.profile_additional_info.education || 'no',
                  smoke: this.user.profile_additional_info.smoke || 'no',
                  drink: this.user.profile_additional_info.drink || 'no',
                  pet_aquarium_fish: this.user.profile_additional_info.pet_aquarium_fish || 0,
                  pet_cat: this.user.profile_additional_info.pet_cat || 0,
                  pet_chinchilla: this.user.profile_additional_info.pet_chinchilla || 0,
                  pet_dog: this.user.profile_additional_info.pet_dog || 0,
                  pet_hamster: this.user.profile_additional_info.pet_hamster || 0,
                  pet_iguana: this.user.profile_additional_info.pet_iguana || 0,
                  pet_parrot: this.user.profile_additional_info.pet_parrot || 0,
                  pet_snake: this.user.profile_additional_info.pet_snake || 0,
                  pet_turtle: this.user.profile_additional_info.pet_turtle || 0,
                  pet_rabbit: this.user.profile_additional_info.pet_rabbit || 0,
                  celebration_birthday: this.user.profile_additional_info.celebration_birthday || 0,
                  celebration_christmas_day:
                    this.user.profile_additional_info.celebration_christmas_day || 0,
                  celebration_easter: this.user.profile_additional_info.celebration_easter || 0,
                  celebration_halloween:
                    this.user.profile_additional_info.celebration_halloween || 0,
                  celebration_new_years_day:
                    this.user.profile_additional_info.celebration_new_years_day || 0,
                  celebration_valentines_day:
                    this.user.profile_additional_info.celebration_valentines_day || 0,
                  celebration_womens_day:
                    this.user.profile_additional_info.celebration_womens_day || 0,
                  accommodation: this.user.profile_additional_info.accommodation || 'no',

                  children: this.selected.children ? this.selected.children.k : 'no',
                  interests: this.selected.interests,
                  target_family: +this.targets.target_family,
                  target_meeting: +this.targets.target_meeting,
                  target_children: +this.targets.target_children,
                  target_communication: +this.targets.target_communication,
                  target_flirtation: +this.targets.target_flirtation,
                  target_friendship: +this.targets.target_friendship,
                  target_journey: +this.targets.target_journey,
                  about_me: this.selected.about_me,
                })
                .then(
                  (r) => {
                    if (r.body.status) {
                      resolve();
                    }
                  },
                  (e) => {
                    reject(e);
                  }
                );
            });
        case 'profile_additional_children':
          return () =>
            new Promise((resolve, reject) => {
              this.$http
                .post('v1/profile/edit-about-me', {
                  access_token: window.localStorage['access-token'],
                  religion: this.user.profile_additional_info.religion || 'no',
                  profession: this.user.profile_additional_info.profession,
                  education: this.user.profile_additional_info.education || 'no',
                  smoke: this.user.profile_additional_info.smoke || 'no',
                  drink: this.user.profile_additional_info.drink || 'no',
                  pet_aquarium_fish: this.user.profile_additional_info.pet_aquarium_fish || 0,
                  pet_cat: this.user.profile_additional_info.pet_cat || 0,
                  pet_chinchilla: this.user.profile_additional_info.pet_chinchilla || 0,
                  pet_dog: this.user.profile_additional_info.pet_dog || 0,
                  pet_hamster: this.user.profile_additional_info.pet_hamster || 0,
                  pet_iguana: this.user.profile_additional_info.pet_iguana || 0,
                  pet_parrot: this.user.profile_additional_info.pet_parrot || 0,
                  pet_snake: this.user.profile_additional_info.pet_snake || 0,
                  pet_turtle: this.user.profile_additional_info.pet_turtle || 0,
                  pet_rabbit: this.user.profile_additional_info.pet_rabbit || 0,
                  celebration_birthday: this.user.profile_additional_info.celebration_birthday || 0,
                  celebration_christmas_day:
                    this.user.profile_additional_info.celebration_christmas_day || 0,
                  celebration_easter: this.user.profile_additional_info.celebration_easter || 0,
                  celebration_halloween:
                    this.user.profile_additional_info.celebration_halloween || 0,
                  celebration_new_years_day:
                    this.user.profile_additional_info.celebration_new_years_day || 0,
                  celebration_valentines_day:
                    this.user.profile_additional_info.celebration_valentines_day || 0,
                  celebration_womens_day:
                    this.user.profile_additional_info.celebration_womens_day || 0,
                  accommodation: this.user.profile_additional_info.accommodation || 'no',

                  children: this.selected.children ? this.selected.children.k : 'no',
                  interests: this.selected.interests,
                  target_family: +this.targets.target_family,
                  target_meeting: +this.targets.target_meeting,
                  target_children: +this.targets.target_children,
                  target_communication: +this.targets.target_communication,
                  target_flirtation: +this.targets.target_flirtation,
                  target_friendship: +this.targets.target_friendship,
                  target_journey: +this.targets.target_journey,
                  about_me: this.selected.about_me,
                })
                .then(
                  (r) => {
                    if (r.body.status) {
                      resolve();
                    }
                  },
                  (e) => {
                    reject(e);
                  }
                );
            });
        case 'profile_additional_info_interests':
          return () =>
            new Promise((resolve, reject) => {
              this.$http
                .post('v1/profile/edit-about-me', {
                  access_token: window.localStorage['access-token'],
                  religion: this.user.profile_additional_info.religion || 'no',
                  profession: this.user.profile_additional_info.profession,
                  education: this.user.profile_additional_info.education || 'no',
                  smoke: this.user.profile_additional_info.smoke || 'no',
                  drink: this.user.profile_additional_info.drink || 'no',
                  pet_aquarium_fish: this.user.profile_additional_info.pet_aquarium_fish || 0,
                  pet_cat: this.user.profile_additional_info.pet_cat || 0,
                  pet_chinchilla: this.user.profile_additional_info.pet_chinchilla || 0,
                  pet_dog: this.user.profile_additional_info.pet_dog || 0,
                  pet_hamster: this.user.profile_additional_info.pet_hamster || 0,
                  pet_iguana: this.user.profile_additional_info.pet_iguana || 0,
                  pet_parrot: this.user.profile_additional_info.pet_parrot || 0,
                  pet_snake: this.user.profile_additional_info.pet_snake || 0,
                  pet_turtle: this.user.profile_additional_info.pet_turtle || 0,
                  pet_rabbit: this.user.profile_additional_info.pet_rabbit || 0,
                  celebration_birthday: this.user.profile_additional_info.celebration_birthday || 0,
                  celebration_christmas_day:
                    this.user.profile_additional_info.celebration_christmas_day || 0,
                  celebration_easter: this.user.profile_additional_info.celebration_easter || 0,
                  celebration_halloween:
                    this.user.profile_additional_info.celebration_halloween || 0,
                  celebration_new_years_day:
                    this.user.profile_additional_info.celebration_new_years_day || 0,
                  celebration_valentines_day:
                    this.user.profile_additional_info.celebration_valentines_day || 0,
                  celebration_womens_day:
                    this.user.profile_additional_info.celebration_womens_day || 0,
                  accommodation: this.user.profile_additional_info.accommodation || 'no',

                  children: this.selected.children ? this.selected.children.k : 'no',
                  interests: this.selected.interests,
                  target_family: +this.targets.target_family,
                  target_meeting: +this.targets.target_meeting,
                  target_children: +this.targets.target_children,
                  target_communication: +this.targets.target_communication,
                  target_flirtation: +this.targets.target_flirtation,
                  target_friendship: +this.targets.target_friendship,
                  target_journey: +this.targets.target_journey,
                  about_me: this.selected.about_me,
                })
                .then(
                  (r) => {
                    if (r.body.status) {
                      resolve();
                    }
                  },
                  (e) => {
                    reject(e);
                  }
                );
            });
        case 'profile_additional_info_about_me':
          return () =>
            new Promise((resolve, reject) => {
              this.$http
                .post('v1/profile/edit-about-me', {
                  access_token: window.localStorage['access-token'],
                  religion: this.user.profile_additional_info.religion || 'no',
                  profession: this.user.profile_additional_info.profession,
                  education: this.user.profile_additional_info.education || 'no',
                  smoke: this.user.profile_additional_info.smoke || 'no',
                  drink: this.user.profile_additional_info.drink || 'no',
                  pet_aquarium_fish: this.user.profile_additional_info.pet_aquarium_fish || 0,
                  pet_cat: this.user.profile_additional_info.pet_cat || 0,
                  pet_chinchilla: this.user.profile_additional_info.pet_chinchilla || 0,
                  pet_dog: this.user.profile_additional_info.pet_dog || 0,
                  pet_hamster: this.user.profile_additional_info.pet_hamster || 0,
                  pet_iguana: this.user.profile_additional_info.pet_iguana || 0,
                  pet_parrot: this.user.profile_additional_info.pet_parrot || 0,
                  pet_snake: this.user.profile_additional_info.pet_snake || 0,
                  pet_turtle: this.user.profile_additional_info.pet_turtle || 0,
                  pet_rabbit: this.user.profile_additional_info.pet_rabbit || 0,
                  celebration_birthday: this.user.profile_additional_info.celebration_birthday || 0,
                  celebration_christmas_day:
                    this.user.profile_additional_info.celebration_christmas_day || 0,
                  celebration_easter: this.user.profile_additional_info.celebration_easter || 0,
                  celebration_halloween:
                    this.user.profile_additional_info.celebration_halloween || 0,
                  celebration_new_years_day:
                    this.user.profile_additional_info.celebration_new_years_day || 0,
                  celebration_valentines_day:
                    this.user.profile_additional_info.celebration_valentines_day || 0,
                  celebration_womens_day:
                    this.user.profile_additional_info.celebration_womens_day || 0,
                  accommodation: this.user.profile_additional_info.accommodation || 'no',

                  children: this.selected.children ? this.selected.children.k : 'no',
                  interests: this.selected.interests,
                  target_family: +this.targets.target_family,
                  target_meeting: +this.targets.target_meeting,
                  target_children: +this.targets.target_children,
                  target_communication: +this.targets.target_communication,
                  target_flirtation: +this.targets.target_flirtation,
                  target_friendship: +this.targets.target_friendship,
                  target_journey: +this.targets.target_journey,
                  about_me: this.selected.about_me,
                })
                .then(
                  (r) => {
                    if (r.body.status) {
                      resolve();
                    }
                  },
                  (e) => {
                    reject(e);
                  }
                );
            });
        case 'profile_additional_info_appearance':
          return () =>
            new Promise((resolve, reject) => {
              this.$http
                .post('v1/profile/edit-appearance', {
                  access_token: window.localStorage['access-token'],
                  eye_color: this.selected.eye_color ? this.selected.eye_color.k : '',
                  hair_color: this.selected.hair_color ? this.selected.hair_color.k : '',
                  body: this.selected.body ? this.selected.body.k : '',
                  weight: this.selected.weight,
                  height: this.feetInchToCm(this.height.feet.k, this.height.inches.k),
                })
                .then(
                  (r) => {
                    if (r.body.status) {
                      resolve();
                    }
                  },
                  (e) => {
                    reject(e);
                  }
                );
            });
        case 'profile_additional_info_about_woman_age':
          return () =>
            new Promise((resolve, reject) => {
              this.$http
                .post('v1/profile/edit-perfect-woman', {
                  access_token: window.localStorage['access-token'],
                  about_woman_age_from: this.selected.about_woman_age_from,
                  about_woman_age_to: this.selected.about_woman_age_to,
                  about_woman_hair_color: this.selected.about_woman_hair_color
                    ? this.selected.about_woman_hair_color.k
                    : '',
                  about_woman_eye_color: this.selected.about_woman_eye_color
                    ? this.selected.about_woman_eye_color.k
                    : '',
                  about_woman_body: this.selected.about_woman_body
                    ? this.selected.about_woman_body.k
                    : '',

                  about_woman_children: this.selected.about_woman_children
                    ? this.selected.about_woman_children.k
                    : '',
                  about_woman_text: this.selected.about_woman_text,

                  about_woman_growth_from:
                    this.user.profile_additional_info.about_woman_growth_from || 0,
                  about_woman_growth_to:
                    this.user.profile_additional_info.about_woman_growth_to || 0,
                  about_woman_weight_from:
                    this.user.profile_additional_info.about_woman_weight_from || 0,
                  about_woman_weight_to:
                    this.user.profile_additional_info.about_woman_weight_to || 0,
                  about_woman_english_level:
                    this.user.profile_additional_info.about_woman_english_level || 'no',
                })
                .then(
                  (r) => {
                    if (r.body.status) {
                      resolve();
                    }
                  },
                  (e) => {
                    reject(e);
                  }
                );
            });
        case 'profile_additional_info_about_woman_appearance':
          return () =>
            new Promise((resolve, reject) => {
              this.$http
                .post('v1/profile/edit-perfect-woman', {
                  access_token: window.localStorage['access-token'],
                  about_woman_age_from: this.selected.about_woman_age_from,
                  about_woman_age_to: this.selected.about_woman_age_to,
                  about_woman_hair_color: this.selected.about_woman_hair_color
                    ? this.selected.about_woman_hair_color.k
                    : '',
                  about_woman_eye_color: this.selected.about_woman_eye_color
                    ? this.selected.about_woman_eye_color.k
                    : '',
                  about_woman_body: this.selected.about_woman_body
                    ? this.selected.about_woman_body.k
                    : '',

                  about_woman_children: this.selected.about_woman_children
                    ? this.selected.about_woman_children.k
                    : '',
                  about_woman_text: this.selected.about_woman_text,

                  about_woman_growth_from:
                    this.user.profile_additional_info.about_woman_growth_from || 0,
                  about_woman_growth_to:
                    this.user.profile_additional_info.about_woman_growth_to || 0,
                  about_woman_weight_from:
                    this.user.profile_additional_info.about_woman_weight_from || 0,
                  about_woman_weight_to:
                    this.user.profile_additional_info.about_woman_weight_to || 0,
                  about_woman_english_level:
                    this.user.profile_additional_info.about_woman_english_level || 'no',
                })
                .then(
                  (r) => {
                    if (r.body.status) {
                      resolve();
                    }
                  },
                  (e) => {
                    reject(e);
                  }
                );
            });
        case 'profile_additional_info_about_woman_text':
          return () =>
            new Promise((resolve, reject) => {
              this.$http
                .post('v1/profile/edit-perfect-woman', {
                  access_token: window.localStorage['access-token'],
                  about_woman_age_from: this.selected.about_woman_age_from,
                  about_woman_age_to: this.selected.about_woman_age_to,
                  about_woman_hair_color: this.selected.about_woman_hair_color
                    ? this.selected.about_woman_hair_color.k
                    : '',
                  about_woman_eye_color: this.selected.about_woman_eye_color
                    ? this.selected.about_woman_eye_color.k
                    : '',
                  about_woman_body: this.selected.about_woman_body
                    ? this.selected.about_woman_body.k
                    : '',

                  about_woman_children: this.selected.about_woman_children
                    ? this.selected.about_woman_children.k
                    : '',
                  about_woman_text: this.selected.about_woman_text,

                  about_woman_growth_from:
                    this.user.profile_additional_info.about_woman_growth_from || 0,
                  about_woman_growth_to:
                    this.user.profile_additional_info.about_woman_growth_to || 0,
                  about_woman_weight_from:
                    this.user.profile_additional_info.about_woman_weight_from || 0,
                  about_woman_weight_to:
                    this.user.profile_additional_info.about_woman_weight_to || 0,
                  about_woman_english_level:
                    this.user.profile_additional_info.about_woman_english_level || 'no',
                })
                .then(
                  (r) => {
                    if (r.body.status) {
                      resolve();
                    }
                  },
                  (e) => {
                    reject(e);
                  }
                );
            });
        case 'profile_additional_info_about_woman_children':
          return () =>
            new Promise((resolve, reject) => {
              this.$http
                .post('v1/profile/edit-perfect-woman', {
                  access_token: window.localStorage['access-token'],
                  about_woman_age_from: this.selected.about_woman_age_from,
                  about_woman_age_to: this.selected.about_woman_age_to,
                  about_woman_hair_color: this.selected.about_woman_hair_color
                    ? this.selected.about_woman_hair_color.k
                    : '',
                  about_woman_eye_color: this.selected.about_woman_eye_color
                    ? this.selected.about_woman_eye_color.k
                    : '',
                  about_woman_body: this.selected.about_woman_body
                    ? this.selected.about_woman_body.k
                    : '',

                  about_woman_children: this.selected.about_woman_children
                    ? this.selected.about_woman_children.k
                    : '',
                  about_woman_text: this.selected.about_woman_text,

                  about_woman_growth_from:
                    this.user.profile_additional_info.about_woman_growth_from || 0,
                  about_woman_growth_to:
                    this.user.profile_additional_info.about_woman_growth_to || 0,
                  about_woman_weight_from:
                    this.user.profile_additional_info.about_woman_weight_from || 0,
                  about_woman_weight_to:
                    this.user.profile_additional_info.about_woman_weight_to || 0,
                  about_woman_english_level:
                    this.user.profile_additional_info.about_woman_english_level || 'no',
                })
                .then(
                  (r) => {
                    if (r.body.status) {
                      resolve();
                    }
                  },
                  (e) => {
                    reject(e);
                  }
                );
            });
        default:
          return () =>
            new Promise((resolve) => {
              resolve();
            });
      }
    },
  },
  watch: {
    modalOpened(val) {
      if (val) {
        this.$store.dispatch('getQuestionnaire').then(() => {
          this.initStep();
          this.loadCountries();

          this.height.feet = this.values.feet.find(
            (i) => i.k === this.cmToFeetInch(this.selected.height).feet
          );
          this.height.inches = this.values.inches.find(
            (i) => i.k === this.cmToFeetInch(this.selected.height).inches
          );
          this.initData();
          this.isFirst = true;
        });
      } else {
        this.resetData();
      }
    },
  },
  methods: {
    closeMe() {
      this.closed = false;
      this.never = false;
    },
    closeHandler() {
      if (this.success) {
        this.$gtag.event('closed_questionnaire', {
          event_category: 'questionnaire',
          event_label: 'Closed',
        });

        this.$store.commit('updateQuestionnaireModal', {
          open: false,
        });
        // sessionStorage.removeItem('questAuto');
        this.$store.dispatch('getQuestionnaire');
      } else {
        this.$store.dispatch('getQuestionnaire');
        this.closed = true;
      }
    },
    getDate(date) {
      this.newBirth = date;
    },
    loadCountries() {
      this.$http.post('v1/user-guest/country', {}).then((response) => {
        if (response.body.status) {
          this.countriesLists = response.body.result;
          this.user.address_country
            ? (this.selectedCountry = this.countriesLists.find(
                (i) => i.name === this.user.address_country
              ))
            : (this.selectedCountry = this.user.address_country);
          this.searchCity(this.user.address_city ? this.user.address_city[0].toLowerCase() : 'a');
        }
      });
    },
    onCountryClose(event) {
      if (!isEqual(event, this.selectedCity)) {
        this.selectedCity = null;
        this.$nextTick(() => {
          this.searchCity(null);
        });
      }
    },

    searchCity(v) {
      if (!this.selectedCountry) return;
      this.$http
        .post('v1/user-guest/city', {
          country_id: this.selectedCountry.id,
          city_name: v || 'a',
        })
        .then((response) => {
          if (response.body.status) {
            ({ result: this.citiesLists } = response.body);
            if (this.isFirst) {
              this.user.address_city
                ? (this.selectedCity = this.citiesLists.find(
                    (i) => i.name === this.user.address_city
                  ))
                : this.selectedCity;
            }
            this.isFirst = false;
          }
        });
    },
    closeModal() {
      if (this.never) {
        this.$http
          .post('v1/questionnaire/complete', {
            access_token: window.localStorage['access-token'],
            action: 'cancel',
          })
          .then((res) => {
            if (res.data.status) {
              this.$store.commit('updateQuestionnaireModal', {
                open: false,
              });
              // sessionStorage.removeItem('questAuto');
              this.$store.dispatch('getQuestionnaire');
            }
          });
      } else {
        this.$store.commit('updateQuestionnaireModal', {
          open: false,
        });
        this.$store.dispatch('getQuestionnaire');
        // sessionStorage.setItem('questAuto', JSON.stringify(false));
      }
    },
    openUploader() {
      this.$store.commit('updateUploadPhotosModal', {
        open: true,
      });
    },
    updatePageInfo() {
      this.avatarUpload = true;
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    initData() {
      this.user.birthday ? (this.birth = this.user.birthday * 1000) : (this.birth = null);
      this.getDate(this.birth);
      this.targets.target_family = !!this.user.profile_additional_info.target_family;
      this.targets.target_meeting = !!this.user.profile_additional_info.target_meeting;
      this.targets.target_children = !!this.user.profile_additional_info.target_children;
      this.targets.target_communication = !!this.user.profile_additional_info.target_communication;
      this.targets.target_flirtation = !!this.user.profile_additional_info.target_flirtation;
      this.targets.target_friendship = !!this.user.profile_additional_info.target_friendship;
      this.targets.target_journey = !!this.user.profile_additional_info.target_journey;
      this.selected.interests = this.user.profile_additional_info.interests;
      this.selected.about_me = this.user.profile_additional_info.about_me;
      this.selected.weight = this.user.profile_additional_info.weight;
      this.selected.children = this.user.profile_additional_info.children
        ? this.values.children.find((i) => i.k === this.user.profile_additional_info.children)
        : this.values.children.find((i) => i.k === 'no');
      this.selected.hair_color = this.user.profile_additional_info.hair_color
        ? this.values.hair.find((i) => i.k === this.user.profile_additional_info.hair_color)
        : this.values.hair.find((i) => i.k === 'no');
      this.selected.eye_color = this.user.profile_additional_info.eye_color
        ? this.values.eyes.find((i) => i.k === this.user.profile_additional_info.eye_color)
        : this.values.eyes.find((i) => i.k === 'no');
      this.selected.body = this.user.profile_additional_info.body
        ? this.values.body.find((i) => i.k === this.user.profile_additional_info.body)
        : this.values.body.find((i) => i.k === 'no');
      this.height.feet = this.values.feet.find(
        (i) => i.k === this.cmToFeetInch(this.user.profile_additional_info.height).feet
      );
      this.height.inches = this.values.inches.find(
        (i) => i.k === this.cmToFeetInch(this.user.profile_additional_info.height).inches
      );

      this.selected.about_woman_age_from = this.user.profile_additional_info.about_woman_age_from;
      this.selected.about_woman_age_to = this.user.profile_additional_info.about_woman_age_to;

      this.selected.about_woman_children = this.user.profile_additional_info.about_woman_children
        ? this.values.childrenWoman.find(
            (i) => i.k === this.user.profile_additional_info.about_woman_children
          )
        : this.values.childrenWoman.find((i) => i.k === 'no');
      this.selected.about_woman_hair_color = this.user.profile_additional_info
        .about_woman_hair_color
        ? this.values.hair.find(
            (i) => i.k === this.user.profile_additional_info.about_woman_hair_color
          )
        : this.values.hair.find((i) => i.k === 'no');
      this.selected.about_woman_eye_color = this.user.profile_additional_info.about_woman_eye_color
        ? this.values.eyes.find(
            (i) => i.k === this.user.profile_additional_info.about_woman_eye_color
          )
        : this.values.eyes.find((i) => i.k === 'no');
      this.selected.about_woman_body = this.user.profile_additional_info.about_woman_body
        ? this.values.body.find((i) => i.k === this.user.profile_additional_info.about_woman_body)
        : this.values.body.find((i) => i.k === 'no');
      this.selected.about_woman_text = this.user.profile_additional_info.about_woman_text;
    },
    initStep() {
      // this.initQuestionIndex = 0;
      // this.step = this.questionsForQuestionnaire[this.initQuestionIndex].name
      if (this.questionnaireStep) {
        this.initQuestionIndex =
          this.questionsForQuestionnaire.findIndex((i) => i.name === this.questionnaireStep) + 1;
        this.step = this.questionsForQuestionnaire[this.initQuestionIndex].name;
      } else {
        this.initQuestionIndex = 0;
        this.step = this.questionsForQuestionnaire[this.initQuestionIndex].name;
      }
    },
    nextStep() {
      // this.initQuestionIndex++;
      // if (this.initQuestionIndex > this.questionsForQuestionnaire.length - 1) {
      //   this.initQuestionIndex = this.questionsForQuestionnaire.length - 1
      // }
      // this.step = this.questionsForQuestionnaire[this.initQuestionIndex].name

      this.stepAction().then(() => {
        const action =
          this.initQuestionIndex === this.questionsForQuestionnaire.length - 1
            ? {
                action: 'complete',
              }
            : {
                action: 'step',
                step: this.questionsForQuestionnaire[this.initQuestionIndex].name,
              };

        this.loading = true;
        this.$http
          .post('v1/questionnaire/complete', {
            access_token: window.localStorage['access-token'],
            ...action,
          })
          .then((res) => {
            if (res.data.status) {
              this.loading = false;
              if (action.action === 'complete') {
                this.never = true;

                if (res.data.accrued) {
                  this.success = true;
                  this.newReward = res.data.credits_reward;
                } else {
                  this.never = false;
                  this.closeModal();
                }

                if (
                  res.data &&
                  // eslint-disable-next-line no-prototype-builtins
                  res.data.hasOwnProperty('accrued') &&
                  // eslint-disable-next-line no-prototype-builtins
                  res.data.hasOwnProperty('credits_reward')
                ) {
                  this.$gtag.event('filled_questionnaire', {
                    event_category: 'questionnaire',
                    event_label: res.data.accrued ? 'accrued' : ' not accrued',
                    value: res.data.credits_reward ? res.data.credits_reward : 0,
                  });
                }

                return;
              }

              this.$store.commit(
                'QUESTIONNAIRE_STEP',
                this.questionsForQuestionnaire[this.initQuestionIndex].name
              );
              this.initQuestionIndex++;
              if (this.initQuestionIndex > this.questionsForQuestionnaire.length - 1) {
                this.initQuestionIndex = this.questionsForQuestionnaire.length - 1;
              }
              this.step = this.questionsForQuestionnaire[this.initQuestionIndex].name;
            } else {
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
            this.$store.dispatch('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
              info: 'load credit packs',
            });
          });
      });
    },
  },
};
</script>

<style scoped>
.success_icon {
  width: 50%;
  max-width: 100px;
}
</style>
