<template>
  <span class="zodiac-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M29.988.48h-.005c-.276 0-6.46.117-10.985 7.857-.91-.225-1.854-.36-2.833-.36-1.086 0-2.135.16-3.134.438C8.5.6 2.28.48 2 .48h-.005C.897.48.02 1.357 0 2.453-.018 3.552.862 4.464 1.962 4.5c.18.006 4.14.216 7.417 5.645-3.012 2.135-4.987 5.64-4.987 9.605 0 6.49 5.28 11.77 11.77 11.77 6.493 0 11.775-5.28 11.775-11.77 0-4.07-2.077-7.663-5.225-9.777C25.943 4.755 29.78 4.51 30.02 4.5c1.1-.016 1.983-.913 1.98-2.017C31.998 1.376 31.1.48 29.988.48zM16.164 27.5c-4.272 0-7.75-3.477-7.75-7.75S11.893 12 16.165 12c4.275 0 7.75 3.478 7.75 7.75.002 4.273-3.476 7.75-7.75 7.75z" />
    </svg>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style>
</style>
